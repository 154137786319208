import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FontFamily, FormStyles } from "../../interfaces/FormStyles";

export const initialStateFormStyles: FormStyles = JSON.parse(localStorage.getItem("FormStyles") as string) || {
  titleFilled: false,
  titleFullWidth: false,
  titleUnderline: false,
  titleColor: "#000000",
  titleFillColor: "#ccffcc",
  accentColor: "#0000ff",
  fontFamily: "sans-serif",
};

export const formStylesSlice = createSlice({
  name: "formStyles",
  initialState: initialStateFormStyles,
  reducers: {
    setTitleFilledFormStyles: (state, action: PayloadAction<boolean>) => {
      state.titleFilled = action.payload;
    },
    setTitleFullWidthFormStyles: (state, action: PayloadAction<boolean>) => {
      state.titleFullWidth = action.payload;
    },
    setTitleUnderlineFormStyles: (state, action: PayloadAction<boolean>) => {
      state.titleUnderline = action.payload;
    },
    setTitleColorFormStyles: (state, action: PayloadAction<string>) => {
      state.titleColor = action.payload;
    },
    setTitleFillColorFormStyles: (state, action: PayloadAction<string>) => {
      state.titleFillColor = action.payload;
    },
    setAccentColorFormStyles: (state, action: PayloadAction<string>) => {
      state.accentColor = action.payload;
    },
    setFontFamilyFormStyles: (state, action: PayloadAction<FontFamily>) => {
      state.fontFamily = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTitleFilledFormStyles,
  setTitleFullWidthFormStyles,
  setTitleUnderlineFormStyles,
  setTitleColorFormStyles,
  setTitleFillColorFormStyles,
  setAccentColorFormStyles,
  setFontFamilyFormStyles,
} = formStylesSlice.actions;

export default formStylesSlice.reducer;