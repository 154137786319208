import "./PhotoForm.css";
import "./_FormsStyles.css";

import React, { FC, useEffect, useState } from "react";
import "./_FormsStyles.css";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setBorderColorPhoto1,
  setBorderRadiusPhoto1,
  setBorderWidthPhoto1,
  setHasPhotoPhoto1,
  setPhotoSizePhoto1,
  setStylePhoto1,
} from "../../../../features/elements/photo/photo1Slice";
import { CollapseableForm } from "./_CommonComponents";
import { SliderComponent } from "../../../../Components/Inputs/SliderComponent";
import { ColorPickerInput } from "../../../../Components/Inputs/ColorPickerInput";

interface PhotoFormProps {}

export const Photo1Form: FC<PhotoFormProps> = React.memo((props) => {
  const photo1 = useAppSelector((state) => state.photo1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);

  const [image, setImage] = useState<string | null>(null);
  // const [photoHeightSliderValue, setPhotoHeightSliderValue] = useState(props.photo.height);
  // const [photoWidthSliderValue, setPhotoWidthSliderValue] = useState(props.photo.width);
  const [photoSizeSliderValue, setPhotoSizeSliderValue] = useState(photo1.width);
  const [photoBorderRadiusSliderValue, setPhotoBorderRadiusSliderValue] = useState(photo1.borderRadius);
  const [photoBorderWidthSliderValue, setPhotoBorderWidthSliderValue] = useState(photo1.borderWidth);

  const handleExpandClick = () => setExpanded(!expanded);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          console.log("reader.result", reader.result);
          resolve(reader.result);
        }
        reader.onerror = (error) => {
          console.error("Error: ", error);
          reject(error);
        };
      };
    });
  };

  // Cleanup function to revoke the object URL when component unmounts
  useEffect(() => {
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setPhotoSizePhoto1(photoSizeSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [photoSizeSliderValue]);
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setBorderRadiusPhoto1(photoBorderRadiusSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [photoBorderRadiusSliderValue]);
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setBorderWidthPhoto1(photoBorderWidthSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [photoBorderWidthSliderValue]);

  // ==================================================================================================================
  // Handle Text/Slider Inputs

  // const handlePhotoHeightSlider = (event: Event, newPhotoHeight: number | number[]) => {
  //   const sliderValue = Array.isArray(newPhotoHeight) ? newPhotoHeight[0] : newPhotoHeight;
  //   setPhotoHeightSliderValue(sliderValue);
  //   props.setPhoto({ ...props.photo, height: sliderValue * 10 });
  // };
  // const handlePhotoWidthSlider = (event: Event, newPhotoWidth: number | number[]) => {
  //   const sliderValue = Array.isArray(newPhotoWidth) ? newPhotoWidth[0] : newPhotoWidth;
  //   setPhotoWidthSliderValue(sliderValue);
  //   props.setPhoto({ ...props.photo, width: sliderValue * 10 });
  // };
  // On Selecting photo from input field
  const handleChangePhoto = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      console.log("file", file);
      if (file) {
        // convert photo to base64 string and save to localstorage
        const photoB64String = await getBase64(file);
        localStorage.setItem("avatarBase64", photoB64String);
        dispatch(setHasPhotoPhoto1(photo1.hasPhoto + 1));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePhotoSizeSlider = (event: React.ChangeEvent<HTMLInputElement>, newPhotoSize: number | number[]) => {
    const sliderValue = Array.isArray(newPhotoSize) ? newPhotoSize[0] : newPhotoSize;
    setPhotoSizeSliderValue(sliderValue);
  };
  const handlePhotoRadiusSlider = (event: React.ChangeEvent<HTMLInputElement>, newPhotoRadius: number | number[]) => {
    const sliderValue = Array.isArray(newPhotoRadius) ? newPhotoRadius[0] : newPhotoRadius;
    setPhotoBorderRadiusSliderValue(sliderValue);
  };
  const handleBorderWidthSlider = (event: React.ChangeEvent<HTMLInputElement>, newPhotoBorderWidth: number | number[]) => {
    const sliderValue = Array.isArray(newPhotoBorderWidth) ? newPhotoBorderWidth[0] : newPhotoBorderWidth;
    setPhotoBorderWidthSliderValue(sliderValue);
  };
  const handleBorderColor = (newPhotoBorderColor: string) => {
    dispatch(setBorderColorPhoto1(newPhotoBorderColor));
  };

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStylePhoto1({ ...photo1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStylePhoto1({ ...photo1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Photo #1</h3>
      &nbsp;
      <div className="photoFormBody">
        <div className="photoFormLeft">
          <label
            // onChange={async (event: any) => handleChangePhoto(event.target.files[0])}
            //   {
            //   try {
            //     const selectedPhoto: File = event.target.files[0];
            //     // let myImage = new Image(100,100);
            //     // myImage.src = URL.createObjectURL(selectedPhoto);
            //     setImage(selectedPhoto)
            //     // const b64 = await getBase64(myImage);
            //     handleChangePhoto(b64!.toString());
            //   } catch (error) {
            //     console.error(error);
            //   }
            // }
            htmlFor="photoUpload"
            className="photoFormUploadButton"
          >
            Upload
            <input
              type="file"
              accept="photo/png, photo/jpg, photo/jpeg"
              name="photoUpload"
              id="photoUpload"
              hidden
              onChange={handleChangePhoto}
            />
          </label>
          <div style={{ paddingTop: "6px" }}>&nbsp;</div>
          <ColorPickerInput label="Border Color" color={photo1.borderColor ? photo1.borderColor : "#123456"} handleColor={handleBorderColor} />
        </div>
        {/* Photo Size & Border Sliders */}
        <div className="photoFormRight">
          {/* <Slider
              aria-label="Photo Height"
              min={0}
              max={20}
              step={1}
              valueLabelDisplay="auto"
              value={photoHeightSliderValue}
              onChange={handlePhotoHeightSlider}
            />
            Height
            <div>&nbsp;</div> */}
          {/* <Slider
              aria-label="Photo Width"
              min={0}
              max={20}
              step={1}
              valueLabelDisplay="auto"
              value={photoWidthSliderValue}
              onChange={handlePhotoWidthSlider}
            />
            Width
            <div>&nbsp;</div> */}
          <SliderComponent
            value={photoSizeSliderValue}
            handleSliderChange={handlePhotoSizeSlider}
            min={0}
            max={20}
            step={1}
            label={"Photo Size"}
          />
          <SliderComponent
            value={photoBorderRadiusSliderValue}
            handleSliderChange={handlePhotoRadiusSlider}
            min={0}
            max={50}
            step={2}
            label={"Border Radius"}
          />
          <SliderComponent
            value={photoBorderWidthSliderValue}
            handleSliderChange={handleBorderWidthSlider}
            min={0}
            max={10}
            step={1}
            label={"Border Width"}
          />
        </div>
      </div>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={photo1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={photo1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
