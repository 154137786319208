import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Projects } from "../../../interfaces/Projects";

export const initialStateProjects2: Projects = {
  title: "",
  bullet: true,
  indent: true,
  data: [{ id: `project${Date.now()}`, projectName: "", projectDetails: [""] }],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const projects2Slice = createSlice({
  name: "projects2",
  initialState: initialStateProjects2,
  reducers: {
    setBlockDataProjects2: (state, action: PayloadAction<Projects>) => {
      state.title = action.payload.title;
      state.bullet = action.payload.bullet;
      state.indent = action.payload.indent;
      state.data = action.payload.data.map((item) => ({
        ...item,
        projectDetails: [...item.projectDetails],
      }));
      state.style = action.payload.style;
    },
    setTitleProjects2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBulletProjects2: (state, action: PayloadAction<boolean>) => {
      state.bullet = action.payload;
    },
    setIndentProjects2: (state, action: PayloadAction<boolean>) => {
      state.indent = action.payload;
    },
    setProjectNameProjects2: (state, action: PayloadAction<{projectName: string, projectId:string}>) => {
      const { projectName, projectId } = action.payload;
      const pos = state.data.findIndex((project) => project.id === projectId);
      state.data[pos].projectName = projectName;
    },
    setProjectDetailsProjects2: (state, action: PayloadAction<{projectDetails: string[], projectId:string}>) => {
      const { projectDetails, projectId } = action.payload;
      const pos = state.data.findIndex((project) => project.id === projectId);
      state.data[pos].projectDetails = projectDetails;
    },
    addProjectProjects2: (state) => {
      state.data.push({ id: `project${Date.now()}`, projectName: "", projectDetails: [""] });
    },
    removeProjectProjects2: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((project) => project.id !== action.payload);
    },
    setStyleProjects2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataProjects2,
  setTitleProjects2,
  setBulletProjects2,
  setIndentProjects2,
  setProjectNameProjects2,
  setProjectDetailsProjects2,
  addProjectProjects2,
  removeProjectProjects2,
  setStyleProjects2,
} = projects2Slice.actions;

export default projects2Slice.reducer;
