import React, { useEffect, useState } from "react";

import { GridItem } from "../../../../interfaces/GridItem";
import { checkHyperlink } from "../../../../helpers/checkHyperlink";
import { FormStyles } from "../../../../interfaces/FormStyles";
import { About } from "../../../../interfaces/About";
import "./_BlocksStyles.css";
import { useAppSelector } from "../../../../app/hooks";
import { RemoveBlockButton } from "./_CommonComponents";

const dummyAbout = {
  name: "John Doe",
  profession: "Software Engineer",
  about: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, quae expedita architecto, doloribus recusandae iste harum fugit, 
          maxime ipsa nemo magnam provident amet voluptate eveniet unde illo! Dolores, alias porro.`,
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const isEmptyAbout = (about: About) => {
  if (about.about === "" && about.name === "" && about.profession === "") return true;
  return false;
};

interface AboutProps {
  item: GridItem;
}
export const AboutBlock1: React.FC<AboutProps> = (props) => {
  const about1 = useAppSelector((state) => state.about1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty About
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyAbout(about1));
  }, [about1]);

  const toBeShownAbout = isEmptyAbout(about1) ? dummyAbout : about1;

  return (
    <div
      style={{
        backgroundColor: toBeShownAbout.style.bgColor,
        color: toBeShownAbout.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <h1 style={{ fontWeight: 600, marginBottom: 0, display: "inline-block", opacity: isEmpty ? 0.5 : 1 }}>
          {toBeShownAbout.name}
        </h1>
        <p style={{ display: "inline-block", opacity: isEmpty ? 0.5 : 1 }}>&nbsp;&nbsp;{toBeShownAbout.profession}</p>
        <RemoveBlockButton item={props.item} blockTitle={"About #1"} />
        <div style={{ fontWeight: 500, fontSize: 15, marginTop: 8, opacity: isEmpty ? 0.5 : 1 }}>
          {/* the about extra */}
          <div style={{ paddingRight: 4, paddingLeft: 4 }}>
            <p>{toBeShownAbout.about}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
