import "./ClientDownloadPage.css";

import React, { FC, useEffect, useRef, useState } from "react";

import { getAnalytics, logEvent } from "firebase/analytics";

import AppBarHeader from "../../Components/AppBarHeader";
import Footer from "../../Components/Footer";
import { useReactToPrint } from "react-to-print";
import { _isProd, DOWNLOADED_RESUME, EDITED_RESUME } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getBlueprint } from "../../helpers/chooseBlueprint";
import { GridItem } from "../../interfaces/GridItem";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";
import { ArrowLeftIcon, DownloadIcon, PrinterFilledIcon } from "../../static/Icons/Icons";
import CircularProgressIcon from "../../Components/Layout/CircularProgress";
import { xToPx } from "../../helpers/xToPx";

interface ClientDownloadPageProps {}

const ClientDownloadPage: React.FC<ClientDownloadPageProps> = (props) => {
  // const gridItems = useAppSelector((state) => state.gridItems);
  // const itemsArray = gridItems.items;
  // const formStyles = useAppSelector((state) => state.formStyles);

  // const itemsArray: GridItem[] = JSON.parse(itemsArrayString);
  // const formStyles = JSON.parse(formStylesString);

  const itemsArrayString = localStorage.getItem("ItemsArray") as string;
  const formStylesString = localStorage.getItem("FormStyles") as string;
  // setItemsArray(JSON.parse(itemsArrayString));
  // setFormStyles(JSON.parse(formStylesString));

  const [itemsArray, setItemsArray] = useState<GridItem[]>(itemsArrayString ? JSON.parse(itemsArrayString) : []);
  // const [formStyles, setFormStyles] = useState<FormStyles>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // if(!_isProd) console.log("itemsArray", itemsArrayString);
    // if(!_isProd) console.log("formStyles", formStylesString);
    // populate all the slices with the data from local storage
    // dispatch(setLayoutItems({ items: itemsArray, forms: formStyles }));
  }, []);

  const pdfRef = useRef<HTMLDivElement | null>(null);

  // Get Google Analytics
  const analytics = getAnalytics();

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(false);

  // Go Back Editing
  const handleGoBack = () => {
    try {
      logEvent(analytics, EDITED_RESUME);
      // navigate("/create");
      window.location.href = "/create";
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickDownload = () => {
    setLoadingDownload(true);
    setTimeout(() => {
      setLoadingDownload(false);
    }, 1000);
    handleDownload();
  };
  const handleClickPrint = () => {
    setLoadingPrint(true);
    setTimeout(() => {
      setLoadingPrint(false);
    }, 1000);
    handlePrint();
  };

  // Build PDF - PRINT
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Resume",
    copyStyles: true,
    onAfterPrint: () => {
      try {
        logEvent(analytics, DOWNLOADED_RESUME);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const calculateHeight = () => {
    let lowestPositionElement = itemsArray[0];
    for (let i = 0; i < itemsArray.length; i++) {
      const element = itemsArray[i];
      if (element.y > lowestPositionElement.y) {
        if (!_isProd) console.log(element.name);
        lowestPositionElement = element;
      }
    }
    const height =
      lowestPositionElement.y * 10 +
      (lowestPositionElement.y - 1) * 10 +
      10 +
      (lowestPositionElement.h * 10 + (lowestPositionElement.h - 1) * 10);
    if (!_isProd) console.log("height", height, lowestPositionElement.name);
    return height > xToPx("297mm") ? height : xToPx("287mm");
  };

  // Build PDF - DOWNLOAD
  const handleDownload = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Resume",
    copyStyles: true,
    // pageStyle: pageStyleForUseReact2Print,
    print: async (printIframe: HTMLIFrameElement) => {
      const pdfdoc = printIframe.contentDocument;
      if (pdfdoc) {
        /** NEEDED BECAUSE HTML2PDF SHIFTS IMAGE DOWN =>
         * REFER TO => https://github.com/eKoopmans/html2pdf.js/issues/659
         * AND => https://github.com/niklasvh/html2canvas/issues/2775
         */
        const style = document.createElement("style");
        document.head.appendChild(style);
        style.sheet?.insertRule("body > div:last-child img { display: inline-block; }");

        // const html = pdfdoc.getElementsByTagName("body")[0].outerHTML;
        const html = pdfdoc.documentElement.outerHTML; // Capture the entire document
        if (!_isProd) console.log("html=>>>>>>>>>>>>>>>>>>>>>>>>>>", html);
        const pdfOptions = {
          margin: [0, 0, 10, 0], // [top, left, bottom, right]
          filename: "Resume.pdf",
          image: { type: "jpeg", quality: 2 },
          enableLinks: true,
          scale: 2,
          html2canvas: {
            scale: 2,
            useCORS: true,
            scrollX: 0,
            scrollY: 0,
            height: calculateHeight(),
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "p",
            putOnlyUsedFonts: true,
            precision: 0,
            floatPrecision: 64,
          },
          // pagebreak: { mode: ["avoid-all", "css", "legacy"] },
          // pagebreak: { before: "#page2el" }, // Ensuring footer isn't broken.
        };
        try {
          // @ts-ignore
          const html2pdf = (await import("html2pdf.js")).default;
          await html2pdf()
            .set(pdfOptions)
            .from(html)
            .save()
            .then((canvas: any) => {
              style.remove();
            });
        } catch (error) {
          console.error("Failed to load html2pdf.js", error);
        }
      }
    },
    onAfterPrint: () => {
      try {
        logEvent(analytics, DOWNLOADED_RESUME);
        if (!_isProd) console.log("Downloaded");
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <>
      <AppBarHeader />
      <div className="pageWrapper">
        <div className="pdfOuterDiv">
          <div ref={pdfRef} id="pdfInnerDiv">
            {itemsArray
              ? itemsArray.map((item: GridItem) => {
                  return getBlueprint(item);
                })
              : null}
            {/* WATERMARK */}
            <div className="pdfWatermark">
              made with <span style={{ fontWeight: 600 }}>Resumez</span>
            </div>
            <div id="page2el"></div>
          </div>
        </div>
        &nbsp; &nbsp;
        <div className="buttonGroupDownloadPage">
          <ButtonComponent
            onClick={handleGoBack}
            bgColor="var(--color-secondary)"
            textColor="var(--color-on-secondary)"
          >
            <ArrowLeftIcon />
            &nbsp;&nbsp;Edit Resume
          </ButtonComponent>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonComponent onClick={handleClickDownload} loading={loadingDownload}>
            Download Resume&nbsp;&nbsp;
            {loadingDownload ? <CircularProgressIcon size={20} color="inherit" /> : <DownloadIcon />}
          </ButtonComponent>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ButtonComponent onClick={handleClickPrint} loading={loadingPrint}>
            Print Resume&nbsp;&nbsp;
            {loadingPrint ? <CircularProgressIcon size={20} color="inherit" /> : <PrinterFilledIcon />}
          </ButtonComponent>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ClientDownloadPage;
