import "./_BlocksStyles.css";

import React, { FC } from "react";

import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";

interface SpacerBlockProps {
  item: GridItem;
}

export const SpacerBlock1: FC<SpacerBlockProps> = (props) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"}>
          <RemoveBlockButton item={props.item} blockTitle={"Spacer #1"} />
        </div>
      </div>
    </>
  );
};

export const SpacerBlock2: FC<SpacerBlockProps> = (props) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"}>
          <RemoveBlockButton item={props.item} blockTitle={"Spacer #2"} />
        </div>
      </div>
    </>
  );
};

export const SpacerBlock3: FC<SpacerBlockProps> = (props) => {
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"}>
          <RemoveBlockButton item={props.item} blockTitle={"Spacer #3"} />
        </div>
      </div>
    </>
  );
};
