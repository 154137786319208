export const xToPx = (x:string) => {
  var div = document.createElement('div');
  div.style.display = 'block';
  div.style.height = x;
  document.body.appendChild(div);
  var px = parseFloat(window.getComputedStyle(div, null).height);
  if (div.parentNode) {
    div.parentNode.removeChild(div);
  }
  return px;
}