import "./RightForm.css";

import React from "react";

import { AboutWithContact1Form, AboutWithContact2Form } from "./FormItems/AboutWithContactForms";
import { FormStylesForm } from "./FormItems/FormStylesForm";
import { OthersForm } from "./FormItems/OthersForms";
import { Projects1Form, Projects2Form } from "./FormItems/ProjectsForms";
import { SkillsForm1, SkillsForm2 } from "./FormItems/SkillsForms";
import { WorksForm1, WorksForm2 } from "./FormItems/WorksForms";
import { Contact1Form, Contact2Form, Contact3Form } from "./FormItems/ContactForms";
import { Ratings1Form, Ratings2Form, Ratings3Form, Ratings4Form } from "./FormItems/RatingsForms";

import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { MADE_RESUME } from "../../../constants";
import { Name1Form } from "./FormItems/NameForms";
import { saveResume } from "../../../services/ResumeService";
import { getCurrentUser } from "../../../services/userService";
import { User } from "firebase/auth";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { About1Form } from "./FormItems/AboutForms";
import { Certifications1Form } from "./FormItems/CertificationsForms";
import { Educations1Form, Educations2Form, Educations3Form } from "./FormItems/EducationsForms";
import { Photo1Form } from "./FormItems/PhotoForm";

import { Layout } from "react-grid-layout";
import { CaretRightIcon } from "../../../static/Icons/Icons";
import { ButtonComponent } from "../../../Components/Inputs/ButtonComponent";
import Throbber from "../../../Components/Throbber";

interface RightFormProps {
  saveToLS: (layouts: Layout[]) => void;
  layout: Layout[];
  rightFormExpanded: boolean;
  closeRightFormDrawer: () => void;
}

const RightForm: React.FC<RightFormProps> = (props) => {
  const navigate = useNavigate();

  const gridItems = useAppSelector((state) => state.gridItems);

  const analytics = getAnalytics();

  // Backdrop/Loading when clicking "GET RESUME"
  const [loading, setLoading] = React.useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      try {
        props.saveToLS(props.layout);
        console.log(".");
        // USER ID IS THE RESUME NAME FOR NOW
        const user: User = await getCurrentUser();
        const itemsArray = JSON.parse(localStorage.getItem("ItemsArray") as string);
        const formStyles = JSON.parse(localStorage.getItem("FormStyles") as string);
        if (formStyles && itemsArray) {
          await saveResume(formStyles, itemsArray, user.uid);
        }
        logEvent(analytics, MADE_RESUME);
        navigate("/resumes/" + user.uid);
      } catch (error) {
        console.error(error);
      }
      setSaveButtonLoading(true);
    }, 1500);
  };

  const handleDownload = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      try {
        props.saveToLS(props.layout);
        logEvent(analytics, MADE_RESUME);
        navigate("/download");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }, Math.floor(Math.random() * (2000 - 1500)) + 1500);
  };

  // Return the specific form from passed parameter
  const chooseFormToShow = (form: string): React.ReactNode => {
    switch (form) {
      case "aboutwithcontact1":
        return <AboutWithContact1Form />;
      case "aboutwithcontact2":
        return <AboutWithContact2Form />;

      case "photo1":
        return <Photo1Form />;

      case "about1":
        return <About1Form />;

      case "name1":
        return <Name1Form />;

      case "contact1":
        return <Contact1Form />;
      case "contact2":
        return <Contact2Form />;
      case "contact3":
        return <Contact3Form />;

      case "skills1":
        return <SkillsForm1 />;
      case "skills2":
        return <SkillsForm2 />;

      case "educations1":
        return <Educations1Form />;
      case "educations2":
        return <Educations2Form />;
      case "educations3":
        return <Educations3Form />;

      case "works1":
        return <WorksForm1 />;
      case "works2":
        return <WorksForm2 />;

      case "projects1":
        return <Projects1Form />;
      case "projects2":
        return <Projects2Form />;

      case "ratings1":
        return <Ratings1Form />;
      case "ratings2":
        return <Ratings2Form />;
      case "ratings3":
        return <Ratings3Form />;
      case "ratings4":
        return <Ratings4Form />;

      case "certifications1":
        return <Certifications1Form />;

      case "others1":
        return <OthersForm />;

      default:
        return <>chooseFormToShow() does not have {form} in switch case in RightForm.tsx</>;
    }
  };

  return (
    <>
      <div style={{ padding: "0px 8px 36px 0px", minHeight: "100vh", display: "flex" }}>
        <div className="divider"></div>
        <div style={{ width: "100%" }}>
          <div>
            {/* THE RIGHT-FORM HEADER */}
            {props.rightFormExpanded && (
              <div
                style={{
                  width: "100%",
                  padding: "12px 0px 16px 16px",
                  backgroundColor: "var(--color-surface)",
                  position: "sticky",
                  top: "72px",
                  zIndex: 1,
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <ButtonComponent onClick={props.closeRightFormDrawer} size="small">
                    <CaretRightIcon />
                  </ButtonComponent>
                  <div style={{ width: "100%" }}>&nbsp;</div>
                  <h3 style={{ width: "100%", textWrap: "nowrap" }}>Customize Your Resume</h3>
                  <div style={{ width: "100%" }}>&nbsp;</div>
                </div>
              </div>
            )}

            {gridItems.forms.map((eachForm) => {
              return (
                <div key={eachForm} className="eachFormWrapper" id={eachForm}>
                  {chooseFormToShow(eachForm)}
                </div>
              );
            })}
            <div className="eachFormWrapper">
              <FormStylesForm />
            </div>
          </div>
          &nbsp;
          {/* Button to go to ClientDownloadPage */}
          <div className="eachFormWrapper">
            <button
              className="downloadButton"
              onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDownload(e)}
            >
              Download&nbsp;&nbsp;Resume
            </button>
            &nbsp;
            {/* SAVE THE RESUME */}
            <button
              className="saveButton"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSave(e)}
            >
              Save&nbsp;&nbsp;Resume
            </button>
          </div>
        </div>
      </div>
      {/* <Backdrop
        sx={{ color: "#123", zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="secondary" />
      </Backdrop> */}
      {loading && <Throbber backgroundColor="rgba(0,0,0,0.5)" />}
    </>
  );
};

export default RightForm;
