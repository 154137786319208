import React from "react";

export const ContactIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={50} y={50} width={130} height={5} rx={2} fill="#646464" />
        <circle cx={190} cy={52} r={4} fill="#f50057" />

        <rect x={70} y={65} width={110} height={5} rx={2} fill="#646464" />
        <circle cx={190} cy={67} r={4} fill="#f50057" />

        <rect x={90} y={80} width={90} height={5} rx={2} fill="#646464" />
        <circle cx={190} cy={82} r={4} fill="#f50057" />

        <rect x={70} y={95} width={110} height={5} rx={2} fill="#646464" />
        <circle cx={190} cy={97} r={4} fill="#f50057" />
      </svg>
    </div>
  );
};

export const ContactIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <circle cx={50} cy={52} r={4} fill="#651fff" />
        <rect x={60} y={50} width={130} height={5} rx={2} fill="#646464" />

        <circle cx={50} cy={67} r={4} fill="#651fff" />
        <rect x={60} y={65} width={110} height={5} rx={2} fill="#646464" />

        <circle cx={50} cy={82} r={4} fill="#651fff" />
        <rect x={60} y={80} width={90} height={5} rx={2} fill="#646464" />

        <circle cx={50} cy={97} r={4} fill="#651fff" />
        <rect x={60} y={95} width={110} height={5} rx={2} fill="#646464" />
      </svg>
    </div>
  );
};

export const ContactIcon3: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={70} y={50} width={110} height={5} rx={2} fill="#646464" />
        <rect x={80} y={65} width={90} height={5} rx={2} fill="#646464" />
        <rect x={70} y={80} width={110} height={5} rx={2} fill="#646464" />
        <rect x={65} y={95} width={120} height={5} rx={2} fill="#646464" />
      </svg>
    </div>
  );
};
