import React, { useEffect } from "react";
import "./App.css";
// import { jsPDF } from "jspdf";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import CreatePage from "./pages/CreatePage/CreatePage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase";
import AuthRoute from "./Components/AuthRoute";
import LoginPage from "./pages/auth/LoginPage";
import SignupPage from "./pages/auth/SignupPage";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ClientDownloadPage from "./pages/DownloadPage/ClientDownloadPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import { initializeAnalytics } from "firebase/analytics";
import ResumePage from "./pages/ResumePage/ResumePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { useAppSelector } from "./app/hooks";
import { useAuth } from "./helpers/useAuth";
import Throbber from "./Components/Throbber";
import PrivacyPolicy from "./pp_tnc/PrivacyPolicy";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
initializeAnalytics(firebaseApp);

// async function makePDF() {
//   // var s = '<div id="myDiv"></div>';
//   var s = await fetch("http://localhost:5000/")
//     .then((response) => response.json())
//     .then((data) => data);

//   var htmlObject = document.createElement("div");
//   htmlObject.innerHTML = s;
//   console.log(htmlObject);

//   var doc = new jsPDF("portrait", "mm");
//   doc.html(s, {
//     callback: function (doc) {
//       doc.save();
//     },
//     width: 210,
//     windowWidth: 1000,
//     autoPaging: true,
//   });
// }

export const App: React.FC = () => {
  const { user, loading } = useAuth();
  return (
    <>
      {loading ? (
        <Provider store={store}>
          <AppThemeProvider>
            <Throbber />
          </AppThemeProvider>
        </Provider>
      ) : (
        <Provider store={store}>
          <AppThemeProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    // <AuthRoute>
                    <LandingPage />
                    // </AuthRoute>
                  }
                />
                {/* <Route path="/form" element={<FormPage />} /> */}
                {/* <Route
                  path="/create"
                  element={
                    <AuthRoute>
                      <CreatePage />
                    </AuthRoute>
                  }
                /> */}
                <Route
                  path="/download"
                  element={
                    <AuthRoute>
                      {/* <DownloadPage /> */}
                      <ClientDownloadPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <AuthRoute>
                      <ProfilePage />
                    </AuthRoute>
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/create" element={<CreatePage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/resumes/:resumeName" element={<ResumePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </BrowserRouter>
          </AppThemeProvider>
        </Provider>
      )}
    </>
  );
};

function AppThemeProvider({ children }: { children: React.ReactNode }) {
  const themeMode = useAppSelector((state) => state.theme.themeMode);
  return <div className={themeMode === "dark" ? "theme-dark" : ""}>{children}</div>;
}
