import React from "react";

export const AvatarIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <circle cx="125" cy="50" r="20" fill="#5b6be6" />
        <rect x={85} y={75} width={80} height={40} rx={12} fill="#5b6be6" />
      </svg>
    </div>
  );
};
// f50057
