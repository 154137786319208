import { _isProd } from "../../constants";
import { CircleFilledComponentIcon, SquareFilledComponentIcon, StarFilledComponentIcon } from "../../static/Icons/ComponentIcons";
import "./RadioButtonGroupComponent.css";

import React, { useEffect, useState } from "react";

interface RadioButtonGroupComponentProps {
  selectedIcon: "star" | "circle" | "square";
  handleSelectedIcon: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string; // to not interfere with other radio buttons(if any) on the same page(this component)
}

export const RadioButtonGroupComponent: React.FC<RadioButtonGroupComponentProps> = (props) => {
  if (!_isProd) console.log("RadioButtonGroupComponent Render", props);

  return (
    <div className="form-control">
      <div className="radio-group">
        <label className="singleRadioInputWrapper">
          <input
            type="radio"
            name={props.name}
            value="star"
            checked={props.selectedIcon === "star"}
            onChange={props.handleSelectedIcon}
          />
          <div className="radioIcon">
            <StarFilledComponentIcon />
          </div>
        </label>
        <label className="singleRadioInputWrapper">
          <input
            type="radio"
            name={props.name}
            value="circle"
            checked={props.selectedIcon === "circle"}
            onChange={props.handleSelectedIcon}
          />
          <div className="radioIcon">
            <CircleFilledComponentIcon />
          </div>
        </label>
        <label className="singleRadioInputWrapper">
          <input
            type="radio"
            name={props.name}
            value="square"
            checked={props.selectedIcon === "square"}
            onChange={props.handleSelectedIcon}
          />
          <div className="radioIcon">
            <SquareFilledComponentIcon />
          </div>
        </label>
      </div>
    </div>
  );
};
