import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";
import { Certificate, Certifications } from "../../../../interfaces/Certification";
import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";
import { AwardIcon, LinkIcon } from "../../../../static/Icons/Icons";

const dummyCertifications: Certifications = {
  title: "Certification Title",
  data: [
    {
      id: "certification001",
      certificateName: "Web Developement Certificate",
      organizationName: "Udemy",
      certificateDate: "April 14, 2023",
      certificateLink: "https://example.com",
    },
    {
      id: "certification002",
      certificateName: "Machine Learning Certificate",
      organizationName: "Udemy",
      certificateDate: "December 31, 2022",
      certificateLink: "https://example.com",
    },
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const isEmptyCertifications = (certifications: Certifications) => {
  return certifications.data.every((value) => {
    if (
      certifications.title === "" &&
      value.certificateName === "" &&
      value.certificateDate === "" &&
      value.organizationName === ""
    ) {
      return true;
    }
    return false;
  });
};

interface CertificationsBlockProps {
  item: GridItem;
}

export const CertificationsBlock1: React.FC<CertificationsBlockProps> = (props) => {
  const certifications1 = useAppSelector((state) => state.certifications1);
  const formStyles = useAppSelector((state) => state.formStyles);


  // Check For Empty Certifications
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyCertifications(certifications1));
  }, [certifications1]);

  const toBeShownCertifications = isEmpty ? dummyCertifications : certifications1;

  return (
    <div
      style={{
        backgroundColor: toBeShownCertifications.style.bgColor,
        color: toBeShownCertifications.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownCertifications.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Certification #1"} />
        <div
          style={{
            fontSize: 14,
            display: "flex",
            flexDirection: "column",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownCertifications.data.map((certificate: Certificate) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "8px 0px 4px 0px",
                  width: "100%",
                }}
                key={certificate.id}
              >
                <div style={{ marginRight: 12 }}>
                  <AwardIcon style={{ color: formStyles.accentColor }} size={24} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    flex: 1,
                  }}
                >
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                    <div
                      style={{
                        marginRight: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: 600,
                          color: toBeShownCertifications.style.textColor,
                        }}
                      >
                        {certificate.certificateName}&nbsp;
                      </h4>
                      <a
                        href={certificate.certificateLink}
                        target="_blank"
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LinkIcon />
                      </a>
                    </div>
                    <p style={{ color: formStyles.accentColor, fontSize: 12, flexShrink: 0, paddingTop: 2 }}>
                      {certificate.certificateDate}
                    </p>
                  </div>

                  <p style={{ fontWeight: 500, color: toBeShownCertifications.style.textColor, margin: 0 }}>
                    {certificate.organizationName}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// export const CertificationsBlock2: React.FC<CertificationsBlockProps> = (props) => {
//   const certifications2 = useAppSelector((state) => state.certifications2);
//   const formStyles = useAppSelector((state) => state.formStyles);

//   // Check For Empty Certifications
//   const [isEmpty, setIsEmpty] = useState(false);
//   useEffect(() => {
//     setIsEmpty(isEmptyCertifications(certifications2));
//   }, [certifications2]);

//   const toBeShownCertifications = isEmpty ? dummyCertifications : certifications2;

//   return (
//     <div
//       style={{
//         backgroundColor: toBeShownCertifications.style.bgColor,
//         color: toBeShownCertifications.style.textColor,
//         height: "100%",
//         width: "100%",
//       }}
//     >
//       <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
//         <BlockTitle formStyles={formStyles} title={toBeShownCertifications.title} isOpaque={isEmpty} />
//         <RemoveBlockButton item={props.item} removeItem={props.removeItem} blockTitle={"Certification #2"} />
//         <div
//           style={{
//             fontSize: 14,
//             display: "flex",
//             justifyContent: "space-evenly",
//             alignItems: "center",
//             margin: "0px 4px",
//             width: "100%",
//             opacity: isEmpty ? 0.5 : 1,
//           }}
//         >
//           {toBeShownCertifications.data.map((certificate: Certificate) => {
//             return (
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   margin: 0,
//                 }}
//                 key={certificate.id}
//               >
//                 <AwardIcon style={{ color: formStyles.accentColor }} fontSize="large" />

//                 <div style={{ fontWeight: 600, marginBottom: 1, textAlign: "center" }}>
//                   {certificate.certificateName}
//                   <a href={certificate.certificateLink}>
//                     <LaunchRoundedIcon />
//                   </a>
//                 </div>
//                 <div style={{ fontWeight: 500, margin: 1, textAlign: "center", color: formStyles.accentColor }}>
//                   {certificate.certificateDate}
//                 </div>
//                 <div style={{ fontWeight: 500, margin: 1, textAlign: "center" }}>{certificate.organizationName}</div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };
