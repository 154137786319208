import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { LockOutlinedIcon } from "../../static/Icons/Icons";
import { TextInput } from "../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";
import AppBarHeader from "../../Components/AppBarHeader";
import Footer from "../../Components/Footer";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  // const [error, setError] = useState("");
  const [authing, setAuthing] = useState(false);

  // const { resetPassword, currentUser } = useAuth();
  const auth = getAuth();

  const handleResetPassword = async () => {
    setAuthing(true);
    try {
      sendPasswordResetEmail(auth, email).then(() => {
        console.log("Password reset email sent!");
        setMessage("Password reset email sent!\nCheck your email to reset your password.");
        // navigate("/");
      });
    } catch (e) {
      console.log("Something went wrong.");
      setAuthing(false);
    }
  };

  return (
    <div className="pageWrapper">
      <AppBarHeader />
      <div className="componentWrapper">
        <div className="iconAvatar">
          <LockOutlinedIcon />
        </div>
        <h2 className="authHeader">Reset Password</h2>
        <form className="form" noValidate>
          <TextInput
            required={true}
            value={email}
            label="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <ButtonComponent
            // disabled={authing}
            fullWidth
            onClick={handleResetPassword}
          >
            Reset Password
          </ButtonComponent>
          <div className="miscOptions">
            <a href="http://localhost:3000/login">Have an account? Log in.</a>
          </div>
        </form>
        <div style={{ margin: 8, color: "#5b6be6" }}>{message}</div>
      </div>
      <Footer />
    </div>
  );
}
