import "./_BlocksStyles.css";

import React, { FC, useEffect, useState } from "react";
import { BarRatings, Ratings, StarRatings } from "../../../../interfaces/Ratings";
import { GridItem } from "../../../../interfaces/GridItem";
import { RemoveBlockButton } from "./_CommonComponents";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";

import {
  CircleFilledComponentIcon,
  CircleOutlinedComponentIcon,
  SquareFilledComponentIcon,
  SquareOutlinedComponentIcon,
  StarFilledComponentIcon,
  StarOutlinedComponentIcon,
} from "../../../../static/Icons/ComponentIcons";

const dummyStarRatings: StarRatings = {
  title: "Language",
  ratingType: "star",
  icon: "square",
  flipped: false,
  data: [
    { id: "1", ratingSubject: "English", rateInPercentage: 80 },
    { id: "2", ratingSubject: "Hindi", rateInPercentage: 80 },
    { id: "3", ratingSubject: "Bengali", rateInPercentage: 100 },
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

const dummyBarRatings: BarRatings = {
  title: "Language",
  ratingType: "star",
  barRadius: 12,
  barWidth: 10,
  flipped: false,
  data: [
    { id: "1", ratingSubject: "English", rateInPercentage: 80 },
    { id: "2", ratingSubject: "Hindi", rateInPercentage: 80 },
    { id: "3", ratingSubject: "Bengali", rateInPercentage: 100 },
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

const isEmptyRatings = (ratings: Ratings) => {
  if (ratings.data.length === 0 && ratings.title === "") return true;
  return false;
};

interface RatingBlockProps {
  item: GridItem;
}
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS BLOCK #2
export const RatingsBlock1: FC<RatingBlockProps> = (props) => {
  const ratings1 = useAppSelector((state) => state.ratings1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Ratings
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyRatings(ratings1));
  }, [ratings1]);

  const toBeShownRatings = isEmpty ? dummyStarRatings : ratings1;

  return (
    <>
      <div
        style={{
          backgroundColor: toBeShownRatings.style.bgColor,
          color: toBeShownRatings.style.textColor,
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
          <BlockTitle
            formStyles={formStyles}
            title={toBeShownRatings.title}
            isOpaque={isEmpty}
            flipped={ratings1.flipped}
          />

          <RemoveBlockButton item={props.item} blockTitle={"Ratings #1"} flipped={ratings1.flipped} />
          <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, opacity: isEmpty ? 0.5 : 1, marginTop: "8px" }}>
            {toBeShownRatings.data.map((eachRating, i) => {
              return (
                <div
                  key={eachRating.ratingSubject + i}
                  style={{
                    display: "flex",
                    flexDirection: ratings1.flipped ? "row-reverse" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <div style={{ margin: "0px 0px 2px 0px" /* for aligning with the starts */ }}>
                    {eachRating.ratingSubject}
                  </div>
                  <div>
                    {eachRating.rateInPercentage === undefined ? (
                      eachRating.rateInPercentage
                    ) : (
                      <div>
                        {getStarsArray(
                          Math.round(eachRating.rateInPercentage / 20),
                          ratings1.icon,
                          formStyles.accentColor,
                          22,
                          ratings1.flipped
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS BLOCK #2
export const RatingsBlock2: FC<RatingBlockProps> = (props) => {
  const ratings2 = useAppSelector((state) => state.ratings2);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Ratings
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyRatings(ratings2));
  }, [ratings2]);

  const toBeShownRatings = isEmpty ? dummyStarRatings : ratings2;
  // const toBeShownRatings = ratings2.data.length === 0 && ratings2.title === "" ? dummyRatings : ratings2;

  return (
    <>
      <div
        style={{
          backgroundColor: toBeShownRatings.style.bgColor,
          color: toBeShownRatings.style.textColor,
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
          <div style={{ display: "flex", flexDirection: ratings2.flipped ? "row-reverse" : "row" }}>
            <BlockTitle
              formStyles={formStyles}
              title={toBeShownRatings.title}
              isOpaque={isEmpty}
              flipped={ratings2.flipped}
            />
            <RemoveBlockButton item={props.item} blockTitle={"Ratings #2"} flipped={ratings2.flipped} />
          </div>

          <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, opacity: isEmpty ? 0.5 : 1, marginTop: "8px" }}>
            {toBeShownRatings.data.map((eachRating, i) => {
              return (
                <div
                  key={eachRating.ratingSubject + i}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: ratings2.flipped ? "flex-end" : "flex-start",
                    margin: "10px 0px",
                  }}
                >
                  <div style={{ margin: "0px 0px 2px 0px" /* for aligning with the starts */ }}>
                    {eachRating.ratingSubject}
                  </div>
                  <div>
                    {eachRating.rateInPercentage === undefined ? (
                      eachRating.rateInPercentage
                    ) : (
                      <div>
                        {getStarsArray(
                          Math.round(eachRating.rateInPercentage / 20),
                          ratings2.icon,
                          formStyles.accentColor,
                          20,
                          ratings2.flipped
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS BLOCK #3
export const RatingsBlock3: FC<RatingBlockProps> = (props) => {
  const ratings3 = useAppSelector((state) => state.ratings3);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Ratings
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyRatings(ratings3));
  }, [ratings3]);

  const toBeShownRatings = isEmpty ? dummyBarRatings : ratings3;
  // const toBeShownRatings = ratings2.data.length === 0 && ratings2.title === "" ? dummyRatings : ratings2;

  return (
    <>
      <div
        style={{
          backgroundColor: toBeShownRatings.style.bgColor,
          color: toBeShownRatings.style.textColor,
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
          <div style={{ display: "flex", flexDirection: ratings3.flipped ? "row-reverse" : "row" }}>
            <BlockTitle
              formStyles={formStyles}
              title={toBeShownRatings.title}
              isOpaque={isEmpty}
              flipped={ratings3.flipped}
            />
            <RemoveBlockButton item={props.item} blockTitle={"Ratings #3"} flipped={ratings3.flipped} />
          </div>

          <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, opacity: isEmpty ? 0.5 : 1, marginTop: "8px" }}>
            {toBeShownRatings.data.map((eachRating, i) => {
              return (
                <div
                  key={eachRating.ratingSubject + i}
                  style={{
                    display: "flex",
                    flexDirection: ratings3.flipped ? "row-reverse" : "row",
                    textAlign: ratings3.flipped ? "right" : "left",
                    alignItems: "center",
                    margin: "10px 0px",
                    width: "100%",
                    // height: `${ratings3.barWidth}px`,
                  }}
                >
                  <div style={{ margin: "0px 0px 4px 0px", width: "40%" }}>{eachRating.ratingSubject}</div>
                  <div style={{ width: "60%" }}>
                    {eachRating.rateInPercentage === undefined
                      ? eachRating.rateInPercentage
                      : getProgressBar(
                          eachRating.rateInPercentage,
                          formStyles.accentColor,
                          ratings3.barRadius,
                          ratings3.barWidth,
                          ratings3.flipped
                        )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS BLOCK #4
export const RatingsBlock4: FC<RatingBlockProps> = (props) => {
  const ratings4 = useAppSelector((state) => state.ratings4);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Ratings
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyRatings(ratings4));
  }, [ratings4]);

  const toBeShownRatings = isEmpty ? dummyBarRatings : ratings4;
  // const toBeShownRatings = ratings2.data.length === 0 && ratings2.title === "" ? dummyRatings : ratings2;

  return (
    <>
      <div
        style={{
          backgroundColor: toBeShownRatings.style.bgColor,
          color: toBeShownRatings.style.textColor,
          height: "100%",
          width: "100%",
        }}
      >
        <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
          <div style={{ display: "flex", flexDirection: ratings4.flipped ? "row-reverse" : "row" }}>
            <BlockTitle
              formStyles={formStyles}
              title={toBeShownRatings.title}
              isOpaque={isEmpty}
              flipped={ratings4.flipped}
            />
            <RemoveBlockButton item={props.item} blockTitle={"Ratings #4"} flipped={ratings4.flipped} />
          </div>

          <div style={{ paddingLeft: 8, fontSize: 15, fontWeight: 600, opacity: isEmpty ? 0.5 : 1, marginTop: "8px" }}>
            {toBeShownRatings.data.map((eachRating, i) => {
              return (
                <div
                  key={eachRating.ratingSubject + i}
                  style={{
                    margin: "8px 0px",
                    width: "100%",
                    textAlign: ratings4.flipped ? "right" : "left",
                    // height: "36px"
                  }}
                >
                  <div style={{ margin: "0px 0px 6px 0px", width: "100%" }}>{eachRating.ratingSubject}</div>
                  {eachRating.rateInPercentage === undefined
                    ? eachRating.rateInPercentage
                    : getProgressBar(
                        eachRating.rateInPercentage,
                        formStyles.accentColor,
                        ratings4.barRadius,
                        ratings4.barWidth,
                        ratings4.flipped
                      )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

function getStarsArray(
  numberOfRatesInStar: number,
  type: string,
  color: string,
  size: number,
  flipped: boolean
): React.ReactNode {
  return flipped ? (
    <div>
      {[...Array(5 - numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_empty", i, color, size)}</span>;
      })}
      {[...Array(numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_fill", i, color, size)}</span>;
      })}
    </div>
  ) : (
    <div>
      {[...Array(numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_fill", i, color, size)}</span>;
      })}
      {[...Array(5 - numberOfRatesInStar)].map((e, i) => {
        return <span key={i}>{chooseIcon(type + "_empty", i, color, size)}</span>;
      })}
    </div>
  );
}
function chooseIcon(iconName: string, key: number, color: string, size: number) {
  switch (iconName) {
    case "star_fill":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <StarFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "star_empty":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <StarOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    case "circle_fill":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <CircleFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "circle_empty":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <CircleOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    case "square_fill":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <SquareFilledComponentIcon key={key + "_fill"} />
        </div>
      );
    case "square_empty":
      return (
        <div style={{ display: "inline", padding: "2px", color: color }}>
          <SquareOutlinedComponentIcon key={key + "_empty"} />
        </div>
      );
    default:
      break;
  }
}

function getProgressBar(percentage: number, color: string, barRadius: number, barWidth: number, flipped: boolean) {
  return flipped ? (
    <div style={{ width: "100%", height: `${barWidth}px`, display: "flex" }}>
      <div
        style={{
          display: percentage === 100 ? "none" : "block",
          width: `${100 - percentage}%`,
          backgroundColor: "transparent",
          border: `2px solid ${color}`,
          borderRadius: percentage === 0 ? `${barRadius}px` : `${barRadius}px 0px 0px ${barRadius}px`,
        }}
      >
        &nbsp;
      </div>
      <div
        style={{
          display: percentage === 0 ? "none" : "block",
          width: `${percentage}%`,
          backgroundColor: color,
          border: `2px solid ${color}`,
          borderRadius: percentage === 100 ? `${barRadius}px` : `0px ${barRadius}px ${barRadius}px 0px`,
        }}
      >
        &nbsp;
      </div>
    </div>
  ) : (
    <div style={{ width: "100%", height: `${barWidth}px`, display: "flex" }}>
      <div
        style={{
          display: percentage === 0 ? "none" : "block",
          width: `${percentage}%`,
          backgroundColor: color,
          border: `2px solid ${color}`,
          borderRadius: percentage === 100 ? `${barRadius}px` : `${barRadius}px 0px 0px ${barRadius}px`,
        }}
      >
        &nbsp;
      </div>
      <div
        style={{
          display: percentage === 100 ? "none" : "block",
          width: `${100 - percentage}%`,
          backgroundColor: "transparent",
          border: `2px solid ${color}`,
          borderRadius: percentage === 0 ? `${barRadius}px` : `0px ${barRadius}px ${barRadius}px 0px`,
        }}
      >
        &nbsp;
      </div>
    </div>
  );
}
