import "./ContactPage.css";

import React, { useState } from "react";

import AppBarHeader from "../../Components/AppBarHeader";
import Footer from "../../Components/Footer";
import { EMAIL_LINK } from "../../constants";
import { DiscordIcon, EmailIcon, GithubIcon, LinkedInIcon, TwitterXIcon } from "../../static/Icons/SocialMediaIcons";
import { IconButtonComponent } from "../../Components/Inputs/IconButtonComponent";
import { TextInput, TextInputMultiline } from "../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";

interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = () => {
  const [senderName, setSenderName] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const gotoLink = (link: string) => {
    window.open(link, "_blank");
  };
  const sendEmail = () => {
    const emailLink =
      "mailto:gourabpaul900@gmail.com" +
      // Email Subject(includes sender name in the end)
      `?subject=${emailSubject + " - by " + senderName + " from Resumez.in"}` +
      // Email Body
      `&body=${emailBody}`;
    gotoLink(emailLink);
  };

  return (
    <>
      <AppBarHeader />
      <div className="contactPageWrapper">
        <div className="contactCard">
          <h1 className="contactPageHeader">Contact Info</h1>
          <div>
            <TextInput label="Your Name" value={senderName} onChange={(e) => setSenderName(e.target.value)} />
            <TextInput label="Subject" value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} />
            <TextInputMultiline label="Body" value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
          </div>
          <div>&nbsp;</div>
          <ButtonComponent onClick={sendEmail} fullWidth>Send&nbsp;Email</ButtonComponent>

          <div
            style={{
              padding: 20,
              margin: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <IconButtonComponent
              size="medium"
              onClick={() => gotoLink("https://github.com/GourabPaul007")}
              iconColor="#333"
            >
              <GithubIcon size={24} />
            </IconButtonComponent>
            <IconButtonComponent
              size="medium"
              onClick={() => gotoLink("https://www.linkedin.com/in/gourab-paul-aa0605216/")}
              iconColor="#1e88e5"
            >
              <LinkedInIcon size={24} />
            </IconButtonComponent>
            <IconButtonComponent
              size="medium"
              onClick={() => gotoLink("https://twitter.com/gourab_paul007")}
              iconColor="#1da1f2"
            >
              <TwitterXIcon size={24} />
            </IconButtonComponent>
            <IconButtonComponent size="medium" onClick={() => gotoLink(`mailto:${EMAIL_LINK}.com`)} iconColor="#EA4335">
              <EmailIcon size={24} />
            </IconButtonComponent>
            <IconButtonComponent
              size="medium"
              onClick={() => gotoLink("https://discord.gg/YczfSVXNcm")}
              iconColor="#5b6be6"
            >
              <DiscordIcon size={24} />
            </IconButtonComponent>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
