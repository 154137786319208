import React, { FC } from "react";
import "./RatingInput.css";

interface RatingComponentProps {
  name: string;
  value: number;
  onChange: (e: any, value: number) => void;
  icon: JSX.Element;
  emptyIcon: JSX.Element;
  totalStars?: number;
  size?: string;
  readOnly?: boolean;
}

export const RatingComponent: FC<RatingComponentProps> = ({
  name,
  value,
  onChange,
  icon,
  emptyIcon,
  totalStars = 5,
  size = "2rem",
  readOnly = false
}) => {
  const starStyles = {
    fontSize: size,
    cursor: readOnly ? "default" : "pointer",
  };

  const handleRating = (rate: number) => {
    if (!readOnly && onChange) {
      onChange(null, rate);
    }
  };

  return (
    <div className="ratingInputWrapper" aria-label={name}>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          className="ratingStar"
          style={starStyles}
          onClick={() => handleRating(index + 1)}
        >
          {value > index ? icon : emptyIcon}
        </span>
      ))}
    </div>
  );
};

// const [hovered, setHovered] = useState(0);
// const handleRating = (rate) => {
//   if (!readOnly && onChange) {
//     onChange(null, rate);
//   }
// };
// const handleMouseEnter = (index) => {
//   setHovered(index + 1);
// };
// const handleMouseLeave = () => {
//   setHovered(0);
// };
// const getStarColor = (index) => {
//   if (hovered > index) {
//     return hoverColor;
//   }
//   if (value > index) {
//     return color;
//   }
//   return emptyColor;
// };
// const starStyles = {
//   fontSize: size,
//   cursor: readOnly ? "default" : "pointer",
// };
// return (
//   <div className="rating" aria-label={name}>
//     {[...Array(totalStars)].map((_, index) => (
//       <span
//         key={index}
//         className="star"
//         style={{ ...starStyles, color: getStarColor(index) }}
//         onClick={() => handleRating(index + 1)}
//         onMouseEnter={() => handleMouseEnter(index)}
//         onMouseLeave={handleMouseLeave}
//       >
//         {value > index ? icon : emptyIcon}
//       </span>
//     ))}
//   </div>
// );