import "./ButtonComponent.css";

import React, { FC } from "react";

interface ButtonComponentProps {
  // text: string;
  loading?: boolean;
  bgColor?: string;
  textColor?: string;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  variant?: "filled" | "outlined";
  type?: "submit" | "button" | "reset";
  onClick: (() => void) | ((e: React.MouseEvent<HTMLButtonElement>) => Promise<void>);
  children: React.ReactNode;
}

export const ButtonComponent: FC<ButtonComponentProps> = ({
  loading,
  bgColor,
  textColor,
  fullWidth,
  size = "medium",
  variant = "filled",
  type,
  onClick,
  children,
}) => {
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // onClick();
    if (onClick.length === 0) {
      await (onClick as () => void)();
    } else {
      await (onClick as (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>)(e);
    }
  };

  return (
    <button
      type={type}
      className={`customButton ${loading ? "customButtonLoading" : ""} ${fullWidth ? "fullWidth" : ""}`}
      onClick={handleClick}
      style={{
        backgroundColor: loading ? "var(--color-muted)" : variant === "outlined" ? "transparent" : bgColor,
        color: loading ? "var(--color-on-surface)" : variant === "outlined" ? "var(--color-primary)" : textColor,
        padding: size === "small" ? "0.5rem 1rem" : size === "medium" ? "0.75rem 1.5rem" : "1rem 2rem",
        border: variant === "outlined" ? `1px solid var(--color-primary)` : "none",
      }}
    >
      {children}
    </button>
  );
};
