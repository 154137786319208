import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { StarRatings } from "../../../interfaces/Ratings";

export const initialStateRatings2: StarRatings = {
  title: "",
  ratingType: "star",
  icon: "star",
  flipped: false,
  data: [
    // { id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 }
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const ratings2Slice = createSlice({
  name: "ratings2",
  initialState: initialStateRatings2,
  reducers: {
    setBlockDataRatings2: (state, action: PayloadAction<StarRatings>) => {
      state.title = action.payload.title;
      state.ratingType = action.payload.ratingType;
      state.icon = action.payload.icon;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setBlockTitleRatings2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setRatingTypeRatings2: (state, action: PayloadAction<"star"|"bar">) => {
      state.ratingType = action.payload;
    },
    setRatingIconRatings2: (state, action: PayloadAction<"star"|"circle"|"square">) => {
      state.icon = action.payload;
    },
    setFlippedRatings2: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setDataRatingSubjectRatings2: (state, action: PayloadAction<{ratingSubject: string, ratingId:string}>) => {
      const { ratingSubject, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].ratingSubject = ratingSubject;
    },
    setDataRateInPercentageRatings2: (state, action: PayloadAction<{rateInPercentage: number, ratingId:string}>) => {
      const { rateInPercentage, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].rateInPercentage = rateInPercentage;
    },
    addRatingRatings2: (state) => {
      state.data.push({ id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 });
    },
    removeRatingRatings2: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((rating) => rating.id !== action.payload);
    },
    setStyleRatings2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataRatings2,
  setBlockTitleRatings2,
  setRatingTypeRatings2,
  setRatingIconRatings2,
  setFlippedRatings2,
  setDataRatingSubjectRatings2,
  setDataRateInPercentageRatings2,
  addRatingRatings2,
  removeRatingRatings2,
  setStyleRatings2,
} = ratings2Slice.actions;

export default ratings2Slice.reducer;
