import React, { FC } from "react";
import './GridComponent.css'; // Include the CSS file

type GridContainerProps = {
  children: React.ReactNode;
  spacing?: number; // Spacing between grid items in pixels
  alignItems?: 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
};

export const GridContainer: FC<GridContainerProps> = ({
  children,
  spacing = 0,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
}) => {
  return (
    <div
      className="gridContainer"
      style={{
        gap: `${spacing}px`,
        alignItems,
        justifyContent,
      }}
    >
      {children}
    </div>
  );
};

type GridItemProps = {
  children: React.ReactNode;
  xs?: number; // Number of columns to span on extra small screens
  sm?: number; // Number of columns to span on small screens
  md?: number; // Number of columns to span on medium screens
  lg?: number; // Number of columns to span on large screens
  style?: React.CSSProperties;
};

export const GridItem: FC<GridItemProps> = ({ children, xs = 12, sm, md, lg, style }) => {
  return (
    <div
      className={`gridItem col-xs-${xs} ${sm ? `col-sm-${sm}` : ''} ${md ? `col-md-${md}` : ''} ${lg ? `col-lg-${lg}` : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};