import React, { useEffect, useState } from "react";
import { BlueprintWrapper } from "./_BlueprintStyles";
import { Photo } from "../../../interfaces/Photo";
import { base64ToBlob, chooseDefaultAvatars } from "../../../helpers/photoHelpers";
import { useAppSelector } from "../../../app/hooks";
import { _isProd } from "../../../constants";

interface AboutBlueprintProps {
  photo: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: Photo;
  };
}

export const PhotoBlueprint1: React.FC<AboutBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);
  
  const [toBeShownPhotoBlob, setToBeShownPhotoBlob] = useState<Blob | null>(null);
  const [defaultAvatar, setDefaultAvatar] = useState("Avatars1");

  useEffect(() => {
    try {
      let avatarBase64 = localStorage.getItem("avatarBase64") as string;
      // avatarBase64 = avatarBase64.split("base64,")[1];
      const blobAvatar = base64ToBlob(avatarBase64);
      setToBeShownPhotoBlob(blobAvatar);
    } catch (error) {
      console.error(error);
    }
  }, [props.photo.data.hasPhoto]);

  useEffect(() => {
    const photoNo = Math.floor(Math.random() * 5) + 1;
    const chosenDefaultAvatar = chooseDefaultAvatars(photoNo);
    setDefaultAvatar(chosenDefaultAvatar);
  }, []);

  return (
    <BlueprintWrapper
      h={props.photo.h}
      x={props.photo.x}
      y={props.photo.y}
      w={props.photo.w}
      bgColor={props.photo.data.style.bgColor}
      textColor={props.photo.data.style.textColor}
      fontFamily={formStyles.fontFamily}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {toBeShownPhotoBlob && props.photo.data.hasPhoto ? (
          <img
            src={URL.createObjectURL(toBeShownPhotoBlob)}
            alt="not found"
            // height={`${props.photo.data.height}px`}
            width={`${props.photo.data.width*10}px`}
            style={{
              border: `${props.photo.data.borderWidth}px solid ${props.photo.data.borderColor}`,
              borderRadius: `${props.photo.data.borderRadius}%`,
            }}
          />
        ) : (
          <img
            src={defaultAvatar}
            alt="not found"
            // height={`${props.photo.data.height}px`}
            width={`${props.photo.data.width}px`}
            style={{
              border: `${props.photo.data.borderWidth}px solid ${props.photo.data.borderColor}`,
              borderRadius: `${props.photo.data.borderRadius}%`,
            }}
          />
        )}
      </div>
    </BlueprintWrapper>
  );
};
