import React, { FC, useEffect, useRef, useState } from "react";
import "./CollapseComponent.css"; // Import the CSS file

interface CollapseProps {
  expanded: boolean;
  children: React.ReactNode;
}

const Collapse: FC<CollapseProps> = (props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (contentRef.current) {
      setHeight(props.expanded ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [props.expanded]);

  return (
    <div className="collapse-container" style={{ height: height }} ref={contentRef}>
      <div className="collapse-content">{props.children}</div>
    </div>
  );
};

export default Collapse;
