import React, { FC } from "react";
import "./Throbber.css";

interface ThrobberProps {
  backgroundColor?: string;
}

const Throbber: FC<ThrobberProps> = (props) => {
  return (
    <div className="throbber"
      style={{backgroundColor: props.backgroundColor || "var(--color-background)"}}
    >
      <div className="cardWrapper">
        <div className="cardContent">
          <div className="textWrapper">𝚁</div>
        </div>
      </div>
    </div>
  );
};

export default Throbber;