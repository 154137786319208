import React from "react";

export const NameIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={35} y={60} width={100} height={20} rx={5} fill="#555" />
        <rect x={145} y={70} width={65} height={10} rx={2} fill="#555" />
      </svg>
    </div>
  );
};
