import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BarRatings } from "../../../interfaces/Ratings";

export const initialStateRatings4: BarRatings = {
  title: "",
  ratingType: "bar",
  flipped: false,
  barRadius: 12,
  barWidth: 10,
  data: [
    // { id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 }
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const ratings4Slice = createSlice({
  name: "ratings4",
  initialState: initialStateRatings4,
  reducers: {
    setBlockDataRatings4: (state, action: PayloadAction<BarRatings>) => {
      state.title = action.payload.title;
      state.ratingType = action.payload.ratingType;
      state.barWidth = action.payload.barWidth;
      state.barRadius = action.payload.barRadius;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setBlockTitleRatings4: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setRatingTypeRatings4: (state, action: PayloadAction<"star"|"bar">) => {
      state.ratingType = action.payload;
    },
    setBarWidthRatings4: (state, action: PayloadAction<number>) => {
      state.barWidth = action.payload;
    },
    setBarRadiusRatings4: (state, action: PayloadAction<number>) => {
      state.barRadius = action.payload;
    },
    setFlippedRatings4: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setRatingSubjectRatings4: (state, action: PayloadAction<{ratingSubject: string, ratingId:string}>) => {
      const { ratingSubject, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].ratingSubject = ratingSubject;
    },
    setPercentageInRatings4: (state, action: PayloadAction<{rateInPercentage: number, ratingId:string}>) => {
      const { rateInPercentage, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].rateInPercentage = rateInPercentage;
    },
    addNewRatingRatings4: (state) => {
      state.data.push({ id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 });
    },
    removeRatingRatings4: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((rating) => rating.id !== action.payload);
    },
    setStyleRatings4: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataRatings4,
  setBlockTitleRatings4,
  setRatingTypeRatings4,
  setBarWidthRatings4,
  setBarRadiusRatings4,
  setFlippedRatings4,
  setRatingSubjectRatings4,
  setPercentageInRatings4,
  addNewRatingRatings4,
  removeRatingRatings4,
  setStyleRatings4,
} = ratings4Slice.actions;

export default ratings4Slice.reducer;
