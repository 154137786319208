import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";

import { checkHyperlink } from "../../../../helpers/checkHyperlink";
import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { Project, Projects } from "../../../../interfaces/Projects";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";

const exampleProjects: Projects = {
  title: "Projects",
  bullet: false,
  indent: true,
  data: [
    {
      id: "projectWed Jan 12 2022 13:38:12 GMT+0530 (India Standard Time)",
      projectName: "Project Name",
      projectDetails: [
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit recusandae quidem veniam.",
        "Used Technologies: ReactJS, TS, React-Redux-Toolkit, NodeJS, ExpressJS, EJS, TypeScript.",
      ],
    },
    {
      id: "projectWed Jan 12 2022 13:36:24 GMT+0530 (India Standard Time)",
      projectName: "Project Name",
      projectDetails: [
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsum debitis, omnis eos sed recusandae ea.",
        "Tempore explicabo quaerat dolorum dolores totam alias. Vitae at quod blanditiis autem magni porro non.",
      ],
    },
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

const isProjectsEmpty = (projects: Projects): boolean => {
  return projects.data.every((value) => {
    // 1st -> checks if name is empty string, 2nd -> checks if all array members are empty strings
    if (value.projectName === "" && value.projectDetails.join("").length === 0 && projects.title === "") {
      console.log("true");

      return true;
    }
    console.log("false");
    return false;
  });
};

interface ProjectsBlockProps {
  item: GridItem;
}

export const ProjectsBlock1: React.FC<ProjectsBlockProps> = (props) => {
  const projects1 = useAppSelector(state=>state.projects1)
  const formStyles = useAppSelector(state=>state.formStyles);

  // Check For Empty Projects
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isProjectsEmpty(projects1));
  }, [projects1]);

  const toBeShownProjects = isEmpty ? exampleProjects : projects1;

  return (
    <div
      style={{
        backgroundColor: toBeShownProjects.style.bgColor,
        color: toBeShownProjects.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownProjects.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Projects #1"} />
        <div style={{ opacity: isEmpty ? 0.5 : 1 }}>
          {toBeShownProjects.data.map((eachProject: Project) => {
            return (
              <div key={eachProject.id} style={{ marginLeft: 12, marginTop: 8, fontSize: 14 }}>
                {/* Project Name */}
                <h4 style={{ fontSize: 18 }}>{eachProject.projectName}</h4>
                {/* Project Details */}
                <div style={{ margin: `4px 0px 8px ${projects1.indent ? 16 : 0}px`, fontWeight: 500 }}>
                  {eachProject.projectDetails.map((detail: string) => {
                    return (
                      <div
                        key={detail}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "start",
                          // marginBottom: 4,
                        }}
                      >
                        {projects1.bullet ? <>•&nbsp;</> : null}
                        {checkHyperlink(detail)}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const ProjectsBlock2: React.FC<ProjectsBlockProps> = (props) => {
  const projects2 = useAppSelector(state=>state.projects2);
  const formStyles = useAppSelector(state=>state.formStyles);

  // Check For Empty Projects
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isProjectsEmpty(projects2));
  }, [projects2]);

  const toBeShownProjects = isEmpty ? exampleProjects : projects2;

  return (
    <div
      style={{
        backgroundColor: toBeShownProjects.style.bgColor,
        color: toBeShownProjects.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownProjects.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Projects #2"} />
        <div style={{ opacity: isEmpty ? 0.5 : 1 }}>
          {toBeShownProjects.data.map((eachProject: Project) => {
            return (
              <div key={eachProject.id} style={{ margin: "12px 0px 0px 8px", fontSize: 14 }}>
                {/* Project Name */}
                <h4 style={{ fontSize: 18 }}>{eachProject.projectName}</h4>
                {/* Project Details */}
                <div style={{ margin: `4px 0px 8px ${projects2.indent ? 16 : 0}px`, fontWeight: 500 }}>
                  {eachProject.projectDetails.map((detail: string) => {
                    return (
                      <div
                        key={detail}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "start",
                          // marginBottom: 4,
                        }}
                      >
                        {projects2.bullet ? <>•&nbsp;</> : null}
                        {checkHyperlink(detail)}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
