import "./_FormsStyles.css";

import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setAddressContact1,
  setEmailsContact1,
  setFlippedContact1,
  setPhnoContact1,
  setStyleContact1,
  setTitleContact1,
} from "../../../../features/elements/contact/contact1Slice";
import {
  setFlippedContact2,
  setTitleContact2,
  setEmailsContact2,
  setAddressContact2,
  setPhnoContact2,
  setStyleContact2,
} from "../../../../features/elements/contact/contact2Slice";
import {
  setFlippedContact3,
  setTitleContact3,
  setEmailsContact3,
  setAddressContact3,
  setPhnoContact3,
  setStyleContact3,
} from "../../../../features/elements/contact/contact3Slice";
import { CollapseableForm } from "./_CommonComponents";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { ButtonGroupComponent2 } from "../../../../Components/Inputs/ButtonGroupComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface ContactFormProps {}

export const Contact1Form: FC<ContactFormProps> = React.memo((props) => {
  const contact1 = useAppSelector((state) => state.contact1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const handleFlip = (flipped: boolean) => dispatch(setFlippedContact1(flipped));
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleContact1({ ...contact1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleContact1({ ...contact1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Contact #1</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput
            value={contact1.title}
            label="Title"
            onChange={(e) => {
              dispatch(setTitleContact1(e.target.value));
            }}
          />
        </GridItem>
        <GridItem xs={1} md={3}>
          <></>
        </GridItem>
        <GridItem xs={12} md={3}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "4px 0px" }}>
            <ButtonGroupComponent2 flipped={contact1.flipped} handleFlip={handleFlip} />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Emails Or Social media links (separate each link with comma ',')"
            value={contact1.data.emails.join(",")}
            onChange={(e) => dispatch(setEmailsContact1(e.target.value.split(",")))}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <TextInput
            label="Address"
            value={contact1.data.address.join("<br>")}
            onChange={(e) => dispatch(setAddressContact1(e.target.value.split("<br>")))}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <TextInput
            label="Phone Number"
            value={contact1.data.phno}
            onChange={(e) => dispatch(setPhnoContact1(e.target.value))}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={contact1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={contact1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const Contact2Form: FC<ContactFormProps> = React.memo((props) => {
  const contact2 = useAppSelector((state) => state.contact2);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const handleFlip = (flipped: boolean) => dispatch(setFlippedContact2(flipped));
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleContact2({ ...contact2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleContact2({ ...contact2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Contact #2</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput
            value={contact2.title}
            label="Title"
            onChange={(e) => {
              // props.setContact({ ...props.contact, title: e.target.value });
              dispatch(setTitleContact2(e.target.value));
            }}
          />
        </GridItem>
        <GridItem xs={1} md={3}>
          <></>
        </GridItem>
        {/* style={{ display: "flex", justifyContent: "center", alignItems: "center" }} */}
        <GridItem xs={12} md={3}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "4px 0px" }}>
            <ButtonGroupComponent2 flipped={contact2.flipped} handleFlip={handleFlip} />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Emails Or Social media links (separate each link with comma ',')"
            value={contact2.data.emails.join(",")}
            onChange={(e) => {
              dispatch(setEmailsContact2(e.target.value.split(",")));
            }}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <TextInput
            label="Address"
            value={contact2.data.address.join("<br>")}
            onChange={(e) => {
              dispatch(setAddressContact2(e.target.value.split("<br>")));
            }}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <TextInput
            label="Phone Number"
            value={contact2.data.phno}
            onChange={(e) => {
              dispatch(setPhnoContact2(e.target.value));
            }}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={contact2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={contact2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const Contact3Form: FC<ContactFormProps> = React.memo((props) => {
  const contact3 = useAppSelector((state) => state.contact3);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  const handleFlip = (flipped: boolean) => dispatch(setFlippedContact3(flipped));
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleContact3({ ...contact3.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleContact3({ ...contact3.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Contact #3</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput
            label="Title"
            value={contact3.title}
            onChange={(e) => {
              dispatch(setTitleContact3(e.target.value));
            }}
          />
        </GridItem>
        <GridItem xs={1} md={3}>
          <></>
        </GridItem>
        <GridItem xs={12} md={3}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "4px 0px" }}>
            <ButtonGroupComponent2 flipped={contact3.flipped} handleFlip={handleFlip} />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Emails Or Social media links (separate each link with comma ',')"
            value={contact3.data.emails.join(",")}
            onChange={(e) => {
              dispatch(setEmailsContact3(e.target.value.split(",")));
            }}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <TextInput
            label="Address"
            value={contact3.data.address.join("<br>")}
            onChange={(e) => {
              dispatch(setAddressContact3(e.target.value.split("<br>")));
            }}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <TextInput
            label="Phone Number"
            value={contact3.data.phno}
            onChange={(e) => {
              dispatch(setPhnoContact3(e.target.value));
            }}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={contact3.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={contact3.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
