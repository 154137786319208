import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Projects } from "../../../interfaces/Projects";

export const initialStateProjects1: Projects = {
  title: "",
  bullet: true,
  indent: true,
  data: [{ id: `project${Date.now()}`, projectName: "", projectDetails: [""] }],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const projects1Slice = createSlice({
  name: "projects1",
  initialState: initialStateProjects1,
  reducers: {
    setBlockDataProjects1: (state, action: PayloadAction<Projects>) => {
      state.title = action.payload.title;
      state.bullet = action.payload.bullet;
      state.indent = action.payload.indent;
      state.data = action.payload.data.map((item) => ({
        ...item,
        projectDetails: [...item.projectDetails],
      }));
      state.style = action.payload.style;
    },
    setTitleProjects1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBulletProjects1: (state, action: PayloadAction<boolean>) => {
      state.bullet = action.payload;
    },
    setIndentProjects1: (state, action: PayloadAction<boolean>) => {
      state.indent = action.payload;
    },
    setProjectNameProjects1: (state, action: PayloadAction<{projectName: string, projectId:string}>) => {
      const { projectName, projectId } = action.payload;
      const pos = state.data.findIndex((project) => project.id === projectId);
      state.data[pos].projectName = projectName;
    },
    setProjectDetailsProjects1: (state, action: PayloadAction<{projectDetails: string[], projectId:string}>) => {
      const { projectDetails, projectId } = action.payload;
      const pos = state.data.findIndex((project) => project.id === projectId);
      state.data[pos].projectDetails = projectDetails;
    },
    addProjectProjects1: (state) => {
      state.data.push({ id: `project${Date.now()}`, projectName: "", projectDetails: [""] });
    },
    removeProjectProjects1: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((project) => project.id !== action.payload);
    },
    setStyleProjects1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataProjects1,
  setTitleProjects1,
  setBulletProjects1,
  setIndentProjects1,
  setProjectNameProjects1,
  setProjectDetailsProjects1,
  addProjectProjects1,
  removeProjectProjects1,
  setStyleProjects1,
} = projects1Slice.actions;

export default projects1Slice.reducer;
