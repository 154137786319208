import React from "react";

import { checkHyperlink } from "../../../helpers/checkHyperlink";
import { Project, Projects } from "../../../interfaces/Projects";
import { BlueprintWrapper } from "./_BlueprintStyles";
import { BlueprintTitle } from "./_BlueprintTitle";
import { useAppSelector } from "../../../app/hooks";

interface ProjectsBlueprintProps {
  projects: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: Projects;
  };
}

export const ProjectsBlueprint1: React.FC<ProjectsBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);
  // const blueprintClasses = useBlueprintStyles({
  //   h: props.projects.h,
  //   x: props.projects.x,
  //   y: props.projects.y,
  //   w: props.projects.w,
  //   bgColor: props.projects.data.style.bgColor,
  //   textColor: props.projects.data.style.textColor,
  // });

  return (
    <BlueprintWrapper
      h={props.projects.h}
      x={props.projects.x}
      y={props.projects.y}
      w={props.projects.w}
      bgColor={props.projects.data.style.bgColor}
      textColor={props.projects.data.style.textColor}
      fontFamily={formStyles.fontFamily}
    >
      <BlueprintTitle formStyles={formStyles} title={props.projects.data.title} />
      {props.projects.data.data.map((eachProject: Project) => {
        return (
          <div key={eachProject.id} style={{ marginLeft: 12, marginTop: 8, fontSize: 14 }}>
            {/* Project Name */}
            <h4 style={{ fontSize: 20 }}>{eachProject.projectName}</h4>
            {/* Project Details */}
            <div style={{ margin: `4px 0px 8px ${props.projects.data.indent ? 16 : 0}px`, fontWeight: 500 }}>
              {eachProject.projectDetails.map((detail: string) => {
                return (
                  <div
                    key={detail}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "start",
                      // marginBottom: 4,
                    }}
                  >
                    {props.projects.data.bullet ? <>•&nbsp;</> : null}
                    {checkHyperlink(detail)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </BlueprintWrapper>
  );
};

export const ProjectsBlueprint2: React.FC<ProjectsBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);
  // const blueprintClasses = useBlueprintStyles({
  //   h: props.projects.h,
  //   x: props.projects.x,
  //   y: props.projects.y,
  //   w: props.projects.w,
  //   bgColor: props.projects.data.style.bgColor,
  //   textColor: props.projects.data.style.textColor,
  // });

  return (
    <BlueprintWrapper
      h={props.projects.h}
      x={props.projects.x}
      y={props.projects.y}
      w={props.projects.w}
      bgColor={props.projects.data.style.bgColor}
      textColor={props.projects.data.style.textColor}
      fontFamily={formStyles.fontFamily}
    >
      <BlueprintTitle formStyles={formStyles} title={props.projects.data.title} />
      {props.projects.data.data.map((eachProject: Project) => {
        return (
          <div key={eachProject.id} style={{ margin: "12px 0px 0px 8px", fontSize: 14 }}>
            {/* Project Name */}
            <h4 style={{ fontSize: 20 }}>{eachProject.projectName}</h4>
            {/* Project Details */}
            <div style={{ margin: `4px 0px 8px ${props.projects.data.indent ? 16 : 0}px`, fontWeight: 500 }}>
              {eachProject.projectDetails.map((detail: string) => {
                return (
                  <div
                    key={detail}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "start",
                      // marginBottom: 4,
                    }}
                  >
                    {props.projects.data.bullet ? <>•&nbsp;</> : null}
                    {checkHyperlink(detail)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </BlueprintWrapper>
  );
};
