import React from "react";

export const ProjectsIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        {/* first row */}
        {/* <rect x={20} y={30} width={70} height={10} rx={2.5} fill="#123456" /> */}
        {/* First Job */}
        <rect x={30} y={28} width={50} height={10} rx={2.5} fill="#6366f1" />
        <rect x={40} y={43} width={160} height={5} fill="#878787" />
        <rect x={40} y={53} width={160} height={5} fill="#878787" />
        <rect x={40} y={63} width={100} height={5} fill="#878787" />
        {/* 2nd Job */}
        <rect x={30} y={80} width={50} height={10} rx={2.5} fill="#6366f1" />
        <rect x={40} y={95} width={160} height={5} fill="#878787" />
        <rect x={40} y={105} width={160} height={5} fill="#878787" />
        <rect x={40} y={115} width={100} height={5} fill="#878787" />
      </svg>
    </div>
  );
};

export const ProjectsIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        {/* first row */}
        {/* <rect x={20} y={30} width={70} height={10} rx={2.5} fill="#123456" /> */}
        {/* First Job */}
        <rect x={30} y={28} width={50} height={10} rx={2.5} fill="#6366f1" />
        <rect x={30} y={43} width={170} height={5} fill="#878787" />
        <rect x={30} y={53} width={170} height={5} fill="#878787" />
        <rect x={30} y={63} width={110} height={5} fill="#878787" />
        {/* 2nd Job */}
        <rect x={30} y={80} width={50} height={10} rx={2.5} fill="#6366f1" />
        <rect x={30} y={95} width={170} height={5} fill="#878787" />
        <rect x={30} y={105} width={170} height={5} fill="#878787" />
        <rect x={30} y={115} width={110} height={5} fill="#878787" />
      </svg>
    </div>
  );
};
