import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Educations } from "../../../interfaces/Educations";

export const initialStateEducations1: Educations = {
  title: "",
  data: [{ id: `education${Date.now()}`, courseName: "", courseResults: "", organizationName: "", courseDuration: "" }],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const Educations1Slice = createSlice({
  name: "educations1",
  initialState: initialStateEducations1,
  reducers: {
    setBlockDataEducations1: (state, action: PayloadAction<Educations>) => {
      state.title = action.payload.title;
      state.data = action.payload.data.map(item => ({
        ...item,
      }));
      state.style = action.payload.style;
    },
    setTitleEducations1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setCourseNameEducations1: (state, action: PayloadAction<{ courseName: string; courseId: string }>) => {
      const { courseName, courseId } = action.payload;
      const pos = state.data.findIndex((course) => course.id === courseId);
      state.data[pos].courseName = courseName;
    },
    setCourseResultsEducations1: (state, action: PayloadAction<{ courseResults: string; courseId: string }>) => {
      const { courseResults, courseId } = action.payload;
      const pos = state.data.findIndex((course) => course.id === courseId);
      state.data[pos].courseResults = courseResults;
    },
    setOrganizationNameEducations1: (state, action: PayloadAction<{ organizationName: string; courseId: string }>) => {
      const { organizationName, courseId } = action.payload;
      const pos = state.data.findIndex((course) => course.id === courseId);
      state.data[pos].organizationName = organizationName;
    },
    setCourseDurationEducations1: (state, action: PayloadAction<{ courseDuration: string; courseId: string }>) => {
      const { courseDuration, courseId } = action.payload;
      const pos = state.data.findIndex((course) => course.id === courseId);
      state.data[pos].courseDuration = courseDuration;
    },
    addEducationEducations1: (state) => {
      state.data.push({
        id: `education${Date.now()}`,
        courseName: "",
        courseResults: "",
        organizationName: "",
        courseDuration: "",
      });
    },
    removeEducationEducations1: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((education) => education.id !== action.payload);
    },
    setStyleEducations1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataEducations1,
  setTitleEducations1,
  setCourseNameEducations1,
  setCourseResultsEducations1,
  setOrganizationNameEducations1,
  setCourseDurationEducations1,
  addEducationEducations1,
  removeEducationEducations1,
  setStyleEducations1,
} = Educations1Slice.actions;

export default Educations1Slice.reducer;
