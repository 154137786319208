import "./FormStylesForm.css";

import React, { FC, useEffect, useState } from "react";
import { accentColorList } from "../../../../helpers/colorDatabase";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setAccentColorFormStyles,
  setFontFamilyFormStyles,
  setTitleColorFormStyles,
  setTitleFillColorFormStyles,
  setTitleFilledFormStyles,
  setTitleFullWidthFormStyles,
  setTitleUnderlineFormStyles,
} from "../../../../features/formStyles/formStylesSlice";
import { CustomSwitch } from "../../../../Components/Inputs/SwitchInput";
import { ColorPickerInput } from "../../../../Components/Inputs/ColorPickerInput";
import { FontFamily } from "../../../../interfaces/FormStyles";
import { SelectComponent } from "../../../../Components/Inputs/SelectComponent";

interface FormStylesFormProps {}

export const FormStylesForm: FC<FormStylesFormProps> = React.memo(() => {
  const formStyles = useAppSelector((state) => state.formStyles);
  const dispatch = useAppDispatch();

  const [currentTitleColor, setCurrentTitleColor] = useState(
    formStyles.titleColor || accentColorList[Math.floor(Math.random() * accentColorList.length)]
  );
  const [currentTitleFillColor, setCurrentTitleFillColor] = useState(
    formStyles.titleFillColor || accentColorList[Math.floor(Math.random() * accentColorList.length)]
  );
  const [currentAccentColor, setCurrentAccentColor] = useState(
    formStyles.accentColor || accentColorList[Math.floor(Math.random() * accentColorList.length)]
  );

  // Handling Color Pickers
  const handleChangeTitleColor = (newColor: string) => {
    setCurrentTitleColor(newColor);
  };
  const handleChangeTitleFillColor = (newColor: string) => {
    setCurrentTitleFillColor(newColor);
  };
  const handleChangeAccentColor = (newColor: string) => {
    setCurrentAccentColor(newColor);
  };

  // Handling Switches
  const handleFillTitleChange = (checkedStatus: boolean) => {
    dispatch(setTitleFilledFormStyles(checkedStatus));
  };
  const handleTitleUnderline = (checkedStatus: boolean) => {
    dispatch(setTitleUnderlineFormStyles(checkedStatus));
  };
  const handleTitleFullWidth = (checkedStatus: boolean) => {
    dispatch(setTitleFullWidthFormStyles(checkedStatus));
  };

  // Handle Font Family Selection
  const handleFontFamily = (option: FontFamily) => {
    dispatch(setFontFamilyFormStyles(option));
  };

  useEffect(() => {
    const lastRequest = setTimeout(() => {
      dispatch(setTitleColorFormStyles(currentTitleColor));
    }, 400);
    return () => {
      clearTimeout(lastRequest);
    };
  }, [currentTitleColor]);

  useEffect(() => {
    const lastRequest = setTimeout(() => {
      dispatch(setTitleFillColorFormStyles(currentTitleFillColor));
    }, 400);
    return () => {
      clearTimeout(lastRequest);
    };
  }, [currentTitleFillColor]);

  useEffect(() => {
    const lastRequest = setTimeout(() => {
      dispatch(setAccentColorFormStyles(currentAccentColor));
    }, 400);
    return () => {
      clearTimeout(lastRequest);
    };
  }, [currentAccentColor]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <section className="eachSection">
        <h3 className="formHeader">Block Titles</h3>
        <div className="eachSectionDivs">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              padding: 0,
            }}
          >
            <CustomSwitch
              label="Fill Background"
              checked={formStyles.titleFilled}
              onChange={(e) => handleFillTitleChange(e.target.checked)}
            />
            <CustomSwitch
              label="Underline"
              checked={formStyles.titleUnderline}
              onChange={(e) => handleTitleUnderline(e.target.checked)}
            />
            <CustomSwitch
              label="Full Width"
              checked={formStyles.titleFullWidth}
              onChange={(e) => handleTitleFullWidth(e.target.checked)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: "1rem",
            }}
          >
            {/* the Title Stroke colors */}
            <ColorPickerInput label="Title Color" color={formStyles.titleColor} handleColor={handleChangeTitleColor} />
            {/* the Title Fill colors */}
            <ColorPickerInput
              label="Title Fill Color"
              color={formStyles.titleFillColor}
              handleColor={handleChangeTitleFillColor}
            />
          </div>
        </div>
      </section>

      {/* the accent color */}
      <section className="eachSection">
        <h3 className="formHeader">Other Styles</h3>
        <div className="eachSectionDivs">
          <div style={{ width: "50%", textAlign: "center", margin: "4px 0px" }}>
            <ColorPickerInput
              label="Accent Color"
              color={formStyles.accentColor}
              handleColor={handleChangeAccentColor}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--color-on-surface)",
            }}
          >
            Font Family &#9658;&nbsp;
            <Select
              variant="outlined"
              id="fontFamilySelect"
              style={{ marginTop: 8, color: "var(--color-on-surface)" }}
              size="small"
              value={formStyles.fontFamily}
              label="FontFamily"
              onChange={(e) => handleFontFamily(e.target.value)}
            >
              <MenuItem value={"sans-serif"}>Sans Serif</MenuItem>
              <MenuItem value={"monospace"}>Monospace</MenuItem>
              <MenuItem value={"cursive"}>Cursive</MenuItem>
              <MenuItem value={"times new roman"}>Times New Roman</MenuItem>
            </Select>
          </div> */}
          <div style={{ width: "50%", margin: "4px 0px" }}>
            <SelectComponent
              options={["sans-serif", "times new roman", "cursive", "monospace"]}
              optionsIcons={{ "sans-serif": "Aa", "times new roman": "Aa", cursive: "Aa", monospace: "Aa" }}
              value={formStyles.fontFamily}
              onSelect={handleFontFamily}
            />
          </div>
        </div>
      </section>
    </div>
  );
});
