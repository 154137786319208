import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Skills } from "../../../interfaces/Skills";

export const initialStateSkills2: Skills = {
  color: "#123456",
  title: "",
  chipRadius: 10,
  chipSize: 4,
  filled: true,
  flipped: false,
  data: [],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const skills2Slice = createSlice({
  name: "skills2",
  initialState: initialStateSkills2,
  reducers: {
    setBlockDataSkills2: (state, action: PayloadAction<Skills>) => {
      state.color = action.payload.color;
      state.title = action.payload.title;
      state.chipRadius = action.payload.chipRadius;
      state.chipSize = action.payload.chipSize;
      state.filled = action.payload.filled;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setColorSkills2: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    setTitleSkills2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setChipRadiusSkills2: (state, action: PayloadAction<number>) => {
      state.chipRadius = action.payload;
    },
    setChipSizeSkills2: (state, action: PayloadAction<number>) => {
      state.chipSize = action.payload;
    },
    setFilledSkills2: (state, action: PayloadAction<boolean>) => {
      state.filled = action.payload;
    },
    setFlippedSkills2: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setDataSkills2: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
    setStyleSkills2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataSkills2,
  setColorSkills2,
  setTitleSkills2,
  setChipRadiusSkills2,
  setChipSizeSkills2,
  setFilledSkills2,
  setFlippedSkills2,
  setDataSkills2,
  setStyleSkills2,
} = skills2Slice.actions;

export default skills2Slice.reducer;
