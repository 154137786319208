import React from "react";
import { FormStyles } from "../../../interfaces/FormStyles";
import { BlueprintWrapper } from "./_BlueprintStyles";
import { Name } from "../../../interfaces/Name";
import { useAppSelector } from "../../../app/hooks";

interface AboutBlueprintProps {
  name: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: Name;
  };
}

export const NameBlueprint1: React.FC<AboutBlueprintProps> = (props) => {
  const formStyles = useAppSelector(state=>state.formStyles);
  
  return (
    <BlueprintWrapper
      h={props.name.h}
      x={props.name.x}
      y={props.name.y}
      w={props.name.w}
      bgColor={props.name.data.style.bgColor}
      textColor={props.name.data.style.textColor}
      fontFamily={formStyles.fontFamily}
    >
      <div
        style={{
          display: "flex",
          justifyContent: props.name.data.align,
          alignItems: "center",
        }}
      >
        <h1 style={{ fontSize: props.name.data.fontSize, fontWeight: 600, marginBottom: 0, display: "inline-block" }}>
          {props.name.data.name}
        </h1>
        <p style={{ marginTop: props.name.data.fontSize - 22, display: "inline-block" }}>
          &nbsp;&nbsp;{props.name.data.profession}
        </p>
      </div>
    </BlueprintWrapper>
  );
};
