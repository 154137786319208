import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/elements/counter/counterSlice';
import about1Reducer from '../features/elements/about/about1Slice';
import aboutWithContact1Reducer from '../features/elements/aboutWithContact/aboutWithContact1Slice';
import aboutWithContact2Reducer from '../features/elements/aboutWithContact/aboutWithContact2Slice';
import certification1Reducer from '../features/elements/certifications/certification1Slice';
import certification2Reducer from '../features/elements/certifications/certification2Slice';
import contact1Reducer from '../features/elements/contact/contact1Slice';
import contact2Reducer from '../features/elements/contact/contact2Slice';
import contact3Reducer from '../features/elements/contact/contact3Slice';
import educations1Reducer from '../features/elements/educations/educations1Slice';
import educations2Reducer from '../features/elements/educations/educations2Slice';
import educations3Reducer from '../features/elements/educations/educations3Slice';
import name1Reducer from '../features/elements/name/name1Slice';
import others1Reducer from '../features/elements/others/others1Slice';
import formStylesReducer from '../features/formStyles/formStylesSlice';
import photo1Reducer from '../features/elements/photo/photo1Slice';
import projects1Reducer from '../features/elements/projects/projects1Slice';
import projects2Reducer from '../features/elements/projects/projects2Slice';

import ratings1Reducer from '../features/elements/ratings/ratings1Slice';
import ratings2Reducer from '../features/elements/ratings/ratings2Slice';
import ratings3Reducer from '../features/elements/ratings/ratings3Slice';
import ratings4Reducer from '../features/elements/ratings/ratings4Slice';

import skills1Reducer from '../features/elements/skills/skills1Slice';
import skills2Reducer from '../features/elements/skills/skills2Slice';
import works1Reducer from '../features/elements/works/works1Slice';
import wokrs2Reducer from '../features/elements/works/works2Slice';

import gridItemsReducer from '../features/gridItem/gridItemSlice';

import themeReducer from '../features/theme/themeSlice';

export const store = configureStore({
  reducer: {
    gridItems: gridItemsReducer,

    about1: about1Reducer,

    aboutWithContact1: aboutWithContact1Reducer,
    aboutWithContact2: aboutWithContact2Reducer,
    
    certifications1: certification1Reducer,
    certifications2: certification2Reducer,

    contact1: contact1Reducer,
    contact2: contact2Reducer,
    contact3: contact3Reducer,

    educations1: educations1Reducer,
    educations2: educations2Reducer,
    educations3: educations3Reducer,

    name1: name1Reducer,

    others1: others1Reducer,

    photo1: photo1Reducer,

    projects1: projects1Reducer,
    projects2: projects2Reducer,

    ratings1: ratings1Reducer,
    ratings2: ratings2Reducer,
    ratings3: ratings3Reducer,
    ratings4: ratings4Reducer,

    skills1: skills1Reducer,
    skills2: skills2Reducer,
    
    works1: works1Reducer,
    works2: wokrs2Reducer,
    
    formStyles: formStylesReducer,

    theme: themeReducer,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch