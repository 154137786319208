import React from "react";

export const WorksIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 160">
        {/* first row */}
        {/* <rect x={20} y={30} width={70} height={10} rx={2.5} fill="#123456" /> */}
        {/* First Job */}
        <rect x={30} y={30} width={80} height={10} rx={2.5} fill="#6366f1" />
        <circle cx={40} cy={49} r={3} fill="#545454" />
        <rect x={48} y={47} width={160} height={5} rx={2.5} fill="#878787" />
        <circle cx={40} cy={59} r={3} fill="#545454" />
        <rect x={48} y={57} width={160} height={5} rx={2.5} fill="#878787" />
        <circle cx={40} cy={69} r={3} fill="#545454" />
        <rect x={48} y={67} width={100} height={5} rx={2.5} fill="#878787" />
        {/* 2nd Job */}
        <rect x={30} y={80} width={80} height={10} rx={2.5} fill="#6366f1" />
        <circle cx={40} cy={99} r={3} fill="#545454" />
        <rect x={48} y={97} width={160} height={5} rx={2.5} fill="#878787" />
        <circle cx={40} cy={109} r={3} fill="#545454" />
        <rect x={48} y={107} width={160} height={5} rx={2.5} fill="#878787" />
        <circle cx={40} cy={119} r={3} fill="#545454" />
        <rect x={48} y={117} width={100} height={5} rx={2.5} fill="#878787" />
      </svg>
    </div>
  );
};

export const WorksIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 160">
        {/* first row */}
        {/* <rect x={20} y={30} width={70} height={10} rx={2.5} fill="#123456" /> */}
        {/* First Job */}
        <rect x={30} y={30} width={80} height={10} rx={2.5} fill="#6366f1" />
        <rect x={30} y={45} width={50} height={5} rx={2.5} fill="#ff6565" />
        <rect x={30} y={57} width={160} height={5} rx={2.5} fill="#878787" />
        <rect x={30} y={67} width={100} height={5} rx={2.5} fill="#878787" />
        {/* 2nd Job */}
        <rect x={30} y={80} width={80} height={10} rx={2.5} fill="#6366f1" />
        <rect x={30} y={95} width={50} height={5} rx={2.5} fill="#ff6565" />
        <rect x={30} y={107} width={160} height={5} rx={2.5} fill="#878787" />
        <rect x={30} y={117} width={100} height={5} rx={2.5} fill="#878787" />
      </svg>
    </div>
  );
};
