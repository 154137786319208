import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Certificate, Certifications } from "../../../interfaces/Certification";
import { ContactBlock } from "../../../interfaces/Contact";

export const initialStateContactBlock1: ContactBlock = {
  title: "",
  flipped: true,
  data: { address: [""], emails: [""], phno: "" },
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const Contact1Slice = createSlice({
  name: "contact1",
  initialState: initialStateContactBlock1,
  reducers: {
    setBlockDataContact1: (state, action: PayloadAction<ContactBlock>) => {
      state.title = action.payload.title;
      state.flipped = action.payload.flipped;
      state.data = {...action.payload.data};
      state.style = action.payload.style;
    },
    setTitleContact1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setFlippedContact1: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setAddressContact1: (state, action: PayloadAction<string[]>) => {
      state.data.address = action.payload;
    },
    setEmailsContact1: (state, action: PayloadAction<string[]>) => {
      state.data.emails = action.payload;
    },
    setPhnoContact1: (state, action: PayloadAction<string>) => {
      state.data.phno = action.payload;
    },
    setStyleContact1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataContact1,
  setTitleContact1,
  setFlippedContact1,
  setAddressContact1,
  setEmailsContact1,
  setPhnoContact1,
  setStyleContact1,
} = Contact1Slice.actions;

export default Contact1Slice.reducer;
