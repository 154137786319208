import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BarRatings } from "../../../interfaces/Ratings";

export const initialStateRatings3: BarRatings = {
  title: "",
  ratingType: "bar",
  flipped: false,
  barRadius: 12,
  barWidth: 10,
  data: [
    // { id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 }
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const ratings3Slice = createSlice({
  name: "ratings3",
  initialState: initialStateRatings3,
  reducers: {
    setBlockDataRatings3: (state, action: PayloadAction<BarRatings>) => {
      state.title = action.payload.title;
      state.ratingType = action.payload.ratingType;
      state.barWidth = action.payload.barWidth;
      state.barRadius = action.payload.barRadius;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setBlockTitleRatings3: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setRatingTypeRatings3: (state, action: PayloadAction<"star"|"bar">) => {
      state.ratingType = action.payload;
    },
    setBarWidthRatings3: (state, action: PayloadAction<number>) => {
      state.barWidth = action.payload;
    },
    setBarRadiusRatings3: (state, action: PayloadAction<number>) => {
      state.barRadius = action.payload;
    },
    setFlippedRatings3: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setDataRatingSubjectRatings3: (state, action: PayloadAction<{ratingSubject: string, ratingId:string}>) => {
      const { ratingSubject, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].ratingSubject = ratingSubject;
    },
    setDataRateInPercentageRatings3: (state, action: PayloadAction<{rateInPercentage: number, ratingId:string}>) => {
      const { rateInPercentage, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].rateInPercentage = rateInPercentage;
    },
    addRatingRatings3: (state) => {
      state.data.push({ id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 });
    },
    removeRatingRatings3: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((rating) => rating.id !== action.payload);
    },
    setStyleRatings3: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataRatings3,
  setBlockTitleRatings3,
  setRatingTypeRatings3,
  setBarWidthRatings3,
  setBarRadiusRatings3,
  setFlippedRatings3,
  setDataRatingSubjectRatings3,
  setDataRateInPercentageRatings3,
  addRatingRatings3,
  removeRatingRatings3,
  setStyleRatings3,
} = ratings3Slice.actions;

export default ratings3Slice.reducer;
