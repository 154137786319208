import "./_FormsStyles.css";

import React, { FC } from "react";
import { Work } from "../../../../interfaces/Works";

import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import {
  addWorkWorks1,
  removeWorkByIdWorks1,
  setBulletWorks1,
  setIndentWorks1,
  setJobTitleByIdWorks1,
  setLocationByIdWorks1,
  setOrganizationNameByIdWorks1,
  setStyleWorks1,
  setTitleWorks1,
  setWorkDetailsByIdWorks1,
  setWorkDurationByIdWorks1,
} from "../../../../features/elements/works/works1Slice";
import {
  addWorkWorks2,
  removeWorkByIdWorks2,
  setTitleWorks2,
  setOrganizationNameByIdWorks2,
  setLocationByIdWorks2,
  setJobTitleByIdWorks2,
  setWorkDetailsByIdWorks2,
  setWorkDurationByIdWorks2,
  setStyleWorks2,
  setBulletWorks2,
  setIndentWorks2,
} from "../../../../features/elements/works/works2Slice";

import { CollapseableForm, RemoveFieldsButton } from "./_CommonComponents";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../../../Components/Inputs/ButtonComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";
import { CustomSwitch } from "../../../../Components/Inputs/SwitchInput";

interface WorksFormProps {}

export const WorksForm1: FC<WorksFormProps> = React.memo(() => {
  const works1 = useAppSelector((state) => state.works1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addWorkWorks1());
  const handleRemoveFields = (id: string) => dispatch(removeWorkByIdWorks1(id));

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => dispatch(setTitleWorks1(title));
  const handleBulletParagraphsInput = (bullet: boolean) => dispatch(setBulletWorks1(bullet));
  const handleIndentParagraphsInput = (indent: boolean) => dispatch(setIndentWorks1(indent));
  const handleWorkOrganizationNameInput = (organizationName: string, workId: string) =>
    dispatch(setOrganizationNameByIdWorks1({ workOrganizationName: organizationName, workId: workId }));
  const handleWorkLocationInput = (workLocation: string, workId: string) =>
    dispatch(setLocationByIdWorks1({ workLocation: workLocation, workId: workId }));
  const handleJobTitleInput = (jobTitle: string, workId: string) =>
    dispatch(setJobTitleByIdWorks1({ jobTitle: jobTitle, workId: workId }));
  const handleWorkDetailsInput = (workDetails: string, workId: string) =>
    dispatch(setWorkDetailsByIdWorks1({ workDetails: workDetails.split("<br>"), workId: workId }));
  const handleWorkDurationInput = (workDuration: string, workId: string) =>
    dispatch(setWorkDurationByIdWorks1({ workDuration: workDuration, workId: workId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleWorks1({ ...works1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleWorks1({ ...works1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Works #1</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={works1.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch label="Bullet Paragraphs" checked={works1.bullet} onChange={(e) => handleBulletParagraphsInput(e.target.checked)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch label="Indent Paragraphs" checked={works1.indent} onChange={(e) => handleIndentParagraphsInput(e.target.checked)} />
        </GridItem>
      </GridContainer>
      {works1.data.map((singleWork: Work, index: number) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Organization Name eg. Google Inc."
                    value={singleWork.workOrganizationName}
                    onChange={(e) => handleWorkOrganizationNameInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Location e.g. New York"
                    value={singleWork.workLocation}
                    onChange={(e) => handleWorkLocationInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Job Title"
                    value={singleWork.jobTitle}
                    onChange={(e) => handleJobTitleInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Worked Between e.g. Mar 2020 - Present"
                    value={singleWork.workDuration}
                    onChange={(e) => handleWorkDurationInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInputMultiline
                    label="Work Details eg. Worked on Stuff (Separate each point by adding <br> at the end)"
                    value={singleWork.workDetails.join("<br>")}
                    onChange={(e) => handleWorkDetailsInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleWork.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={works1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={works1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
export const WorksForm2: FC<WorksFormProps> = React.memo(() => {
  const works2 = useAppSelector((state) => state.works2);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addWorkWorks2());
  const handleRemoveFields = (id: string) => dispatch(removeWorkByIdWorks2(id));

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => dispatch(setTitleWorks2(title));
  const handleBulletParagraphsInput = (bullet: boolean) => dispatch(setBulletWorks2(bullet));
  const handleIndentParagraphsInput = (indent: boolean) => dispatch(setIndentWorks2(indent));
  const handleWorkOrganizationNameInput = (organizationName: string, workId: string) =>
    dispatch(setOrganizationNameByIdWorks2({ workOrganizationName: organizationName, workId: workId }));
  const handleWorkLocationInput = (workLocation: string, workId: string) =>
    dispatch(setLocationByIdWorks2({ workLocation: workLocation, workId: workId }));
  const handleJobTitleInput = (jobTitle: string, workId: string) =>
    dispatch(setJobTitleByIdWorks2({ jobTitle: jobTitle, workId: workId }));
  const handleWorkDetailsInput = (workDetails: string, workId: string) =>
    dispatch(setWorkDetailsByIdWorks2({ workDetails: workDetails.split("<br>"), workId: workId }));
  const handleWorkDurationInput = (workDuration: string, workId: string) =>
    dispatch(setWorkDurationByIdWorks2({ workDuration: workDuration, workId: workId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleWorks2({ ...works2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleWorks2({ ...works2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Works #2</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={works2.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch label="Bullet Paragraphs" checked={works2.bullet} onChange={(e) => handleBulletParagraphsInput(e.target.checked)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch label="Indent Paragraphs" checked={works2.indent} onChange={(e) => handleIndentParagraphsInput(e.target.checked)} />
        </GridItem>
      </GridContainer>
      {works2.data.map((singleWork: Work, index: number) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Organization Name eg. Google Inc."
                    value={singleWork.workOrganizationName}
                    onChange={(e) => handleWorkOrganizationNameInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Location e.g. New York"
                    value={singleWork.workLocation}
                    onChange={(e) => handleWorkLocationInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Job Title"
                    value={singleWork.jobTitle}
                    onChange={(e) => handleJobTitleInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Worked Between e.g. Mar 2020 - Present"
                    value={singleWork.workDuration}
                    onChange={(e) => handleWorkDurationInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInputMultiline
                    label="Work Details eg. Worked on Stuff (Separate each point by adding <br> at the end)"
                    value={singleWork.workDetails.join("<br>")}
                    onChange={(e) => handleWorkDetailsInput(e.target.value, singleWork.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleWork.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={works2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={works2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
