import "./_BlocksStyles.css";

import React, {  useEffect, useState } from "react";

import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { Skills } from "../../../../interfaces/Skills";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";

const dummySkills1: Skills = {
  color: "#ff6565",
  title: "Skills",
  chipRadius: 10,
  chipSize: 4,
  filled: true,
  flipped: false,
  data: [
    "HTML/CSS/JSS",
    "TypeScript",
    "ReactJS",
    "NextJS",
    "Flutter",
    "NodeJS",
    "ExpressJS",
    "Python",
    "MySql",
    "MongoDB",
    "Sqlite",
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const dummySkills2: Skills = {
  color: "#ff5656",
  title: "Skills Title",
  chipRadius: 16,
  chipSize: 4,
  filled: false,
  flipped: false,
  data: ["HTML/CSS/JSS", "TypeScript", "ReactJS", "Flutter", "NodeJS", "ExpressJS", "MySql", "MongoDB", "Sqlite"],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const dummySkills3: Skills = {
  color: "#ff5656",
  title: "Skills Title",
  chipRadius: 10,
  chipSize: 4,
  filled: true,
  flipped: false,
  data: ["HTML/CSS/JSS", "TypeScript", "ReactJS", "Flutter", "NodeJS", "ExpressJS", "MySql", "MongoDB", "Sqlite"],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const isSkillsEmpty = (skills: Skills): boolean => {
  if (skills.data.join("") === "" && skills.title === "") return true;
  return false;
};

interface SkillsBlockProps {
  item: GridItem;
}

export const SkillsBlock1: React.FC<SkillsBlockProps> = (props) => {
  const skills1 = useAppSelector((state) => state.skills1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Skills
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isSkillsEmpty(skills1));
  }, [skills1]);

  const toBeShownSkills = isEmpty ? dummySkills1 : skills1;

  // Change back some property so use can preview styles without typing data
  // toBeShownSkills.color = skills1.color;
  // toBeShownSkills.filled = skills1.filled;
  // toBeShownSkills.chipSize = skills1.chipSize;
  // toBeShownSkills.chipRadius = skills1.chipRadius;

  return (
    <div
      style={{
        backgroundColor: toBeShownSkills.style.bgColor,
        color: toBeShownSkills.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <div style={{ display: "flex", flexDirection: skills1.flipped ? "row-reverse" : "row" }}>
          <BlockTitle
            formStyles={formStyles}
            title={toBeShownSkills.title}
            isOpaque={isEmpty}
            flipped={skills1.flipped}
          />
          <RemoveBlockButton
            item={props.item}
            blockTitle={"Skills #1"}
            flipped={skills1.flipped}
          />
        </div>
        <div
          style={{
            marginTop: 8,
            // paddingLeft: 8,
            fontWeight: 500,
            // for aligning to left or right
            display: "flex",
            flexFlow: "wrap",
            flexDirection: skills1.flipped ? "row-reverse" : "row",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownSkills.data.map((eachSkill: string, index: number) => {
            return (
              <div
                key={eachSkill + index}
                style={{
                  display: "inline-block",
                  padding: `${toBeShownSkills.chipSize}px ${toBeShownSkills.chipSize * 2}px`,
                  margin: "4px 0px 4px 4px",
                  border: toBeShownSkills.filled ? `1px solid transparent` : `1px solid ${toBeShownSkills.color}`,
                  borderRadius: toBeShownSkills.chipRadius,
                  color: toBeShownSkills.filled ? "#fff" : toBeShownSkills.color,
                  backgroundColor: toBeShownSkills.filled ? toBeShownSkills.color : "transparent",
                  fontSize: 15,
                }}
              >
                {eachSkill}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SkillsBlock2: React.FC<SkillsBlockProps> = (props) => {
  const skills2 = useAppSelector((state) => state.skills2);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Skills
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isSkillsEmpty(skills2));
  }, [skills2]);

  const toBeShownSkills = isEmpty ? dummySkills2 : skills2;

  return (
    <div
      style={{
        backgroundColor: toBeShownSkills.style.bgColor,
        color: toBeShownSkills.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <div style={{ display: "flex", flexDirection: skills2.flipped ? "row-reverse" : "row" }}>
          <BlockTitle
            formStyles={formStyles}
            title={toBeShownSkills.title}
            isOpaque={isEmpty}
            flipped={skills2.flipped}
          />
          <RemoveBlockButton
            item={props.item}
            blockTitle={"Skills #2"}
            flipped={skills2.flipped}
          />
        </div>
        <div
          style={{
            marginTop: 8,
            // paddingLeft: 8,
            fontWeight: 500,
            // for aligning to left or right
            display: "flex",
            flexFlow: "wrap",
            flexDirection: skills2.flipped ? "row-reverse" : "row",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownSkills.data.map((eachSkill: string, index: number) => {
            return (
              <div
                key={eachSkill + index}
                style={{
                  display: "inline-block",
                  padding: `${toBeShownSkills.chipSize}px ${toBeShownSkills.chipSize * 2}px`,
                  margin: "4px 0px 4px 4px",
                  border: toBeShownSkills.filled ? `1px solid transparent` : `1px solid ${toBeShownSkills.color}`,
                  borderRadius: toBeShownSkills.chipRadius,
                  color: toBeShownSkills.filled ? "#fff" : toBeShownSkills.color,
                  backgroundColor: toBeShownSkills.filled ? toBeShownSkills.color : "transparent",
                  fontSize: 15,
                }}
              >
                {eachSkill}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
