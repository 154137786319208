import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Photo } from "../../../interfaces/Photo";

export const initialStatePhoto1: Photo = {
  name: "",
  // photo: new Blob(),
  hasPhoto: 0,
  height: 200,
  width: 160,
  borderRadius: 0,
  borderColor: "#ffffff",
  borderStyle: "solid",
  borderWidth: 3,
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const photo1Slice = createSlice({
  name: "photo1",
  initialState: initialStatePhoto1,
  reducers: {
    setBlockDataPhoto1: (state, action: PayloadAction<Photo>) => {
      state.name = action.payload.name;
      // state.photo = action.payload.photo;
      state.hasPhoto = action.payload.hasPhoto;
      state.height = action.payload.height;
      state.width = action.payload.width;
      state.borderRadius = action.payload.borderRadius;
      state.borderColor = action.payload.borderColor;
      state.borderStyle = action.payload.borderStyle;
      state.borderWidth = action.payload.borderWidth;
      state.style = action.payload.style;
    },
    setNamePhoto1: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    // setPhotoPhoto1: (state, action: PayloadAction<Blob>) => {
    //   state.photo = action.payload;
    // },
    setHasPhotoPhoto1: (state, action: PayloadAction<number>) => {
      state.hasPhoto = action.payload;
    },
    // setHeightPhoto1: (state, action: PayloadAction<number>) => {
    //   state.height = action.payload;
    // },
    // setWidthPhoto1: (state, action: PayloadAction<number>) => {
    //   state.width = action.payload;
    // },
    setPhotoSizePhoto1: (state, action: PayloadAction<number>) => {
      state.height = action.payload;
      state.width = action.payload;
    },
    setBorderRadiusPhoto1: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    },
    setBorderColorPhoto1: (state, action: PayloadAction<string>) => {
      state.borderColor = action.payload;
    },
    setBorderStylePhoto1: (state, action: PayloadAction<string>) => {
      state.borderStyle = action.payload;
    },
    setBorderWidthPhoto1: (state, action: PayloadAction<number>) => {
      state.borderWidth = action.payload;
    },
    setStylePhoto1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataPhoto1,
  setNamePhoto1,
  setHasPhotoPhoto1,
  // setHeightPhoto1,
  // setWidthPhoto1,
  setPhotoSizePhoto1,
  setBorderRadiusPhoto1,
  setBorderColorPhoto1,
  setBorderStylePhoto1,
  setBorderWidthPhoto1,
  setStylePhoto1,
} = photo1Slice.actions;

export default photo1Slice.reducer;
