import "./ColorPickerInput.css";

import React, { FC } from "react";

interface ColorPickerProps {
  label: string;
  color: string;
  handleColor: (newColor: string) => void;
  height?: number;
}
export const ColorPickerInput: React.FC<ColorPickerProps> = (props) => {
  return (
    <div className="colorPickerDiv">
      {props.label} &#9658;&nbsp;
      <label
        style={{
          backgroundColor: props.color,
          width: props.height ? props.height : 36,
          height: props.height ? props.height : 36,
        }}
        className="colorPickerCircleWrapper"
      >
        <input
          style={{ visibility: "hidden" }}
          type="color"
          name="colorPicker"
          value={props.color}
          onChange={(e) => props.handleColor(e.target.value)}
        />
      </label>
    </div>
  );
};
