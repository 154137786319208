import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { StarRatings } from "../../../interfaces/Ratings";

export const initialStateRatings1: StarRatings = {
  title: "",
  ratingType: "star",
  icon: "star",
  flipped: false,
  data: [
    // { id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 }
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const ratings1Slice = createSlice({
  name: "ratings1",
  initialState: initialStateRatings1,
  reducers: {
    setBlockDataRatings1: (state, action: PayloadAction<StarRatings>) => {
      state.title = action.payload.title;
      state.ratingType = action.payload.ratingType;
      state.icon = action.payload.icon;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setBlockTitleRatings1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setRatingTypeRatings1: (state, action: PayloadAction<"star"|"bar">) => {
      state.ratingType = action.payload;
    },
    setRatingIconRatings1: (state, action: PayloadAction<"star"|"circle"|"square">) => {
      state.icon = action.payload;
    },
    setFlippedRatings1: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setDataRatingSubjectRatings1: (state, action: PayloadAction<{ratingSubject: string, ratingId:string}>) => {
      const { ratingSubject, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].ratingSubject = ratingSubject;
    },
    setDataRateInPercentageRatings1: (state, action: PayloadAction<{rateInPercentage: number, ratingId:string}>) => {
      const { rateInPercentage, ratingId } = action.payload;
      const pos = state.data.findIndex((rating) => rating.id === ratingId);
      state.data[pos].rateInPercentage = rateInPercentage;
    },
    addRatingRatings1: (state) => {
      state.data.push({ id: `rating${Date.now()}`, ratingSubject: "", rateInPercentage: 80 });
    },
    removeRatingRatings1: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((rating) => rating.id !== action.payload);
    },
    setStyleRatings1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataRatings1,
  setBlockTitleRatings1,
  setRatingTypeRatings1,
  setRatingIconRatings1,
  setFlippedRatings1,
  setDataRatingSubjectRatings1,
  setDataRateInPercentageRatings1,
  addRatingRatings1,
  removeRatingRatings1,
  setStyleRatings1,
} = ratings1Slice.actions;

export default ratings1Slice.reducer;
