import "./_FormsStyles.css";

import React, { FC } from "react";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setBulletOthers1,
  setDataOthers1,
  setStyleOthers1,
  setTitleOthers1,
} from "../../../../features/elements/others/others1Slice";
import { CollapseableForm } from "./_CommonComponents";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { SelectComponent } from "../../../../Components/Inputs/SelectComponent";
import { BulletPoint } from "../../../../interfaces/Others";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface OthersFormProps {}

export const OthersForm: FC<OthersFormProps> = React.memo((props) => {
  const others1 = useAppSelector((state) => state.others1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // ======================================================
  // Handle Inputs
  const handleBlockTitleInput = (title: string) => {
    dispatch(setTitleOthers1(title));
  };
  const handleBulletPoint = (option: BulletPoint) => {
    dispatch(setBulletOthers1(option));
  };
  const handleDataInput = (data: string[]) => {
    dispatch(setDataOthers1(data));
  };
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleOthers1({ ...others1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleOthers1({ ...others1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Others #1</h3>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <TextInput
            label="Title"
            value={others1.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBlockTitleInput(e.target.value)}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <div style={{ paddingTop: "4px" }}>
            <SelectComponent
              options={["dot", "circle", "square", "triangle", "none"]}
              optionsIcons={{ dot: "●", circle: "○", square: "■", triangle: "▶", none: "" }}
              value={others1.bullet}
              onSelect={handleBulletPoint}
            />
          </div>
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Other Skills & Activities (Separate each point by adding <br> at the end)"
            value={others1.data.join("<br>")}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleDataInput(e.target.value.split("<br>"))}
          />
        </GridItem>
      </GridContainer>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={others1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={others1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
