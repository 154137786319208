import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AboutWithContact } from "../../../interfaces/AboutWithContact";

const initialState: AboutWithContact = {
  name: "",
  profession: "",
  address: [""],
  cityZip: "",
  phno: "",
  emails: [""],
  about: "",
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const aboutWithContact2Slice = createSlice({
  name: "aboutwithcontact2",
  initialState,
  reducers: {
    setNameAboutWithContact2: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setProfessionAboutWithContact2: (state, action: PayloadAction<string>) => {
      state.profession = action.payload;
    },
    setAddressAboutWithContact2: (state, action: PayloadAction<string[]>) => {
      state.address = action.payload;
    },
    setCityZipAboutWithContact2: (state, action: PayloadAction<string>) => {
      state.cityZip = action.payload;
    },
    setPhNoAboutWithContact2: (state, action: PayloadAction<string>) => {
      state.phno = action.payload;
    },
    setEmailsAboutWithContact2: (state, action: PayloadAction<string[]>) => {
      state.emails = action.payload;
    },
    setAboutAboutWithContact2: (state, action: PayloadAction<string>) => {
      state.about = action.payload;
    },
    setStyleAboutWithContact2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNameAboutWithContact2,
  setAddressAboutWithContact2,
  setCityZipAboutWithContact2,
  setPhNoAboutWithContact2,
  setEmailsAboutWithContact2,
  setAboutAboutWithContact2,
  setProfessionAboutWithContact2,
  setStyleAboutWithContact2,
} = aboutWithContact2Slice.actions;

export default aboutWithContact2Slice.reducer;
