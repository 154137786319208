import React, { FC } from "react";

import "./SectionHeader.css";

interface SectionHeaderProps {
  supportHeader: string;
  mainHeader: string;
}

const SectionHeader: FC<SectionHeaderProps> = (props) => {
  return (
    <div className="sectionHeaderContainer">
      <div className="supportHeader">
        {props.supportHeader}
      </div>
      <div className="mainHeader">
        {props.mainHeader}
      </div>
    </div>
  );
};

export default SectionHeader;
