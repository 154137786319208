import { useState } from "react";
import "./TextInput.css";

interface TextInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: "email" | "password" | "text";
}

export const TextInput: React.FC<TextInputProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(props.value.length > 0);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: any) => {
    setIsFocused(false);
    setHasValue(!!e.target.value);
  };

  return (
    // <div className="textInputWrapper">
    <div className={`textInputWrapper ${isFocused || hasValue ? "focused" : ""}`}>
      <label className="textInputLabel">{props.label}{props.required?" *":""}</label>
      <input
        type={props.type ? props.type : "text"}
        className="textInput"
        required={props.required}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
};

// MULTILINE TEXT INPUT
// =================================================================================================
// =================================================================================================
// =================================================================================================
interface TextInputMultilineProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export const TextInputMultiline: React.FC<TextInputMultilineProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(props.value.length > 0);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: any) => {
    setIsFocused(false);
    setHasValue(!!e.target.value);
  };

  return (
    // <div className="textInputWrapper">
    <div className={`textInputWrapper ${isFocused || hasValue ? "focused" : ""}`}>
      <label className="textInputLabel">{props.label}</label>
      {/* <input type="text" className="textInputMultiline" onFocus={handleFocus} onBlur={handleBlur} value={props.value} onChange={props.onChange} /> */}
      <textarea
        className="textInput textArea"
        rows={4}
        onFocus={handleFocus}
        onBlur={handleBlur}
        required
        value={props.value}
        onChange={props.onChange}
      ></textarea>
    </div>
  );
};
