import React from "react";
import { StarFilledIcon, StarOutlinedIcon } from "../../../../static/Icons/Icons";

export const RatingsIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={50} y={46} width={65} height={8} rx={4} fill="#656565" />
        {/* <rect x={130} y={50} width={65} height={8} rx={4} fill="#6b5be6" /> */}
        <circle cx={135} cy={50} r={5} fill="#f50057" />
        <circle cx={150} cy={50} r={5} fill="#f50057" />
        <circle cx={165} cy={50} r={5} fill="#f50057" />
        <circle cx={180} cy={50} r={5} fill="#f50057" />
        <circle cx={195} cy={50} r={5} stroke="#f50057" strokeWidth="1" fill="none" />
        <rect x={50} y={71} width={65} height={8} rx={4} fill="#656565" />
        <circle cx={135} cy={75} r={5} fill="#f50057" />
        <circle cx={150} cy={75} r={5} fill="#f50057" />
        <circle cx={165} cy={75} r={5} fill="#f50057" />
        <circle cx={180} cy={75} r={5} stroke="#f50057" strokeWidth="1" fill="none" />
        <circle cx={195} cy={75} r={5} stroke="#f50057" strokeWidth="1" fill="none" />
        <rect x={50} y={95} width={65} height={8} rx={4} fill="#656565" />
        <circle cx={135} cy={100} r={5} fill="#f50057" />
        <circle cx={150} cy={100} r={5} fill="#f50057" />
        <circle cx={165} cy={100} r={5} fill="#f50057" />
        <circle cx={180} cy={100} r={5} fill="#f50057" />
        <circle cx={195} cy={100} r={5} fill="#f50057" />
      </svg>
    </div>
  );
};

export const RatingsIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={80} y={40} width={65} height={8} rx={4} fill="#656565" />
        <circle cx={95} cy={64} r={5} fill="#FBC02D" />
        <circle cx={110} cy={64} r={5} fill="#FBC02D" />
        <circle cx={125} cy={64} r={5} fill="#FBC02D" />
        <circle cx={140} cy={64} r={5} fill="#FBC02D" />
        <circle cx={155} cy={64} r={5} fill="#FBC02D" />

        {/* <rect x={90} y={60} width={75} height={8} rx={4} fill="#FBC02D" /> */}
        <rect x={80} y={80} width={65} height={8} rx={4} fill="#656565" />
        <circle cx={95} cy={104} r={5} fill="#FBC02D" />
        <circle cx={110} cy={104} r={5} fill="#FBC02D" />
        <circle cx={125} cy={104} r={5} fill="#FBC02D" />
        <circle cx={140} cy={104} r={5} fill="#FBC02D" />
        <circle cx={155} cy={104} r={5} stroke="#FBC02D" strokeWidth="1" fill="none" />
      </svg>
    </div>
  );
};

export const RatingsIcon3: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={50} y={45} width={65} height={8} rx={4} fill="#343434" />
        <rect x={135} y={45} width={65} height={7} rx={4} fill="#f50057" />
        
        <rect x={50} y={70} width={65} height={8} rx={4} fill="#343434" />
        <rect x={135} y={70} width={45} height={7} rx={4} fill="#f50057" />
        <rect x={135} y={70} width={65} height={7} rx={4} stroke="#f50057" fill="none" />

        <rect x={50} y={95} width={65} height={8} rx={4} fill="#343434" />
        <rect x={135} y={95} width={55} height={7} rx={4} fill="#f50057" />
        <rect x={135} y={95} width={65} height={7} rx={4} stroke="#f50057" fill="none" />
        </svg>
    </div>
  );
};

export const RatingsIcon4: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <rect x={80} y={45} width={75} height={8} rx={4} fill="#343434" />
        <rect x={100} y={60} width={75} height={7} rx={4} fill="#FACC15" />
\
        <rect x={80} y={80} width={75} height={8} rx={4} fill="#343434" />
        <rect x={100} y={95} width={60} height={7} rx={4} fill="#FACC15" />
        <rect x={100} y={95} width={75} height={7} rx={4} stroke="#FACC15" fill="none" />
        
      </svg>
    </div>
  );
};