import React, { FC } from "react";
import { CollapseableForm, RemoveFieldsButton } from "./_CommonComponents";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import {
  addEducationEducations1,
  removeEducationEducations1,
  setCourseDurationEducations1,
  setCourseNameEducations1,
  setCourseResultsEducations1,
  setOrganizationNameEducations1,
  setStyleEducations1,
  setTitleEducations1,
} from "../../../../features/elements/educations/educations1Slice";
import {
  addEducationEducations3,
  removeEducationEducations3,
  setCourseDurationEducations3,
  setCourseNameEducations3,
  setCourseResultsEducations3,
  setOrganizationNameEducations3,
  setStyleEducations3,
  setTitleEducations3,
} from "../../../../features/elements/educations/educations3Slice";
import {
  addEducationEducations2,
  removeEducationEducations2,
  setCourseDurationEducations2,
  setCourseNameEducations2,
  setCourseResultsEducations2,
  setOrganizationNameEducations2,
  setStyleEducations2,
  setTitleEducations2,
} from "../../../../features/elements/educations/educations2Slice";
import { TextInput } from "../../../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../../../Components/Inputs/ButtonComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface EducationFormProps {}

export const Educations1Form: FC<EducationFormProps> = React.memo((props) => {
  const educations1 = useAppSelector((state) => state.educations1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => {
    dispatch(addEducationEducations1());
  };
  const handleRemoveFields = (courseId: string) => {
    dispatch(removeEducationEducations1(courseId));
  };

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => {
    dispatch(setTitleEducations1(title));
  };
  const handleOrganizationNameInput = (organizationName: string, courseId: string): void => {
    dispatch(setOrganizationNameEducations1({ organizationName, courseId }));
  };
  const handleCourseNameInput = (courseName: string, courseId: string): void => {
    dispatch(setCourseNameEducations1({ courseName, courseId }));
  };
  const handleCourseDurationInput = (courseDuration: string, courseId: string): void => {
    dispatch(setCourseDurationEducations1({ courseDuration, courseId }));
  };
  const handleCourseResultInput = (courseResults: string, courseId: string): void => {
    dispatch(setCourseResultsEducations1({ courseResults, courseId }));
  };
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleEducations1({ ...educations1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleEducations1({ ...educations1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Educations #1</h3>
      <div style={{ margin: "8px" }}>
        <TextInput label="Title" value={educations1.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
      </div>
      {educations1.data.map((singleCourse, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{width: "100%"}}>
              <GridContainer>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Organization Name eg. Massachusetts Institute of Technology"
                    value={singleCourse.organizationName}
                    onChange={(e) => handleOrganizationNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Duration or Passing year, e.g. 2019-2022"
                    value={singleCourse.courseDuration}
                    onChange={(e) => handleCourseDurationInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Course Name eg. Bachelors in Computer Science"
                    value={singleCourse.courseName}
                    onChange={(e) => handleCourseNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Result"
                    value={singleCourse.courseResults}
                    onChange={(e) => handleCourseResultInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleCourse.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={educations1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={educations1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const Educations2Form: FC<EducationFormProps> = React.memo((props) => {
  const educations2 = useAppSelector((state) => state.educations2);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => {
    dispatch(addEducationEducations2());
  };
  const handleRemoveFields = (courseId: string) => {
    dispatch(removeEducationEducations2(courseId));
  };

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => {
    dispatch(setTitleEducations2(title));
  };
  const handleOrganizationNameInput = (organizationName: string, courseId: string): void => {
    dispatch(setOrganizationNameEducations2({ organizationName, courseId }));
  };
  const handleCourseNameInput = (courseName: string, courseId: string): void => {
    dispatch(setCourseNameEducations2({ courseName, courseId }));
  };
  const handleCourseDurationInput = (courseDuration: string, courseId: string): void => {
    dispatch(setCourseDurationEducations2({ courseDuration, courseId }));
  };
  const handleCourseResultInput = (courseResults: string, courseId: string): void => {
    dispatch(setCourseResultsEducations2({ courseResults, courseId }));
  };
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleEducations2({ ...educations2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleEducations2({ ...educations2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Educations #2</h3>
      <div style={{ margin: "8px" }}>
        <TextInput label="Title" value={educations2.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
      </div>
      {educations2.data.map((singleCourse, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{width: "100%"}}>
              <GridContainer>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Organization Name eg. Massachusetts Institute of Technology"
                    value={singleCourse.organizationName}
                    onChange={(e) => handleOrganizationNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Duration or Passing year, e.g. 2019-2022"
                    value={singleCourse.courseDuration}
                    onChange={(e) => handleCourseDurationInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Course Name eg. Bachelors in Computer Science"
                    value={singleCourse.courseName}
                    onChange={(e) => handleCourseNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Result"
                    value={singleCourse.courseResults}
                    onChange={(e) => handleCourseResultInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleCourse.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={educations2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={educations2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const Educations3Form: FC<EducationFormProps> = React.memo((props) => {
  const educations3 = useAppSelector((state) => state.educations3);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => {
    dispatch(addEducationEducations3());
  };
  const handleRemoveFields = (courseId: string) => {
    dispatch(removeEducationEducations3(courseId));
  };

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => {
    dispatch(setTitleEducations3(title));
  };
  const handleOrganizationNameInput = (organizationName: string, courseId: string): void => {
    dispatch(setOrganizationNameEducations3({ organizationName, courseId }));
  };
  const handleCourseNameInput = (courseName: string, courseId: string): void => {
    dispatch(setCourseNameEducations3({ courseName, courseId }));
  };
  const handleCourseDurationInput = (courseDuration: string, courseId: string): void => {
    dispatch(setCourseDurationEducations3({ courseDuration, courseId }));
  };
  const handleCourseResultInput = (courseResults: string, courseId: string): void => {
    dispatch(setCourseResultsEducations3({ courseResults, courseId }));
  };
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleEducations3({ ...educations3.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleEducations3({ ...educations3.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Educations #3</h3>
      <div style={{ margin: "8px" }}>
        <TextInput label="Title" value={educations3.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
      </div>
      {educations3.data.map((singleCourse, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{width: "100%"}}>
              <GridContainer>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Organization Name eg. Massachusetts Institute of Technology"
                    value={singleCourse.organizationName}
                    onChange={(e) => handleOrganizationNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Duration or Passing year, e.g. 2019-2022"
                    value={singleCourse.courseDuration}
                    onChange={(e) => handleCourseDurationInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={7}>
                  <TextInput
                    label="Course Name eg. Bachelors in Computer Science"
                    value={singleCourse.courseName}
                    onChange={(e) => handleCourseNameInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={5}>
                  <TextInput
                    label="Result"
                    value={singleCourse.courseResults}
                    onChange={(e) => handleCourseResultInput(e.target.value, singleCourse.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleCourse.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={educations3.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={educations3.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
