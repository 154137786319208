import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Name } from "../../../interfaces/Name";

export const initialStateName1: Name = {
  name: "",
  profession: "",
  fontSize: 32,
  align: "flex-start",
  direction: "row",
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const name1Slice = createSlice({
  name: "name1",
  initialState: initialStateName1,
  reducers: {
    setBlockDataName1: (state, action: PayloadAction<Name>) => {
      state.name = action.payload.name;
      state.profession = action.payload.profession;
      state.fontSize = action.payload.fontSize;
      state.align = action.payload.align;
      state.direction = action.payload.direction;
      state.style = action.payload.style;
    },
    setNameName1: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setProfessionName1: (state, action: PayloadAction<string>) => {
      state.profession = action.payload;
    },
    setFontSizeName1: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    setAlignName1: (state, action: PayloadAction<{align: "flex-start" | "center" | "flex-end", direction: "row" | "row-reverse"}>) => {
      state.align = action.payload.align;
      state.direction = action.payload.direction;
    },
    setStyleName1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlockDataName1, setNameName1, setProfessionName1, setFontSizeName1, setAlignName1, setStyleName1 } = name1Slice.actions;

export default name1Slice.reducer;
