import "./_FormsStyles.css";

import React, { FC } from "react";
import { Certificate } from "../../../../interfaces/Certification";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addCertificateCertifications1,
  removeCertificateCertifications1,
  setCertificateDateCertifications1,
  setCertificateLinkCertifications1,
  setCertificateNameCertifications1,
  setOrganizationNameCertifications1,
  setStyleCertifications1,
  setTitleCertifications1,
} from "../../../../features/elements/certifications/certification1Slice";
import { CollapseableForm, RemoveFieldsButton } from "./_CommonComponents";
import { TextInput } from "../../../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../../../Components/Inputs/ButtonComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface CertificationsFormProps {}

export const Certifications1Form: FC<CertificationsFormProps> = React.memo((props) => {
  const certifications1 = useAppSelector((state) => state.certifications1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => {
    dispatch(addCertificateCertifications1());
  };
  const handleRemoveFields = (id: string) => {
    dispatch(removeCertificateCertifications1(id));
  };

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => {
    dispatch(setTitleCertifications1(title));
  };
  const handleOrganizationNameInput = (organizationName: string, certificateId: string): void => {
    dispatch(setOrganizationNameCertifications1({ organizationName, certificateId }));
  };
  const handleCertificateNameInput = (certificateName: string, certificateId: string): void => {
    dispatch(setCertificateNameCertifications1({ certificateName, certificateId }));
  };
  const handleCertificateDateInput = (certificateDate: string, certificateId: string): void => {
    dispatch(setCertificateDateCertifications1({ certificateDate, certificateId }));
  };
  const handleCertificateLinkInput = (certificateLink: string, certificateId: string): void => {
    dispatch(setCertificateLinkCertifications1({ certificateLink, certificateId }));
  };

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleCertifications1({ ...certifications1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleCertifications1({ ...certifications1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Certifications #1</h3>
      <div style={{ margin: "8px" }}>
        <TextInput
          label="Title"
          value={certifications1.title}
          onChange={(e) => handleBlockTitleInput(e.target.value)}
        />
      </div>
      {certifications1.data.map((certificate: Certificate, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Organization Name e.g. Udemy/Udacity etc."
                    value={certificate.organizationName}
                    onChange={(e) => handleOrganizationNameInput(e.target.value, certificate.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Certification date e.g. Jan 1, 2022"
                    value={certificate.certificateDate}
                    onChange={(e) => handleCertificateDateInput(e.target.value, certificate.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Certificate Name e.g. Machine Learning Certificate"
                    value={certificate.certificateName}
                    onChange={(e) => handleCertificateNameInput(e.target.value, certificate.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Certificate Link"
                    value={certificate.certificateLink}
                    onChange={(e) => handleCertificateLinkInput(e.target.value, certificate.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={certificate.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={certifications1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={certifications1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
