// SLIDER COMPONENT
import "./SliderComponent.css";

export const SliderComponent = ({
  value,
  handleSliderChange,
  min,
  max,
  step,
  label,
}: {
  value: number;
  handleSliderChange: (event: React.ChangeEvent<HTMLInputElement>, newValue: number | number[]) => void;
  min: number;
  max: number;
  step: number;
  label: string;
}) => {
  const calculateBackground = () => {
    const percentage = ((value - min) / (max - min)) * 100;
    const gradientStyle = `linear-gradient(to right, var(--color-primary) ${percentage}%, var(--color-muted) ${percentage}%)`
    return gradientStyle;
  };

  return (
    <div className="sliderWrapper">
      <input
        type="range"
        className="slider"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(event) => handleSliderChange(event, parseInt(event.target.value))}
        style={{ background: calculateBackground() }}
      />
      {label}
    </div>
  );
};