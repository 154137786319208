import { LoadingIcon } from "../../static/Icons/Icons";
import "./IconButtonComponent.css";

import React, { FC } from "react";

interface IconButtonComponentProps {
  iconColor?: string;
  onClick: (() => void) | ((e: React.MouseEvent<HTMLButtonElement>) => void);
  children: React.ReactNode;
  size: "small" | "medium" | "large";
  className?: string;
}

export const IconButtonComponent: FC<IconButtonComponentProps> = (props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onClick(e);
  };

  return (
    <button
      className={`iconButton ${getClassFromSize(props.size)} ${props.className}`}
      onClick={handleClick}
      style={{ color: props.iconColor }}
    >
      {props.children}
    </button>
  );
};

const getClassFromSize = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "smallIconButton";
    case "medium":
      return "mediumIconButton";
    case "large":
      return "largeIconButton";
    default:
      return "mediumIconButton";
  }
};
