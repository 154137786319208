import "./_CommonComponents.css";

import React, { FC } from "react";
import { ColorPickerInput } from "../../../../Components/Inputs/ColorPickerInput";
import { ChevronDownIcon, DashCircleIcon } from "../../../../static/Icons/Icons";
import { IconButtonComponent } from "../../../../Components/Inputs/IconButtonComponent";
import Collapse from "../../../../Components/Layout/CollapseComponent";

interface CollapseableFormProps {
  expanded: boolean;
  handleExpandClick: () => void;
  bgColor: string;
  handleBgColor: (newColor: string) => void;
  textColor: string;
  handleTextColor: (newColor: string) => void;
}

export const CollapseableForm: FC<CollapseableFormProps> = React.memo((props) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <IconButtonComponent
          size="medium"
          onClick={props.handleExpandClick}
          aria-expanded={props.expanded}
          aria-label="show advanced options"
          className="expandMoreIconButton"
        >
          <ChevronDownIcon
            style={{
              transform: props.expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform var(--transition-main)",
              margin: "2px 0px 0px 0px",
            }}
          />
        </IconButtonComponent>
      </div>
      <Collapse expanded={props.expanded}>
        <div className="colorFormWrapper">
          <ColorPickerInput
            label="Background Color"
            color={props.bgColor ? props.bgColor : "#123456"}
            handleColor={props.handleBgColor}
          />
          <ColorPickerInput
            label="Text Color"
            color={props.textColor ? props.textColor : "#000000"}
            handleColor={props.handleTextColor}
          />
        </div>
      </Collapse>
    </>
  );
});

// THE REMOVE FIELDS BUTTON IN FORMS
export const RemoveFieldsButton = ({
  handleRemoveFields,
  id,
}: {
  handleRemoveFields: (id: string) => void;
  id: string;
}) => {
  return (
    <IconButtonComponent
      size="medium"
      className="removeFieldsButton"
      onClick={() => {
        handleRemoveFields(id);
      }}
    >
      <DashCircleIcon />
    </IconButtonComponent>
  );
};
