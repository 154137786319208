import "./CircularProgress.css";

import React, { FC, useEffect, useState } from "react";

interface CircularProgressIconProps {
  size?: number;
  thickness?: number;
  color?: string;
}

const CircularProgressIcon: FC<CircularProgressIconProps> = ({
  size = 24,
  thickness = 2,
  color = "var(--color-on-primary)",
}) => {
  const [arcLength, setArcLength] = useState(0.2);
  const minArcLength = 0.2,
    maxArcLength = 0.8;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newArcLength = Math.random() * (maxArcLength - minArcLength) + minArcLength;
      setArcLength(newArcLength);
    }, 500); // Change arc length every 700ms

    return () => clearInterval(intervalId);
  }, [minArcLength, maxArcLength]);

  const radius = (size - thickness) / 2;
  const circumference = 2 * Math.PI * radius;
  const dashArray = `${arcLength * circumference} ${(1 - arcLength) * circumference}`;

  return (
    <div
      // className="inline-flex relative"
      style={{ display: "inline-flex", position: "relative", width: size, height: size }}
    >
      <svg className="animate-circular-rotate" viewBox={`${size / 2} ${size / 2} ${size} ${size}`}>
        <circle
          // className={`text-${color}-600 transition-all duration-700 ease-in-out`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: 0.75 * circumference,
            transition: "stroke-dasharray 500ms ease-in",
            color: color,
          }}
          strokeWidth={thickness}
          strokeLinecap="round"
          stroke="currentColor"
          fill="none"
          cx={size}
          cy={size}
          r={radius}
        />
      </svg>
    </div>
  );
};

export default CircularProgressIcon;
