import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";

import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { Name } from "../../../../interfaces/Name";
import { useAppSelector } from "../../../../app/hooks";

const dummyName = {
  name: "John Doe",
  profession: "Software Engineer",
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const isEmptyName = (name: Name) => {
  if (name.name === "" && name.profession === "") return true;
  return false;
};

interface NameProps {
  item: GridItem;
}
export const NameBlock1: React.FC<NameProps> = (props) => {
  const name1 = useAppSelector((state) => state.name1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Name
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyName(name1));
  }, [name1]);

  const toBeShownName = isEmptyName(name1) ? dummyName : name1;

  return (
    <div
      style={{
        backgroundColor: toBeShownName.style.bgColor,
        color: toBeShownName.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div
        className={"blockWrapper"}
        style={{
          fontFamily: formStyles.fontFamily,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: name1.direction,
          justifyContent: name1.align,
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: name1.fontSize || 36,
            fontWeight: 600,
            marginBottom: 0,
            display: "inline-block",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownName.name}
        </h1>
        <p style={{ marginTop: name1.fontSize - 22, display: "inline-block", opacity: isEmpty ? 0.5 : 1 }}>
          &nbsp;&nbsp;{toBeShownName.profession}&nbsp;&nbsp;
        </p>
        <RemoveBlockButton item={props.item} blockTitle={"Name #1"} />
      </div>
    </div>
  );
};
