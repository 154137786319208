import "./AuthPagesStyles.css";

import React, { useState } from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AppBarHeader from "../../Components/AppBarHeader";
import Footer from "../../Components/Footer";
import OAuthCard from "./OAuthCard";
import { getErrorMessage } from "./errorMessages";

import { getAnalytics, logEvent } from "firebase/analytics";
import { SIGNED_UP } from "../../constants";
import { LockOutlinedIcon } from "../../static/Icons/Icons";
import { TextInput } from "../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";

export interface ILoginPageProps {}

const SignupPage: React.FunctionComponent<ILoginPageProps> = (props) => {
  const analytics = getAnalytics();
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (value: string) => {
    var input = document.createElement("input");
    input.type = "email";
    input.required = true;
    input.value = value;
    return typeof input.checkValidity === "function" ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
  };
  const validatePassword = (value: string) => {
    return value.length > 5;
  };

  const handleSubmitSignup = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAuthing(true);
    setError("");
    if (!email) {
      setError("Please enter an email address.");
    } else if (!validateEmail(email)) {
      setError("Please enter a password.");
    } else if (!password) {
      setError("Please enter a valid email address.");
    } else if (!validatePassword(password)) {
      setError("Please enter a password of 6 or more characters.");
    } else {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((response) => {
          console.log(response.user);
          logEvent(analytics, SIGNED_UP);
          navigate("/");
        })
        .catch((e) => {
          console.log(e);
          setError(getErrorMessage(e.code));
          setAuthing(false);
        });
    }
    setAuthing(false);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  return (
    <>
      <div className="pageWrapper">
        <AppBarHeader />
        <div className="componentWrapper">
          <div className="paper">
            <div
              style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: error ? 0 : 48 }}
            >
              <div className="iconAvatar">
                <LockOutlinedIcon />
              </div>
              &nbsp;&nbsp;&nbsp;
              <h2 className="authHeader">
                Sign Up
              </h2>
            </div>
            {/* ERROR SECTION */}
            {error ? (
              <h3 className="errorSection">
                {error}
              </h3>
            ) : null}
            <form className="form" noValidate>
              <TextInput label="Email Address" onChange={handleEmail} value={email} required={true} type="email" />
              <div style={{ height: "8px" }}>&nbsp;</div>
              <TextInput label="Password" onChange={handlePassword} value={password} required={true} type="password" />
              <div style={{ height: "16px" }}>&nbsp;</div>
              <div style={{ width: "100%" }}>
                <ButtonComponent type="submit" fullWidth loading={authing} onClick={handleSubmitSignup}>
                  SIGN UP
                </ButtonComponent>
              </div>
              <div className="miscOptions">
                <a href="/forgot-password">
                  Forgot password?
                </a>
                <a href="/login">
                  {"Have an account? Log In"}
                </a>
              </div>
            </form>
          </div>
          <OAuthCard />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default SignupPage;
