import { SingleBlockStyle } from "./_SingleBlockStyle";

export type BulletPoint = 'dot' | 'circle' | 'square' | 'triangle' | 'none';

export enum bulletPoints {
  dot = '●',
  circle = '○',
  square = '■',
  triangle = '▶',
  none = '',
}

export interface Others {
  title: string;
  bullet: BulletPoint;
  data: string[];
  style: SingleBlockStyle;
}
