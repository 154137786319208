import React from "react";

export const SkillsIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        {/* first row */}
        <rect x={35} y={35} width={90} height={12} rx={4} fill="#651fff" />
        <rect x={135} y={35} width={60} height={12} rx={4} fill="#651fff" />
        {/* 2nd row */}
        <rect x={35} y={55} width={50} height={12} rx={4} fill="#651fff" />
        <rect x={95} y={55} width={40} height={12} rx={4} fill="#651fff" />
        <rect x={145} y={55} width={50} height={12} rx={4} fill="#651fff" />
        {/* 3rd row */}
        <rect x={35} y={75} width={60} height={12} rx={4} fill="#651fff" />
        <rect x={105} y={75} width={40} height={12} rx={4} fill="#651fff" />
        <rect x={155} y={75} width={50} height={12} rx={4} fill="#651fff" />
        {/* 4th row */}
        <rect x={35} y={95} width={70} height={12} rx={4} fill="#651fff" />
        <rect x={115} y={95} width={50} height={12} rx={4} fill="#651fff" />
        {/* the size text on bottom */}
        {/* <text x="170" y="145" style={{ fontWeight: "bold", fontSize: 24 }} fill="#545454">
        3 x 4
      </text> */}
      </svg>
    </div>
  );
};

export const SkillsIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        {/* first row */}
        <rect x={35} y={35} width={90} height={12} rx={4} fill="#f50057" />
        <rect x={135} y={35} width={60} height={12} rx={4} fill="#f50057" />
        {/* 2nd row */}
        <rect x={35} y={55} width={50} height={12} rx={4} fill="#f50057" />
        <rect x={95} y={55} width={40} height={12} rx={4} fill="#f50057" />
        <rect x={145} y={55} width={50} height={12} rx={4} fill="#f50057" />
        {/* 3rd row */}
        <rect x={35} y={75} width={60} height={12} rx={4} fill="#f50057" />
        <rect x={105} y={75} width={40} height={12} rx={4} fill="#f50057" />
        <rect x={155} y={75} width={50} height={12} rx={4} fill="#f50057" />
        {/* 4th row */}
        <rect x={35} y={95} width={70} height={12} rx={4} fill="#f50057" />
        <rect x={115} y={95} width={50} height={12} rx={4} fill="#f50057" />
        {/* the size text on bottom */}
        {/* <text x="170" y="145" style={{ fontWeight: "bold", fontSize: 24 }} fill="#545454">
      3 x 4
    </text> */}
      </svg>
    </div>

    // <svg fill="none" viewBox="0 0 250 150">
    //   {/* first row */}
    //   <rect x={30} y={36} width={90} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={130} y={36} width={60} height={16} stroke="#123456" strokeWidth={2} />
    //   {/* 2nd row */}
    //   <rect x={30} y={60} width={50} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={90} y={60} width={40} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={140} y={60} width={50} height={16} stroke="#123456" strokeWidth={2} />
    //   {/* 3rd row */}
    //   <rect x={30} y={84} width={60} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={100} y={84} width={40} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={150} y={84} width={50} height={16} stroke="#123456" strokeWidth={2} />
    //   {/* 4th row */}
    //   <rect x={30} y={108} width={70} height={16} stroke="#123456" strokeWidth={2} />
    //   <rect x={110} y={108} width={50} height={16} stroke="#123456" strokeWidth={2} />
    //   {/* the size text on bottom */}
    //   <text x="170" y="145" style={{ fontWeight: "bold", fontSize: 24 }} fill="#545454">
    //     3 x 4
    //   </text>
    // </svg>
  );
};
