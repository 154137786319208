import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Works } from "../../../interfaces/Works";

export const initialStateWorks1: Works = {
  title: "",
  bullet: true,
  indent: true,
  data: [
    {
      id: `work${Date.now()}`,
      workOrganizationName: "",
      workLocation: "",
      jobTitle: "",
      workDetails: [""],
      workDuration: "",
    },
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const works1Slice = createSlice({
  name: "works1",
  initialState: initialStateWorks1,
  reducers: {
    setBlockDataWorks1: (state, action: PayloadAction<Works>) => {
      state.title = action.payload.title;
      state.bullet = action.payload.bullet;
      state.indent = action.payload.indent;
      // Update the data array with potential deep copy for nested arrays
      state.data = action.payload.data.map((item) => ({
        ...item,
        workDetails: [...item.workDetails],
      }));
      state.style = action.payload.style;
    },
    setTitleWorks1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBulletWorks1: (state, action: PayloadAction<boolean>) => {
      state.bullet = action.payload;
    },
    setIndentWorks1: (state, action: PayloadAction<boolean>) => {
      state.indent = action.payload;
    },
    setOrganizationNameByIdWorks1: (state, action: PayloadAction<{ workOrganizationName: string; workId: string }>) => {
      const { workOrganizationName, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workOrganizationName = workOrganizationName;
    },
    setLocationByIdWorks1: (state, action: PayloadAction<{ workLocation: string; workId: string }>) => {
      const { workLocation, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workLocation = workLocation;
    },
    setJobTitleByIdWorks1: (state, action: PayloadAction<{ jobTitle: string; workId: string }>) => {
      const { jobTitle, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].jobTitle = jobTitle;
    },
    setWorkDetailsByIdWorks1: (state, action: PayloadAction<{ workDetails: string[]; workId: string }>) => {
      const { workDetails, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workDetails = workDetails;
    },
    setWorkDurationByIdWorks1: (state, action: PayloadAction<{ workDuration: string; workId: string }>) => {
      const { workDuration, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workDuration = workDuration;
    },
    addWorkWorks1: (state) => {
      state.data.push({
        id: `work${Date.now()}`,
        workOrganizationName: "",
        workLocation: "",
        jobTitle: "",
        workDetails: [""],
        workDuration: "",
      });
    },
    // remove the work from array where work id is the same as when clicked "X" in UI
    removeWorkByIdWorks1: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((work) => work.id !== action.payload);
    },
    setStyleWorks1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataWorks1,
  setTitleWorks1,
  setBulletWorks1,
  setIndentWorks1,
  setOrganizationNameByIdWorks1,
  setLocationByIdWorks1,
  setJobTitleByIdWorks1,
  setWorkDetailsByIdWorks1,
  setWorkDurationByIdWorks1,
  addWorkWorks1,
  removeWorkByIdWorks1,
  setStyleWorks1,
} = works1Slice.actions;

export default works1Slice.reducer;
