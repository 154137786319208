import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";

import { GridItem } from "../../../../interfaces/GridItem";
import { ContactBlock } from "../../../../interfaces/Contact";
import { getUrlDomainName } from "../../../../helpers/getUrlDomainName";
import { getIcon } from "../../../../helpers/Icons";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";
import { RemoveBlockButton } from "./_CommonComponents";

const dummyContact: ContactBlock = {
  title: "Contact Title",
  flipped: false,
  data: {
    address: ["123 Rd, City, State"],
    emails: ["abc@example.com", "github.com/JohnDoe"],
    phno: "+00 1234567890",
  },
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

const isEmptyContact = (contact: ContactBlock) => {
  if (
    contact.title === "" &&
    contact.data.address.join("") === "" &&
    contact.data.phno === "" &&
    contact.data.emails.join("") === ""
  )
    return true;
  return false;
};

interface ContactBlockProps {
  item: GridItem;
}

export const ContactBlock1: React.FC<ContactBlockProps> = (props) => {
  const contact1 = useAppSelector((store) => store.contact1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Contact
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyContact(contact1));
  }, [contact1]);

  const toBeShownContact = isEmpty ? dummyContact : contact1;

  return (
    <div
      style={{
        backgroundColor: toBeShownContact.style.bgColor,
        color: toBeShownContact.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        {/* The Title */}
        {toBeShownContact.title === "" ? (
          <RemoveBlockButton item={props.item} blockTitle={"Contact #1"} flipped={contact1.flipped} />
        ) : (
          <div style={{ display: "flex", flexDirection: contact1.flipped ? "row-reverse" : "row" }}>
            {/* Need to check contact1.title because toBeShownContact will always have a title */}
            {contact1.title === "" ? (
              <BlockTitle formStyles={formStyles} title={""} isOpaque={isEmpty} flipped={contact1.flipped} />
            ) : (
              <BlockTitle
                formStyles={formStyles}
                title={toBeShownContact.title}
                isOpaque={isEmpty}
                flipped={contact1.flipped}
              />
            )}
            <RemoveBlockButton item={props.item} blockTitle={"Contact #1"} flipped={contact1.flipped} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: contact1.flipped ? "flex-end" : "flex-start",
            fontWeight: 500,
            fontSize: 15,
            marginTop: toBeShownContact.title === "" ? 24 : 0,
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
              flexDirection: contact1.flipped ? "row" : "row-reverse",
            }}
          >
            {toBeShownContact.data.address}
            {getIcon({
              name: "address",
              color: formStyles.accentColor,
              style: contact1.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
            })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
              flexDirection: contact1.flipped ? "row" : "row-reverse",
            }}
          >
            {toBeShownContact.data.phno}
            {getIcon({
              name: "phone",
              color: formStyles.accentColor,
              style: contact1.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
            })}
          </div>
          {toBeShownContact.data.emails.map((eachLink) => {
            return (
              <div
                key={eachLink}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 4,
                  flexDirection: contact1.flipped ? "row" : "row-reverse",
                }}
              >
                {eachLink}
                {getIcon({
                  name: getUrlDomainName(eachLink),
                  color: formStyles.accentColor,
                  style: contact1.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const ContactBlock2: React.FC<ContactBlockProps> = (props) => {
  const contact2 = useAppSelector((store) => store.contact2);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Contact
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyContact(contact2));
  }, [contact2]);

  const toBeShownContact = isEmpty ? dummyContact : contact2;

  return (
    <div
      style={{
        backgroundColor: toBeShownContact.style.bgColor,
        color: toBeShownContact.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        {/* The Title */}
        {toBeShownContact.title === "" ? (
          <RemoveBlockButton item={props.item} blockTitle={"Contact #2"} flipped={contact2.flipped} />
        ) : (
          <div style={{ display: "flex", flexDirection: toBeShownContact.flipped ? "row-reverse" : "row" }}>
            {/* Need to check contact2.title because toBeShownContact will always have a title */}
            {contact2.title === "" ? (
              <BlockTitle formStyles={formStyles} title={""} isOpaque={isEmpty} flipped={contact2.flipped} />
            ) : (
              <BlockTitle
                formStyles={formStyles}
                title={toBeShownContact.title}
                isOpaque={isEmpty}
                flipped={contact2.flipped}
              />
            )}
            <RemoveBlockButton item={props.item} blockTitle={"Contact #2"} flipped={contact2.flipped} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: contact2.flipped ? "flex-end" : "flex-start",
            fontWeight: 500,
            fontSize: 15,
            marginTop: toBeShownContact.title === "" ? 24 : 0,
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
              flexDirection: contact2.flipped ? "row" : "row-reverse",
            }}
          >
            {toBeShownContact.data.address}
            {getIcon({
              name: "address",
              color: formStyles.accentColor,
              style: contact2.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
            })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
              flexDirection: contact2.flipped ? "row" : "row-reverse",
            }}
          >
            {toBeShownContact.data.phno}
            {getIcon({
              name: "phone",
              color: formStyles.accentColor,
              style: contact2.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
            })}
          </div>
          {toBeShownContact.data.emails.map((eachLink) => {
            return (
              <div
                key={eachLink}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 4,
                  flexDirection: contact2.flipped ? "row" : "row-reverse",
                }}
              >
                {eachLink}
                {getIcon({
                  name: getUrlDomainName(eachLink),
                  color: formStyles.accentColor,
                  style: contact2.flipped ? { margin: "0px 0px 0px 8px" } : { margin: "0px 8px 0px 0px" },
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const ContactBlock3: React.FC<ContactBlockProps> = (props) => {
  const contact3 = useAppSelector((store) => store.contact3);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Contact
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyContact(contact3));
  }, [contact3]);

  const toBeShownContact = isEmpty ? dummyContact : contact3;

  return (
    <div
      style={{
        backgroundColor: toBeShownContact.style.bgColor,
        color: toBeShownContact.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        {/* The Title */}
        {toBeShownContact.title === "" ? (
          <RemoveBlockButton item={props.item} blockTitle={"Contact #3"} flipped={contact3.flipped} />
        ) : (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            {/* Need to check contact3.title because toBeShownContact will always have a title */}
            {contact3.title === "" ? (
              <BlockTitle formStyles={formStyles} title={""} isOpaque={isEmpty} flipped={contact3.flipped} />
            ) : (
              <BlockTitle formStyles={formStyles} title={toBeShownContact.title} isOpaque={isEmpty} center={true} />
            )}
            <RemoveBlockButton item={props.item} blockTitle={"Contact #3"} flipped={contact3.flipped} />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontWeight: 500,
            fontSize: 15,
            marginTop: 0,
            opacity: isEmpty ? 0.5 : 1,
            lineHeight: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
            }}
          >
            {toBeShownContact.data.address}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: 4,
            }}
          >
            {toBeShownContact.data.phno}
          </div>
          {toBeShownContact.data.emails.map((eachLink) => {
            return (
              <div
                key={eachLink}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 4,
                }}
              >
                {eachLink}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
