import * as React from "react";
import { AboutAndContactIcon1, AboutAndContactIcon2 } from "./LeftMenuIcons/AboutAndContactIcons";
import { EducationIcon1, EducationIcon2, EducationIcon3 } from "./LeftMenuIcons/EducationsIcons";
import { ProjectsIcon1, ProjectsIcon2 } from "./LeftMenuIcons/ProjectIcons";
import { OthersIcon1 } from "./LeftMenuIcons/OthersIcons";
import { SkillsIcon1, SkillsIcon2 } from "./LeftMenuIcons/SkillsIcons";
import { WorksIcon1, WorksIcon2 } from "./LeftMenuIcons/WorksIcons";
import { AboutIcon1 } from "./LeftMenuIcons/AboutIcons";
import { ContactIcon1, ContactIcon2, ContactIcon3 } from "./LeftMenuIcons/ContactIcons";
import { RatingsIcon1, RatingsIcon2, RatingsIcon3, RatingsIcon4 } from "./LeftMenuIcons/RatingsIcons";
import { SpacersIcon1, SpacersIcon2, SpacersIcon3 } from "./LeftMenuIcons/SpacersIcons";
import { NameIcon1 } from "./LeftMenuIcons/NameIcons";
import { CertificateIcon1 } from "./LeftMenuIcons/CertificatesIcons";
import { AvatarIcon1 } from "./LeftMenuIcons/AvatarIcons";

import "./LeftMenu.css";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { addGridItem } from "../../../features/gridItem/gridItemSlice";
import { initialStateAboutWithContact1 } from "../../../features/elements/aboutWithContact/aboutWithContact1Slice";
import { initialStateAboutWithContact2 } from "../../../features/elements/aboutWithContact/aboutWithContact2Slice";
import { Layout as LayoutItem } from "react-grid-layout";
import { ButtonComponent } from "../../../Components/Inputs/ButtonComponent";
import { CaretLeftIcon, CaretRightIcon } from "../../../static/Icons/Icons";

const sections = [
  ["aboutwithcontact1", "aboutwithcontact2"],
  ["photo1"],
  ["about1"],
  ["name1"],
  ["contact1", "contact2", "contact3"],
  [
    "educations1",
    "educations2",
    // "educations3" - table like education - not implemented
  ],
  ["skills1", "skills2"],
  ["works1", "works2"],
  ["projects1", "projects2"],
  ["ratings1", "ratings2", "ratings3", "ratings4"],
  ["certifications1"],
  ["others1"],
  ["spacer1", "spacer2", "spacer3"],
];

interface LeftMenuProps {
  leftMenuExpanded: boolean;
  openLeftMenuDrawer: (widthInPercentage: string) => void;
  closeLeftMenuDrawer: () => void;
}

const LeftMenu: React.FC<LeftMenuProps> = (props) => {
  const gridItems = useAppSelector((state) => state.gridItems);
  const dispatch = useAppDispatch();

  const handleAddBlock = (blockName: string) => {
    switch (blockName) {
      case "aboutwithcontact1":
        dispatch(
          addGridItem({
            name: "aboutwithcontact1",
            x: Infinity,
            y: Infinity,
            width: 12,
            height: 10,
            data: initialStateAboutWithContact1,
            isResizable: true,
          })
        );
        break;

      case "aboutwithcontact2":
        dispatch(
          addGridItem({
            name: "aboutwithcontact2",
            x: Infinity,
            y: Infinity,
            width: 12,
            height: 10,
            data: initialStateAboutWithContact2,
            isResizable: true,
          })
        );
        break;

      case "photo1":
        dispatch(
          addGridItem({
            name: "photo1",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 10,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "about1":
        dispatch(
          addGridItem({
            name: "about1",
            x: Infinity,
            y: Infinity,
            width: 8,
            height: 8,
            data: {
              name: "",
              profession: "",
              about: "",
              style: {
                bgColor: "#ffffff",
                textColor: "#000000",
              },
            },
            isResizable: true,
          })
        );
        break;

      case "name1":
        dispatch(
          addGridItem({
            name: "name1",
            x: Infinity,
            y: Infinity,
            width: 8,
            height: 4,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "contact1":
        dispatch(
          addGridItem({
            name: "contact1",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 8,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "contact2":
        dispatch(
          addGridItem({
            name: "contact2",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 8,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "contact3":
        dispatch(
          addGridItem({
            name: "contact3",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 8,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "educations1":
        dispatch(
          addGridItem({
            name: "educations1",
            x: Infinity,
            y: Infinity,
            width: 7,
            height: 11,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "educations2":
        dispatch(
          addGridItem({
            name: "educations2",
            x: Infinity,
            y: Infinity,
            width: 12,
            height: 9,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "educations3":
        dispatch(
          addGridItem({
            name: "educations3",
            x: Infinity,
            y: Infinity,
            width: 12,
            height: 12,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "skills1":
        dispatch(
          addGridItem({
            name: "skills1",
            x: Infinity,
            y: Infinity,
            width: 5,
            height: 11,
            data: {
              color: "#ff5656",
              title: "Skills UE",
              chipRadius: 16,
              chipSize: 5,
              filled: true,
              flipped: false,
              data: [
                "Lorem",
                "ipsum",
                "dolor sit",
                "amet",
                "consect",
                "etur",
                "Adipis",
                "icing",
                "Nulla",
                "acusant",
                "officiis",
                "distinct",
              ],
              style: {
                bgColor: "#ffffff",
                textColor: "#000000",
              },
            },
            isResizable: true,
          })
        );
        break;

      case "skills2":
        dispatch(
          addGridItem({
            name: "skills2",
            x: Infinity,
            y: Infinity,
            width: 5,
            height: 11,
            data: {
              color: "#ff5656",
              title: "Skills UE",
              chipRadius: 16,
              chipSize: 5,
              filled: true,
              flipped: false,
              data: [
                "Lorem",
                "ipsum",
                "dolor sit",
                "amet",
                "consect",
                "etur",
                "Adipis",
                "icing",
                "Nulla",
                "acusant",
                "officiis",
                "distinct",
              ],
              style: {
                bgColor: "#ffffff",
                textColor: "#000000",
              },
            },
            isResizable: true,
          })
        );
        break;

      case "works1":
        dispatch(
          addGridItem({
            name: "works1",
            x: Infinity,
            y: Infinity,
            width: 8,
            height: 12,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "works2":
        dispatch(
          addGridItem({
            name: "works2",
            x: Infinity,
            y: Infinity,
            width: 7,
            height: 15,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "projects1":
        dispatch(
          addGridItem({
            name: "projects1",
            x: Infinity,
            y: Infinity,
            width: 8,
            height: 16,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "projects2":
        dispatch(
          addGridItem({
            name: "projects2",
            x: Infinity,
            y: Infinity,
            width: 7,
            height: 16,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "ratings1":
        dispatch(
          addGridItem({
            name: "ratings1",
            x: Infinity,
            y: Infinity,
            width: 5,
            height: 10,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "ratings2":
        dispatch(
          addGridItem({
            name: "ratings2",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 11,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "ratings3":
        dispatch(
          addGridItem({
            name: "ratings3",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 11,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "ratings4":
        dispatch(
          addGridItem({
            name: "ratings4",
            x: Infinity,
            y: Infinity,
            width: 4,
            height: 11,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "certifications1":
        dispatch(
          addGridItem({
            name: "certifications1",
            x: Infinity,
            y: Infinity,
            width: 6,
            height: 10,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "others1":
        dispatch(
          addGridItem({
            name: "others1",
            x: Infinity,
            y: Infinity,
            width: 6,
            height: 10,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "spacer1":
        dispatch(
          addGridItem({
            name: "spacer1",
            x: Infinity,
            y: Infinity,
            width: 3,
            height: 5,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "spacer2":
        dispatch(
          addGridItem({
            name: "spacer2",
            x: Infinity,
            y: Infinity,
            width: 6,
            height: 5,
            data: {},
            isResizable: true,
          })
        );
        break;

      case "spacer3":
        dispatch(
          addGridItem({
            name: "spacer3",
            x: Infinity,
            y: Infinity,
            width: 9,
            height: 5,
            data: {},
            isResizable: true,
          })
        );
        break;

      default:
        console.error("Block Name does not exist in LeftMenu.tsx", blockName);
        break;
    }
  };

  // Check if already in Array then grey out the block
  const inItemsArray = (itemName: string): boolean => {
    for (let i = 0; i < gridItems.items.length; i++) {
      if (gridItems.items[i].name == itemName) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {/* THE ICON TO CLOSE LEFT-MENU IF EXPANDED */}
      {props.leftMenuExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            top: "72px",
            backgroundColor: "var(--color-surface)",
            padding: "12px 0px 12px 0px",
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
            }}
          >
            <h3 style={{ width: "100%", textWrap: "nowrap" }}>Choose Cards</h3>
            <div style={{ width: "100%" }}>&nbsp;</div>
            <div style={{ width: "100%" }}>&nbsp;</div>
            <div style={{ width: "100%" }}>&nbsp;</div>
            <div style={{ width: "100%" }}>&nbsp;</div>
            <ButtonComponent onClick={props.closeLeftMenuDrawer} size="small" fullWidth>
              <CaretLeftIcon />
            </ButtonComponent>
          </div>
        </div>
      )}
      {/* OPTION TO EXPAND LEFT-MENU IN LARGER SCREENS */}
      {!props.leftMenuExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "12px 24px",
            backgroundColor: "var(--color-surface)",
            position: "sticky",
            top: "0px",
            zIndex: 1,
          }}
        >
          <ButtonComponent
            size="small"
            onClick={() => {
              if (window.innerWidth > 600 && window.innerWidth < 1200) {
                props.openLeftMenuDrawer("60");
              } else {
                props.openLeftMenuDrawer("30");
              }
            }}
            fullWidth
          >
            <CaretRightIcon />
          </ButtonComponent>
        </div>
      )}

      {/* THE MENU ITEMS - OPTIONALLY RENDER BASED ON EXPANDED OR NOT */}
      {sections.map((section, index) => {
        return props.leftMenuExpanded ? (
          <div style={{ padding: "12px 24px" }} key={index}>
            <div className={"categoryTitle"} style={{ marginTop: 0 }}>
              {getCategoryTitle(section[0])}
            </div>
            <div className="leftMenuGridContainer">
              {section.map((item) => {
                return (
                  <div
                    className={inItemsArray(item) ? "leftMenuEachSelectedIcon" : "leftMenuEachIcon"}
                    onClick={() => handleAddBlock(item)}
                    key={item}
                  >
                    {getCardIcon(item)}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div style={{ paddingLeft: 3, paddingRight: 3 }} key={index}>
            <div className={"categoryTitle"} style={{ marginTop: 0 }}>
              {getCategoryTitle(section[0])}
            </div>
            <div>
              {section.map((item) => {
                return (
                  <div
                    className={inItemsArray(item) ? "leftMenuEachSelectedIcon" : "leftMenuEachIcon"}
                    onClick={() => handleAddBlock(item)}
                    key={item}
                  >
                    {getCardIcon(item)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

// const AboutBlocks

export default LeftMenu;

const getCardIcon = (section: string) => {
  switch (section) {
    case "aboutwithcontact1":
      return <AboutAndContactIcon1 />;
    case "aboutwithcontact2":
      return <AboutAndContactIcon2 />;

    case "photo1":
      return <AvatarIcon1 />;

    case "about1":
      return <AboutIcon1 />;

    case "name1":
      return <NameIcon1 />;

    case "contact1":
      return <ContactIcon1 />;
    case "contact2":
      return <ContactIcon2 />;
    case "contact3":
      return <ContactIcon3 />;

    case "educations1":
      return <EducationIcon1 />;
    case "educations2":
      return <EducationIcon2 />;
    case "educations3":
      return <EducationIcon3 />;

    case "skills1":
      return <SkillsIcon1 />;
    case "skills2":
      return <SkillsIcon2 />;

    case "works1":
      return <WorksIcon1 />;
    case "works2":
      return <WorksIcon2 />;

    case "projects1":
      return <ProjectsIcon1 />;
    case "projects2":
      return <ProjectsIcon2 />;
      
    case "ratings1":
      return <RatingsIcon1 />;
    case "ratings2":
      return <RatingsIcon2 />;
    case "ratings3":
      return <RatingsIcon3 />;
    case "ratings4":
      return <RatingsIcon4 />;

    case "certifications1":
      return <CertificateIcon1 />;

    case "others1":
      return <OthersIcon1 />;

    case "spacer1":
      return <SpacersIcon1 />;
    case "spacer2":
      return <SpacersIcon2 />;
    case "spacer3":
      return <SpacersIcon3 />;
      
    default:
      return <>getCardIcons() does not have {section} in switch case in LeftMenu.tsx</>;
  }
};

const getCategoryTitle = (category: string) => {
  if (category.startsWith("aboutwithcontact")) {
    return "About + Contact";
  } else if (category.startsWith("photo")) {
    return "Images";
  } else if (category.endsWith("s1")) {
    return category[0].toUpperCase() + category.slice(1, -2);
  } else {
    return category[0].toUpperCase() + category.slice(1, -1);
  }
};
