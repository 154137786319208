import "./AppBarHeader.css";

import React, { useState } from "react";

import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { goToYourResume } from "../services/ResumeService";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setThemeMode } from "../features/theme/themeSlice";
import { useAuth } from "../helpers/useAuth";
import {
  AccountCircleIcon,
  BoxArrowInRightIcon,
  BoxArrowOutRightIcon,
  FileRichIcon,
  HouseIcon,
  MenuIcon,
  MoonIcon,
  MoonStarsIcon,
  PencilIcon,
  SunIcon,
} from "../static/Icons/Icons";
import { IconButtonComponent } from "./Inputs/IconButtonComponent";
import { ButtonComponent } from "./Inputs/ButtonComponent";
import DrawerComponent from "./Layout/DrawerComponent";
import { EmailIcon } from "../static/Icons/SocialMediaIcons";
import Menu, { Divider, MenuItem } from "./Layout/MenuComponent";

interface AppBarHeaderProps {}

const AppBarHeader: React.FC<AppBarHeaderProps> = () => {
  const navigate = useNavigate();

  const theme = useAppSelector((state) => state.theme.themeMode);
  const dispatch = useAppDispatch();

  const { user, loading } = useAuth();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const auth = getAuth();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDarkMode = () => dispatch(setThemeMode(theme === "light" ? "dark" : "light"));

  return (
    <>
      {/* <!-- Start Header --> */}
      {!loading && (
        <nav className="navbar" id="navbar">
          <a href="/" className="logo">
            <h1>Resumez - A Free Resume Builder</h1>
          </a>
          {/* All the navigation links, only for large screens */}
          <div className="navLinks">
            <a style={{ fontWeight: 500 }} href="/" className="fill">
              Home
            </a>
            <a style={{ fontWeight: 500 }} href="/create" className="fill">
              create
            </a>
            <a
              style={{ fontWeight: 500 }}
              // href={`/${userId}`}
              className="fill"
              onClick={async (e) => {
                e.preventDefault();
                return await goToYourResume();
              }}
            >
              Your Resume
            </a>
            <a style={{ fontWeight: 500 }} href="/contact" className="fill">
              contact
            </a>
            <IconButtonComponent size="medium" className="themeToggleButton" onClick={toggleDarkMode}>
              {theme === "dark" ? <SunIcon size={22} /> : <MoonStarsIcon size={22} />}
            </IconButtonComponent>
            {user ? (
              <>
                &nbsp;&nbsp;&nbsp;
                <IconButtonComponent size="medium" aria-label="profile" className="profileButton" onClick={handleClick}>
                  <AccountCircleIcon size={24} />
                </IconButtonComponent>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <p style={{ color: "#666", padding: "8px 0px 8px 16px" }}>Account</p>
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <AccountCircleIcon /> &nbsp;&nbsp;Profile
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={async () => {
                      handleClose();
                      await signOut(auth);
                      navigate("/login");
                    }}
                  >
                    <BoxArrowOutRightIcon />
                    &nbsp;&nbsp;Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <a
                style={{ fontWeight: 500, marginLeft: "24px" }}
                className="fill"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login / Sign up
              </a>
            )}
            {/* <div className="toggle-menu scale-effect">
              <i className="fas fa-times"></i>
            </div> */}
          </div>

          {/* <!-- Toggle Menu Drawer Button--> */}
          <div className="toggleDrawerMenuButton">
            <ButtonComponent
              fullWidth
              onClick={() => {
                setOpenDrawer(true);
              }}
            >
              <MenuIcon />
            </ButtonComponent>
          </div>
        </nav>
      )}
      {/* ============================================================= */}
      {/* ============================================================= */}
      {/* DRAWER FOR SMALLER SCREENS*/}
      <>
        <DrawerComponent
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          anchor="rtl"
          width="70%"
        >
          <div className="drawerWrapper">
            {/* HOMEPAGE */}
            <div className="buttonWrapper">
              <ButtonComponent fullWidth onClick={() => navigate("/")}>
                <HouseIcon />
                &nbsp;&nbsp;&nbsp;Home
              </ButtonComponent>
            </div>

            {/* CREATE */}
            <div className="buttonWrapper">
              <ButtonComponent fullWidth onClick={() => navigate("/create")}>
                <PencilIcon />
                &nbsp;&nbsp;&nbsp;Create
              </ButtonComponent>
            </div>

            {/* YOUR RESUME */}
            {user ? (
              <div className="buttonWrapper">
                <ButtonComponent
                  fullWidth
                  onClick={async (e) => {
                    e.preventDefault();
                    await goToYourResume();
                  }}
                >
                  <FileRichIcon />
                  &nbsp;&nbsp;&nbsp;Your Resume
                </ButtonComponent>
              </div>
            ) : null}

            {/* CONTACT */}
            <div className="buttonWrapper">
              <ButtonComponent fullWidth onClick={() => navigate("/contact")}>
                <EmailIcon />
                &nbsp;&nbsp;&nbsp;Contact
              </ButtonComponent>
            </div>

            {/* THEME */}
            <div className="buttonWrapper">
              <ButtonComponent fullWidth onClick={toggleDarkMode}>
                {theme === "dark" ? <SunIcon margin="3px 3px 0px 3px" /> : <MoonStarsIcon margin="3px 3px 0px 3px" />}
                &nbsp;&nbsp;&nbsp;Change Theme
              </ButtonComponent>
            </div>

            {/* PROFILE */}
            {user ? (
              <div className="buttonWrapper">
                <ButtonComponent fullWidth onClick={() => navigate("/profile")}>
                  <AccountCircleIcon />
                  &nbsp;&nbsp;&nbsp;Profile
                </ButtonComponent>
              </div>
            ) : null}

            {/* LOGIN/LOGOUT */}
            {user ? (
              <div className="buttonWrapper">
                <ButtonComponent
                  fullWidth
                  onClick={() => {
                    signOut(auth);
                    navigate("/login");
                  }}
                >
                  <BoxArrowOutRightIcon />
                  &nbsp;&nbsp;&nbsp;Sign out
                </ButtonComponent>
              </div>
            ) : (
              <div className="buttonWrapper">
                <ButtonComponent fullWidth onClick={() => navigate("/login")}>
                  <BoxArrowInRightIcon />
                  &nbsp;&nbsp;&nbsp;Login / Sign up
                </ButtonComponent>
              </div>
            )}
          </div>
        </DrawerComponent>
      </>
    </>
  );
};

export default AppBarHeader;
