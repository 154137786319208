import "./_FormsStyles.css";

import React, { FC, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setChipRadiusSkills1,
  setChipSizeSkills1,
  setColorSkills1,
  setDataSkills1,
  setFilledSkills1,
  setFlippedSkills1,
  setStyleSkills1,
  setTitleSkills1,
} from "../../../../features/elements/skills/skills1Slice";
import { CollapseableForm } from "./_CommonComponents";
import {
  setColorSkills2,
  setChipRadiusSkills2,
  setChipSizeSkills2,
  setTitleSkills2,
  setFlippedSkills2,
  setFilledSkills2,
  setDataSkills2,
  setStyleSkills2,
} from "../../../../features/elements/skills/skills2Slice";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { CustomSwitch } from "../../../../Components/Inputs/SwitchInput";
import { SliderComponent } from "../../../../Components/Inputs/SliderComponent";
import { ButtonGroupComponent2 } from "../../../../Components/Inputs/ButtonGroupComponent";
import { ColorPickerInput } from "../../../../Components/Inputs/ColorPickerInput";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface SkillsFormProps {}

export const SkillsForm1: FC<SkillsFormProps> = React.memo((props) => {
  const skills1 = useAppSelector((state) => state.skills1);
  const dispatch = useAppDispatch();

  // Expand More Options
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [currentColor, setCurrentColor] = useState<string>(
    skills1.color.length > 0
      ? skills1.color
      : "#000000".replace(/0/g, function () {
          return (~~(Math.random() * 16)).toString(16);
        })
  );

  const [chipRadiusSliderValue, setChipRadiusSliderValue] = useState(skills1.chipRadius);
  const [chipSizeSliderValue, setChipSizeSliderValue] = useState(skills1.chipSize);

  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setColorSkills1(currentColor)), 400);
    return () => clearTimeout(lastRequest);
  }, [currentColor]);
  // Change the value after user has done sliding the chip radius slider
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setChipRadiusSkills1(chipRadiusSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [chipRadiusSliderValue]);
  // Change the value after user has done sliding the chip size slider
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setChipSizeSkills1(chipSizeSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [chipSizeSliderValue]);

  // ==================================================================================================================
  // Handle Text/Slider Inputs
  const handleChangeTitle = (title: string) => dispatch(setTitleSkills1(title));
  const handleFlip = (flipped: boolean) => dispatch(setFlippedSkills1(flipped));
  const handleChangeSkillsItemsColor = (newColor: string) => setCurrentColor(newColor);
  const handleCheckFilled = (isFilled: boolean) => dispatch(setFilledSkills1(isFilled));
  const handleChipSizeSlider = (event: React.ChangeEvent<HTMLInputElement>, newChipSize: number | number[]) => {
    const radius = Array.isArray(newChipSize) ? newChipSize[0] : newChipSize;
    setChipSizeSliderValue(radius);
  };
  const handleChipRadiusSlider = (event: React.ChangeEvent<HTMLInputElement>, newChipRadius: number | number[]) => {
    const radius = Array.isArray(newChipRadius) ? newChipRadius[0] : newChipRadius;
    setChipRadiusSliderValue(radius);
  };
  const handleData = (data: string[]) => dispatch(setDataSkills1(data));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleSkills1({ ...skills1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleSkills1({ ...skills1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Skills #1</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={skills1.title} onChange={(e) => handleChangeTitle(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={4} style={{marginTop: 10}}>
          <ColorPickerInput label="Chip Color" color={skills1.color} handleColor={handleChangeSkillsItemsColor} />
        </GridItem>
        <GridItem xs={12} md={4} style={{marginTop: 8}}>
          <CustomSwitch label="Filled" checked={skills1.filled} onChange={(e) => handleCheckFilled(e.target.checked)} />
        </GridItem>
        <GridItem xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px 0px" }}>
          <ButtonGroupComponent2 flipped={skills1.flipped} handleFlip={handleFlip} />
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Skills (Separate each skill with comma ',' i.e. Html,Css)"
            value={skills1.data.join(",")}
            onChange={(e) => handleData(e.target.value.split(","))}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <SliderComponent label="Chip Radius" min={0} max={16} step={2} value={chipRadiusSliderValue} handleSliderChange={handleChipRadiusSlider} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <SliderComponent label="Chip Size" min={0} max={8} step={1} value={chipSizeSliderValue} handleSliderChange={handleChipSizeSlider} />
        </GridItem>
      </GridContainer>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={skills1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={skills1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});


// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
// ====================================================================================================================
export const SkillsForm2: FC<SkillsFormProps> = React.memo(() => {
  const skills2 = useAppSelector((state) => state.skills2);
  const dispatch = useAppDispatch();

  // Expand More Options
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [currentColor, setCurrentColor] = useState<string>(
    skills2.color.length > 0
      ? skills2.color
      : "#000000".replace(/0/g, function () {
          return (~~(Math.random() * 16)).toString(16);
        })
  );

  const [chipRadiusSliderValue, setChipRadiusSliderValue] = useState(skills2.chipRadius);
  const [chipSizeSliderValue, setChipSizeSliderValue] = useState(skills2.chipSize);

  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setColorSkills2(currentColor)), 400);
    return () => clearTimeout(lastRequest);
  }, [currentColor]);
  // Change the value after user has done sliding the chip radius slider
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setChipRadiusSkills2(chipRadiusSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [chipRadiusSliderValue]);
  // Change the value after user has done sliding the chip size slider
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setChipSizeSkills2(chipSizeSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [chipSizeSliderValue]);

  // ==================================================================================================================
  // Handle Text/Slider Inputs
  const handleChangeTitle = (title: string) => dispatch(setTitleSkills2(title));
  const handleFlip = (flipped: boolean) => dispatch(setFlippedSkills2(flipped));
  const handleChangeSkillsItemsColor = (newColor: string) => setCurrentColor(newColor);
  const handleCheckFilled = (isFilled: boolean) => dispatch(setFilledSkills2(isFilled));
  const handleChipSizeSlider = (event: React.ChangeEvent<HTMLInputElement>, newChipSize: number | number[]) => {
    const radius = Array.isArray(newChipSize) ? newChipSize[0] : newChipSize;
    setChipSizeSliderValue(radius);
  };
  const handleChipRadiusSlider = (event: React.ChangeEvent<HTMLInputElement>, newChipRadius: number | number[]) => {
    const radius = Array.isArray(newChipRadius) ? newChipRadius[0] : newChipRadius;
    setChipRadiusSliderValue(radius);
  };
  const handleData = (data: string[]) => dispatch(setDataSkills2(data));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleSkills2({ ...skills2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleSkills2({ ...skills2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Skills #2</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={skills2.title} onChange={(e) => handleChangeTitle(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={4} style={{marginTop: 10}}>
          <ColorPickerInput label="Chip Color" color={skills2.color} handleColor={handleChangeSkillsItemsColor} />
        </GridItem>
        <GridItem xs={12} md={4} style={{marginTop: 8}}>
          <CustomSwitch label="Filled" checked={skills2.filled} onChange={(e) => handleCheckFilled(e.target.checked)} />
        </GridItem>
        <GridItem xs={12} md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "8px 0px" }}>
          <ButtonGroupComponent2 flipped={skills2.flipped} handleFlip={handleFlip} />
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Skills (Separate each skill with comma ',' i.e. Html,Css)"
            value={skills2.data.join(",")}
            onChange={(e) => handleData(e.target.value.split(","))}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <SliderComponent label="Chip Radius" min={0} max={16} step={2} value={chipRadiusSliderValue} handleSliderChange={handleChipRadiusSlider} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <SliderComponent label="Chip Size" min={0} max={8} step={1} value={chipSizeSliderValue} handleSliderChange={handleChipSizeSlider} />
        </GridItem>
      </GridContainer>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={skills2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={skills2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
