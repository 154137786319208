import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Certificate, Certifications } from "../../../interfaces/Certification";
import { ContactBlock } from "../../../interfaces/Contact";

export const initialStateContactBlock3: ContactBlock = {
  title: "",
  flipped: true,
  data: { address: [""], emails: [""], phno: "" },
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const Contact3Slice = createSlice({
  name: "contact3",
  initialState: initialStateContactBlock3,
  reducers: {
    setBlockDataContact3: (state, action: PayloadAction<ContactBlock>) => {
      state.title = action.payload.title;
      state.flipped = action.payload.flipped;
      state.data = {...action.payload.data};
      state.style = action.payload.style;
    },
    setTitleContact3: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setFlippedContact3: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setAddressContact3: (state, action: PayloadAction<string[]>) => {
      state.data.address = action.payload;
    },
    setEmailsContact3: (state, action: PayloadAction<string[]>) => {
      state.data.emails = action.payload;
    },
    setPhnoContact3: (state, action: PayloadAction<string>) => {
      state.data.phno = action.payload;
    },
    setStyleContact3: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataContact3,
  setTitleContact3,
  setFlippedContact3,
  setAddressContact3,
  setEmailsContact3,
  setPhnoContact3,
  setStyleContact3,
} = Contact3Slice.actions;

export default Contact3Slice.reducer;
