import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";

import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { bulletPoints, Others } from "../../../../interfaces/Others";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";

const dummyOthers = {
  title: "Others Title",
  data: [
    "Lorem ipsum dolor sit amet.",
    "Consectetur adipisicing",
    "Dolorum earum officiis.",
    "distinctio ipsa officia soluta.",
    "accusantium ationem.",
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

const isEmptyOthers = (others: Others) => {
  if (others.data.join("") === "" && others.title === "") return true;
  return false;
};

interface OthersBlockProps {
  item: GridItem;
}

export const OthersBlock1: React.FC<OthersBlockProps> = (props) => {
  const others1 = useAppSelector(state=>state.others1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Others
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyOthers(others1));
  }, [others1]);

  const toBeShownOthers = isEmpty ? dummyOthers : others1;

  return (
    <div
      style={{
        backgroundColor: toBeShownOthers.style.bgColor,
        color: toBeShownOthers.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle title={toBeShownOthers.title} formStyles={formStyles} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Others #1"} />
        <div style={{ paddingLeft: 8, fontSize: 15, opacity: isEmpty ? 0.5 : 1, marginTop: "8px" }}>
          {toBeShownOthers.data.map((eachLine: string, index: number) => {
            return (
              <div
                key={eachLine + index}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: 8,
                }}
              >
                <div>{bulletPoints[others1.bullet]}&nbsp;&nbsp;</div>
                <div style={{ fontWeight: 500 }}>{eachLine}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
