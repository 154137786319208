import React, { FC, useState } from "react";
import "./SelectComponent.css";
import { BulletPoint } from "../../interfaces/Others";
import { FontFamily } from "../../interfaces/FormStyles";

interface SelectComponentProps<T extends BulletPoint | FontFamily> {
  options: T[];
  value: T;
  optionsIcons?: { [key: string]: string };
  onSelect: (option: T) => void;
}

export const SelectComponent = <T extends BulletPoint | FontFamily>(props: SelectComponentProps<T>) => {
  const [selectedOption, setSelectedOption] = useState(props.value);
  
  const [selectedOptionIcon, setSelectedOptionIcon] = useState(props.optionsIcons ? props.optionsIcons[props.value] : "");
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: T, icon?: string) => {
    setSelectedOption(option);
    setSelectedOptionIcon(icon || "");
    props.onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="select-container">
      <div className={`select-box ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(!isOpen)}>
        <div className="select-label">
          <span style={{ fontFamily: `${selectedOption}` }}>{selectedOptionIcon}</span>
          &nbsp;&nbsp;{selectedOption || "Select an option"}
        </div>
        <div className="select-arrow">&#9662;</div>
      </div>
      <div className={`options-list ${isOpen ? "open" : ""}`}>
        {props.options.map((option, index) => (
          <div key={index} className="option" onClick={() => handleOptionClick(option, props.optionsIcons![option])}>
            <span style={{ fontFamily: `${option}` }}>{props.optionsIcons![option]}</span>
            &nbsp;&nbsp;{option}
          </div>
        ))}
      </div>
    </div>
  );
};
