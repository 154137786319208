import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BulletPoint, Others } from "../../../interfaces/Others";

export const initialStateOthers1: Others = {
  title: "",
  bullet: "dot",
  data: [""],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const others1Slice = createSlice({
  name: "others1",
  initialState: initialStateOthers1,
  reducers: {
    setBlockDataOthers1: (state, action: PayloadAction<Others>) => {
      state.title = action.payload.title;
      state.bullet = action.payload.bullet;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setTitleOthers1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBulletOthers1: (state, action: PayloadAction<BulletPoint>) => {
      state.bullet = action.payload;
    },
    setDataOthers1: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
    setStyleOthers1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBlockDataOthers1, setTitleOthers1, setBulletOthers1, setDataOthers1, setStyleOthers1 } =
  others1Slice.actions;

export default others1Slice.reducer;
