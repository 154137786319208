import "./_FormsStyles.css";
import "./_CommonComponents.css";

import React, { FC, useState, useEffect } from "react";
import { CollapseableForm } from "./_CommonComponents";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  setAlignName1,
  setFontSizeName1,
  setNameName1,
  setProfessionName1,
  setStyleName1,
} from "../../../../features/elements/name/name1Slice";
import { TextInput } from "../../../../Components/Inputs/TextInput";
import { SliderComponent } from "../../../../Components/Inputs/SliderComponent";
import { ButtonGroupComponent3 } from "../../../../Components/Inputs/ButtonGroupComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface NameFormProps {}

export const Name1Form: FC<NameFormProps> = React.memo((props) => {
  const name1 = useAppSelector((state) => state.name1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const [fontSizeSliderValue, setFontSizeSliderValue] = useState(name1.fontSize);

  // ==================================================================================================================
  // Handle Text/Slider Inputs
  const handleName = (name: string) => {
    dispatch(setNameName1(name));
  };
  const handleProfession = (profession: string) => {
    dispatch(setProfessionName1(profession));
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleFontSizeSlider = (event: Event | React.ChangeEvent<HTMLInputElement>, newFontSize: number | number[]) => {
    const fontSize = Array.isArray(newFontSize) ? newFontSize[0] : newFontSize;
    setFontSizeSliderValue(fontSize);
  };
  // Change the value after user has done sliding the font size slider
  useEffect(() => {
    const lastRequest = setTimeout(() => dispatch(setFontSizeName1(fontSizeSliderValue)), 400);
    return () => clearTimeout(lastRequest);
  }, [fontSizeSliderValue]);
  const handleAlignName = (align: "flex-start" | "center" | "flex-end", direction: "row" | "row-reverse") => {
    dispatch(setAlignName1({ align: align, direction: direction }));
  };
  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleName1({ ...name1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleName1({ ...name1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Name #1</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput label="Full Name" value={name1.name} onChange={(e) => handleName(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <TextInput label="Profession" value={name1.profession} onChange={(e) => handleProfession(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={8}>
          <SliderComponent
            value={fontSizeSliderValue}
            handleSliderChange={handleFontSizeSlider}
            min={20}
            max={40}
            step={2}
            label={"Font Size"}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "24px" }}>
            <ButtonGroupComponent3 align={name1.align} direction={name1.direction} handleAlign={handleAlignName} />
          </div>
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={name1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={name1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
