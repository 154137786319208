import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Educations } from "../../../interfaces/Educations";

export const initialStateEducations2: Educations = {
  title: "",
  data: [{ id: `education${Date.now()}`, courseName: "", courseResults: "", organizationName: "", courseDuration: "" }],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const Educations2Slice = createSlice({
  name: "educations2",
  initialState: initialStateEducations2,
  reducers: {
    setBlockDataEducations2: (state, action: PayloadAction<Educations>) => {
      state.title = action.payload.title;
      state.data = action.payload.data.map(item => ({
        ...item,
      }));
      state.style = action.payload.style;
    },
    setTitleEducations2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setCourseNameEducations2: (state, action: PayloadAction<{ courseName: string; courseId: string }>) => {
      const { courseName, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseName = courseName;
    },
    setCourseResultsEducations2: (state, action: PayloadAction<{ courseResults: string; courseId: string }>) => {
      const { courseResults, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseResults = courseResults;
    },
    setOrganizationNameEducations2: (
      state,
      action: PayloadAction<{ organizationName: string; courseId: string }>
    ) => {
      const { organizationName, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].organizationName = organizationName;
    },
    setCourseDurationEducations2: (state, action: PayloadAction<{ courseDuration: string; courseId: string }>) => {
      const { courseDuration, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseDuration = courseDuration;
    },
    addEducationEducations2: (state) => {
      state.data.push({
        id: `education${Date.now()}`,
        courseName: "",
        courseResults: "",
        organizationName: "",
        courseDuration: "",
      });
    },
    removeEducationEducations2: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((education) => education.id !== action.payload);
    },
    setStyleEducations2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataEducations2,
  setTitleEducations2,
  setCourseNameEducations2,
  setCourseResultsEducations2,
  setOrganizationNameEducations2,
  setCourseDurationEducations2,
  addEducationEducations2,
  removeEducationEducations2,
  setStyleEducations2,
} = Educations2Slice.actions;

export default Educations2Slice.reducer;
