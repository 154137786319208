// Required by react-grid-layout to function properly
import "./MiddleGrid.css";

import React from "react";

import RGL, { WidthProvider } from "react-grid-layout";

import { GridItem } from "../../../interfaces/GridItem";
import { ProjectsBlock1, ProjectsBlock2 } from "./GridItems/ProjectsBlocks";
import { AboutBlock1 } from "./GridItems/AboutBlocks";
import { ContactBlock1, ContactBlock2, ContactBlock3 } from "./GridItems/ContactBlocks";
import { RatingsBlock1, RatingsBlock2, RatingsBlock3, RatingsBlock4 } from "./GridItems/RatingsBlocks";
import { SkillsBlock1, SkillsBlock2 } from "./GridItems/SkillsBlocks";
import { EducationsBlock1, EducationsBlock2, EducationsBlock3 } from "./GridItems/EducationsBlocks";
import { AboutWithContactBlock1, AboutWithContactBlock2 } from "./GridItems/AboutWithContactBlocks";
import { WorksBlock1, WorksBlock2 } from "./GridItems/WorksBlocks";
import { OthersBlock1 } from "./GridItems/OthersBlocks";
import { SpacerBlock1, SpacerBlock2, SpacerBlock3 } from "./GridItems/SpacerBlocks";
import { NameBlock1 } from "./GridItems/NameBlocks";
import { CertificationsBlock1 } from "./GridItems/CertificationBlocks";
import { PhotoBlock1Memo } from "./GridItems/PhotoBlocks";
import { useAppSelector } from "../../../app/hooks";
// import ResponsiveReactGridLayout from "react-grid-layout";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);

interface MiddleGridProps {
  onLayoutChange: any;
}

const MiddleGrid: React.FC<MiddleGridProps> = (props) => {
  const gridItems = useAppSelector((state) => state.gridItems);

  // GO TO THE RELATED FORM IN RIGHT-FORM ON CLICKING IN THE MIDDLE-GIRD BLOCKS
  const handleNavigateToBlock = (itemBlock: GridItem) => {
    try {
      document.getElementById(itemBlock.name)!.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error(error);
    }
  };

  function getItemBlock(item: GridItem): React.ReactNode {
    switch (item.name) {
      case "aboutwithcontact1":
        return <AboutWithContactBlock1 item={item} />;
      case "aboutwithcontact2":
        return <AboutWithContactBlock2 item={item} />;
      case "photo1":
        return <PhotoBlock1Memo item={item} />;
      case "about1":
        return <AboutBlock1 item={item} />;
      case "name1":
        return <NameBlock1 item={item} />;
      case "contact1":
        return <ContactBlock1 item={item} />;
      case "contact2":
        return <ContactBlock2 item={item} />;
      case "contact3":
        return <ContactBlock3 item={item} />;
      case "educations1":
        return <EducationsBlock1 item={item} />;
      case "educations2":
        return <EducationsBlock2 item={item} />;
      case "educations3":
        return <EducationsBlock3 item={item} />;
      case "skills1":
        return <SkillsBlock1 item={item} />;
      case "skills2":
        return <SkillsBlock2 item={item} />;
      case "works1":
        return <WorksBlock1 item={item} />;
      case "works2":
        return <WorksBlock2 item={item} />;
      case "projects1":
        return <ProjectsBlock1 item={item} />;
      case "projects2":
        return <ProjectsBlock2 item={item} />;

      case "ratings1":
        return <RatingsBlock1 item={item} />;
      case "ratings2":
        return <RatingsBlock2 item={item} />;
      case "ratings3":
        return <RatingsBlock3 item={item} />;
      case "ratings4":
        return <RatingsBlock4 item={item} />;

      case "certifications1":
        return <CertificationsBlock1 item={item} />;
      case "others1":
        return <OthersBlock1 item={item} />;

      case "spacer1":
        return <SpacerBlock1 item={item} />;
      case "spacer2":
        return <SpacerBlock2 item={item} />;
      case "spacer3":
        return <SpacerBlock3 item={item} />;
      default:
        return <>elementName {item.name} does not exist in getItemBlock() in MiddleGrid.tsx`</>;
    }
  }

  return (
    <>
      <div id="gridLayoutWrapper">
        <ReactGridLayout
          className="layout"
          onLayoutChange={props.onLayoutChange}
          autoSize={false}
          rowHeight={10}
          cols={12}
          // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          // cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        >
          {gridItems.items.map((item: GridItem, index: number) => {
            return (
              <div
                className="gridBlocks"
                key={item.name + index}
                data-grid={{ x: item.x, y: item.y, w: item.w, h: item.h }}
                onClick={() => {
                  handleNavigateToBlock(item);
                }}
              >
                {getItemBlock(item)}
              </div>
            );
          })}
        </ReactGridLayout>
      </div>
      <div className="suggestiveEnd" id="suggestiveEnd1">&nbsp;</div>
      {/* <div className="suggestiveEnd" id="suggestiveEnd2">&nbsp;</div> */}
      {/* <div style={{ paddingTop: "4rem" }}>&nbsp;</div> */}
    </>
  );
};

export default MiddleGrid;
