import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GridItem } from "../../interfaces/GridItem";
import { log } from "../../helpers/logger";
import { AboutWithContact } from "../../interfaces/AboutWithContact";
import { About } from "../../interfaces/About";
import { Certifications } from "../../interfaces/Certification";
import { Contact } from "../../interfaces/Contact";
import { Educations } from "../../interfaces/Educations";
import { Name } from "../../interfaces/Name";
import { Others } from "../../interfaces/Others";
import { Photo } from "../../interfaces/Photo";
import { Projects } from "../../interfaces/Projects";
import { Ratings } from "../../interfaces/Ratings";
import { Skills } from "../../interfaces/Skills";
import { Works } from "../../interfaces/Works";
import { _isProd } from "../../constants";

export const initialStateGridItems: { items: GridItem[]; forms: string[] } = {
  items: [],
  forms: [],
};

export const gridItemsSlice = createSlice({
  name: "gridItems",
  initialState: initialStateGridItems,
  reducers: {
    addGridItem(
      state,
      action: PayloadAction<{
        name: string;
        x: number;
        y: number;
        width: number;
        height: number;
        data:
          | About
          | AboutWithContact
          | Certifications
          | Contact
          | Educations
          | Name
          | Others
          | Photo
          | Projects
          | Ratings
          | Skills
          | Works
          | {};
        isResizable: boolean;
      }>
    ) {
      const item = action.payload;
      /** add to items (first check if already exists, if not then add, else return) */
      for (let i = 0; i < state.items.length; i++) {
        const element = state.items[i];
        if (element.name === item.name) {
          console.log(element.name, item.name, "this item already exists");
          item.name = "";
          return;
        }
      }
      state.items.push({
        name: item.name,
        // i: item.name,
        x: item.x,
        y: item.y,
        w: item.width,
        h: item.height,
        // isResizable: item.isResizable,
        data: item.data,
      });
      console.log("old", state.items);

      /** add to forms (first check if already exists, if not then add, else return) */
      const newFormsArray = state.forms;
      const newFormName = item.name;
      // If the item already exist then dont add to form
      if (newFormsArray.includes(newFormName)) {
        if(!_isProd) console.log("Form Item already exists", newFormName, state.forms);
        return;
      }
      // If the item is an spacer then dont add to form
      if (newFormName === "spacer1" || newFormName === "spacer2" || newFormName === "spacer3") {
       if(!_isProd) console.log("Form Item is spacer", newFormName, state.forms);
        return;
      }
      // Add item to form and update [forms] state.
      newFormsArray.push(newFormName);
      state.forms = newFormsArray;

      // saveToLS()
    },

    // removeItem(state, action: PayloadAction<GridItem>) {
    //   const toBeRemovedItem = action.payload;
    //  if(!_isProd) console.log("removing", toBeRemovedItem);
    //   for (let i = 0; i < state.items.length; i++) {
    //     if (state.items[i].i === toBeRemovedItem.i) {
    //       const newItems = state.items.filter(function (el) {
    //         return el != toBeRemovedItem;
    //       });
    //       // setItems(newItems);
    //       state.items = newItems;

    //       // remove From Forms Array
    //       const newItemsNameArray = newItems.map((item) => item.i);
    //       if (newItemsNameArray.includes(toBeRemovedItem.i)) {
    //        if(!_isProd) console.log("Another item with same form exists", newItemsNameArray);
    //       } else {
    //        if(!_isProd) console.log("Removing Form", toBeRemovedItem.i);
    //         // remove from form array where matches the `toBeRemovedItemName`
    //         state.forms = state.forms.filter((formItem) => formItem != toBeRemovedItem.i);
    //       }
    //     }
    //   }
    // },
    removeGridItem: (state, action: PayloadAction<GridItem>) => {
      const toBeRemovedItem = action.payload;
      console.log("removing", toBeRemovedItem);

      const index = state.items.findIndex((item) => item.name === toBeRemovedItem.name);
      if (index !== -1) {
        // Remove the item directly from the array
        state.items.splice(index, 1);

        // Check if there are any other items with the same 'i' value
        const itemStillExists = state.items.some((item) => item.name === toBeRemovedItem.name);

        if (!itemStillExists) {
          console.log("Removing Form", toBeRemovedItem.name);
          // Remove from form array
          const formIndex = state.forms.indexOf(toBeRemovedItem.name);
          if (formIndex !== -1) {
            state.forms.splice(formIndex, 1);
          }
        } else {
          console.log(
            "Another item with same form exists",
            state.items.map((item) => item.name)
          );
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { addGridItem, removeGridItem } = gridItemsSlice.actions;

export default gridItemsSlice.reducer;

const getTruncatedElementName = (elementI: string): string => {
  if (elementI.startsWith("aboutwithcontact1")) return "aboutwithcontact1";
  if (elementI.startsWith("aboutwithcontact2")) return "aboutwithcontact2";
  if (elementI.startsWith("photo1")) return "photo1";
  if (elementI.startsWith("about1")) return "about1";
  if (elementI.startsWith("name1")) return "name1";
  if (elementI.startsWith("contact1")) return "contact1";
  if (elementI.startsWith("contact2")) return "contact2";
  if (elementI.startsWith("contact3")) return "contact3";
  if (elementI.startsWith("educations1")) return "educations1";
  if (elementI.startsWith("educations2")) return "educations2";
  if (elementI.startsWith("educations3")) return "educations3";
  if (elementI.startsWith("skills1")) return "skills1";
  if (elementI.startsWith("skills2")) return "skills2";
  if (elementI.startsWith("works1")) return "works1";
  if (elementI.startsWith("works2")) return "works2";
  if (elementI.startsWith("projects1")) return "projects1";
  if (elementI.startsWith("projects2")) return "projects2";
  if (elementI.startsWith("ratings1")) return "ratings1";
  if (elementI.startsWith("ratings2")) return "ratings2";
  if (elementI.startsWith("certifications1")) return "certifications1";
  if (elementI.startsWith("others1")) return "others1";
  if (elementI.startsWith("spacer1")) return "spacer1";
  if (elementI.startsWith("spacer2")) return "spacer2";
  if (elementI.startsWith("spacer3")) return "spacer3";
  return elementI;

  // const newElementName = elName.substring(0, elName.indexOf("function") - 1);
  // const newElementName = elementI.substring(0, elementI.length - 1);
  // return newElementName;
};
