import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { About } from "../../../interfaces/About";

export const initialStateAbout1: About = {
  name: "",
  profession: "",
  about: "",
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const about1Slice = createSlice({
  name: "about1",
  initialState: initialStateAbout1,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setBlockDataAbout1: (state, action: PayloadAction<About>) => {
      state.name = action.payload.name;
      state.profession = action.payload.profession;
      state.about = action.payload.about;
      // Ensure proper handling for the nested style object
      state.style = {...action.payload.style};
    },
    setNameAbout1: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setAboutAbout1: (state, action: PayloadAction<string>) => {
      state.about = action.payload;
    },
    setProfessionAbout1: (state, action: PayloadAction<string>) => {
      state.profession = action.payload;
    },
    setStyleAbout1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { setBlockDataAbout1, setNameAbout1, setAboutAbout1, setProfessionAbout1, setStyleAbout1 } = about1Slice.actions;

export default about1Slice.reducer;
