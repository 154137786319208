import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Certificate, Certifications } from "../../../interfaces/Certification";
import { ContactBlock } from "../../../interfaces/Contact";

export const initialStateContactBlock2: ContactBlock = {
  title: "",
  flipped: true,
  data: { address: [""], emails: [""], phno: "" },
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const Contact2Slice = createSlice({
  name: "contact2",
  initialState: initialStateContactBlock2,
  reducers: {
    setBlockDataContact2: (state, action: PayloadAction<ContactBlock>) => {
      state.title = action.payload.title;
      state.flipped = action.payload.flipped;
      state.data = {...action.payload.data};
      state.style = action.payload.style;
    },
    setTitleContact2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setFlippedContact2: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setAddressContact2: (state, action: PayloadAction<string[]>) => {
      state.data.address = action.payload;
    },
    setEmailsContact2: (state, action: PayloadAction<string[]>) => {
      state.data.emails = action.payload;
    },
    setPhnoContact2: (state, action: PayloadAction<string>) => {
      state.data.phno = action.payload;
    },
    setStyleContact2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataContact2,
  setTitleContact2,
  setFlippedContact2,
  setAddressContact2,
  setEmailsContact2,
  setPhnoContact2,
  setStyleContact2,
} = Contact2Slice.actions;

export default Contact2Slice.reducer;
