import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  themeMode: "light" | "dark";
  // Add any other theme-related state properties here
}

const initialState: ThemeState = {
  themeMode: localStorage.getItem("resumezTheme") === "dark" ? "dark" : "light",
  // Initialize other theme-related state properties here
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeMode: (state, action: PayloadAction<"light" | "dark">) => {
      state.themeMode = action.payload;
      localStorage.setItem("resumezTheme", action.payload);
    },
  },
});

export const { setThemeMode } = themeSlice.actions;

export default themeSlice.reducer;
