import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AboutWithContact } from "../../../interfaces/AboutWithContact";

export const initialStateAboutWithContact1: AboutWithContact = {
  name: "",
  profession: "",
  address: [""],
  cityZip: "",
  phno: "",
  emails: [""],
  about: "",
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const aboutWithContact1Slice = createSlice({
  name: "aboutwithcontact1",
  initialState: initialStateAboutWithContact1,
  reducers: {
    setAllDataAboutWithContact1: (state, action: PayloadAction<AboutWithContact>) => {
      state.name = action.payload.name;
      state.profession = action.payload.profession;
      state.address = action.payload.address;
      state.cityZip = action.payload.cityZip;
      state.phno = action.payload.phno;
      state.emails = action.payload.emails;
      state.about = action.payload.about;
      state.style = {...action.payload.style};
    },
    setNameAboutWithContact1: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setProfessionAboutWithContact1: (state, action: PayloadAction<string>) => {
      state.profession = action.payload;
    },
    setAddressAboutWithContact1: (state, action: PayloadAction<string[]>) => {
      state.address = action.payload;
    },
    setCityZipAboutWithContact1: (state, action: PayloadAction<string>) => {
      state.cityZip = action.payload;
    },
    setPhNoAboutWithContact1: (state, action: PayloadAction<string>) => {
      state.phno = action.payload;
    },
    setEmailsAboutWithContact1: (state, action: PayloadAction<string[]>) => {
      state.emails = action.payload;
    },
    setAboutAboutWithContact1: (state, action: PayloadAction<string>) => {
      state.about = action.payload;
    },
    setStyleAboutWithContact1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllDataAboutWithContact1,
  setNameAboutWithContact1,
  setAddressAboutWithContact1,
  setCityZipAboutWithContact1,
  setPhNoAboutWithContact1,
  setEmailsAboutWithContact1,
  setAboutAboutWithContact1,
  setProfessionAboutWithContact1,
  setStyleAboutWithContact1,
} = aboutWithContact1Slice.actions;

export default aboutWithContact1Slice.reducer;
