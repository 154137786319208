import React from "react";
import { EmailIcon, GithubIcon, LinkedInIcon } from "../static/Icons/SocialMediaIcons";
import { GlobeNetIcon, HouseIcon, PhoneIcon } from "../static/Icons/Icons";

export const getIcon = ({ name, color, style }: { name: string; color: string; style: React.CSSProperties }) => {
  const iconStyles = { fontSize: 20, style, color: color || "currentColor" };

  switch (name.toLowerCase()) {
    case "github":
      return <GithubIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    case "linkedin":
      return <LinkedInIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    case "email":
      return <EmailIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    case "address":
      return <HouseIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    case "phone":
      return <PhoneIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    case "home":
      return <HouseIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
    default:
      return <GlobeNetIcon size={iconStyles.fontSize} style={iconStyles.style} color={iconStyles.color} />;
  }
};
