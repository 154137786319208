import React, { FC, useState } from 'react';
import './DialogComponent.css';

interface DialogComponentProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const DialogComponent:FC<DialogComponentProps> = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <div className="dialog-backdrop" onClick={onClose}>
      <div className="dialog-container" onClick={(e) => e.stopPropagation()}>
        {/* <button className="dialog-close-button" onClick={onClose}>Close</button> */}
        {children}
      </div>
    </div>
  );
};

export default DialogComponent;