import "./_FormsStyles.css";

import React, { FC, useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { CollapseableForm, RemoveFieldsButton } from "./_CommonComponents";

import {
  addRatingRatings1,
  removeRatingRatings1,
  setBlockTitleRatings1,
  setDataRateInPercentageRatings1,
  setDataRatingSubjectRatings1,
  setFlippedRatings1,
  setRatingIconRatings1,
  setStyleRatings1,
} from "../../../../features/elements/ratings/ratings1Slice";
import {
  addRatingRatings2,
  removeRatingRatings2,
  setFlippedRatings2,
  setBlockTitleRatings2,
  setRatingIconRatings2,
  setDataRatingSubjectRatings2,
  setDataRateInPercentageRatings2,
  setStyleRatings2,
} from "../../../../features/elements/ratings/ratings2Slice";
import { TextInput } from "../../../../Components/Inputs/TextInput";
import { ButtonGroupComponent2 } from "../../../../Components/Inputs/ButtonGroupComponent";
import { RadioButtonGroupComponent } from "../../../../Components/Inputs/RadioButtonGroupComponent";
import { StarFilledComponentIcon, StarOutlinedComponentIcon } from "../../../../static/Icons/ComponentIcons";
import { ButtonComponent } from "../../../../Components/Inputs/ButtonComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";
import { RatingComponent } from "../../../../Components/Inputs/RatingInput";
import {
  setBlockTitleRatings3,
  setBarWidthRatings3,
  setBarRadiusRatings3,
  setFlippedRatings3,
  setDataRatingSubjectRatings3,
  setDataRateInPercentageRatings3,
  addRatingRatings3,
  removeRatingRatings3,
  setStyleRatings3,
} from "../../../../features/elements/ratings/ratings3Slice";
import { SliderComponent } from "../../../../Components/Inputs/SliderComponent";
import { _isProd } from "../../../../constants";
import { addNewRatingRatings4, removeRatingRatings4, setBarRadiusRatings4, setBarWidthRatings4, setBlockTitleRatings4, setPercentageInRatings4, setRatingSubjectRatings4, setFlippedRatings4, setStyleRatings4 } from "../../../../features/elements/ratings/ratings4Slice";

const starStyles = {
  fontSize: 32,
  margin: "0px 4px",
  color: "var(--color-primary)",
};

interface RatingsFormProps {}
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS FORM #1
export const Ratings1Form: FC<RatingsFormProps> = React.memo(() => {
  const ratings1 = useAppSelector((state) => state.ratings1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addRatingRatings1());
  const handleRemoveFields = (ratingId: string) => dispatch(removeRatingRatings1(ratingId));

  // ==================================================================================================================
  // Handle Inputs
  const handleFlip = (flipped: boolean) => dispatch(setFlippedRatings1(flipped));
  const handleBlockTitleInput = (title: string) => dispatch(setBlockTitleRatings1(title));
  const handleRatingIconChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setRatingIconRatings1(event.target.value as "star" | "circle" | "square"));
  const handleRatingSubjectInput = (subjectName: string, ratingId: string) =>
    dispatch(setDataRatingSubjectRatings1({ ratingSubject: subjectName, ratingId: ratingId }));
  const handleRatingStarInput = (ratingPercentage: number, ratingId: string) =>
    dispatch(setDataRateInPercentageRatings1({ rateInPercentage: ratingPercentage * 20, ratingId: ratingId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleRatings1({ ...ratings1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleRatings1({ ...ratings1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Ratings #1</h3>
      <GridContainer>
        <GridItem xs={12} md={5}>
          <TextInput label="Title" value={ratings1.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <RadioButtonGroupComponent
            selectedIcon={ratings1.icon}
            handleSelectedIcon={handleRatingIconChange}
            name="ratings1Radio"
          />
        </GridItem>
        <GridItem
          xs={12}
          md={2}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <ButtonGroupComponent2 flipped={ratings1.flipped} handleFlip={handleFlip} />
        </GridItem>
      </GridContainer>
      {ratings1.data.map((singleRating, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Subject"
                    value={singleRating.ratingSubject}
                    onChange={(e) => handleRatingSubjectInput(e.target.value, singleRating.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6} style={{ margin: "12px 0px" }}>
                  <div style={{ marginLeft: 36 }}>
                    <RatingComponent
                      name="rating1-controlled"
                      value={Math.round(singleRating.rateInPercentage / 20)}
                      icon={<StarFilledComponentIcon />}
                      emptyIcon={<StarOutlinedComponentIcon />}
                      onChange={(e, value) => handleRatingStarInput(value, singleRating.id)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleRating.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={ratings1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={ratings1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS FORM #2
export const Ratings2Form: FC<RatingsFormProps> = React.memo(() => {
  const ratings2 = useAppSelector((state) => state.ratings2);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addRatingRatings2());
  const handleRemoveFields = (ratingId: string) => dispatch(removeRatingRatings2(ratingId));

  // ==================================================================================================================
  // Handle Inputs
  const handleFlip = (flipped: boolean) => dispatch(setFlippedRatings2(flipped));
  const handleBlockTitleInput = (title: string) => dispatch(setBlockTitleRatings2(title));
  const handleRatingIconChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setRatingIconRatings2(event.target.value as "star" | "circle" | "square"));
  const handleRatingSubjectInput = (subjectName: string, ratingId: string) =>
    dispatch(setDataRatingSubjectRatings2({ ratingSubject: subjectName, ratingId: ratingId }));
  const handleRatingStarInput = (ratingPercentage: number, ratingId: string) =>
    dispatch(setDataRateInPercentageRatings2({ rateInPercentage: ratingPercentage * 20, ratingId: ratingId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleRatings2({ ...ratings2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleRatings2({ ...ratings2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Ratings #2</h3>
      <GridContainer>
        <GridItem xs={12} md={5}>
          <TextInput label="Title" value={ratings2.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={5} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <RadioButtonGroupComponent
            selectedIcon={ratings2.icon}
            handleSelectedIcon={handleRatingIconChange}
            name="ratings2Radio"
          />
        </GridItem>
        <GridItem
          xs={12}
          md={2}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <ButtonGroupComponent2 flipped={ratings2.flipped} handleFlip={handleFlip} />
        </GridItem>
      </GridContainer>
      {ratings2.data.map((singleRating, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Subject"
                    value={singleRating.ratingSubject}
                    onChange={(e) => handleRatingSubjectInput(e.target.value, singleRating.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6} style={{ margin: "12px 0px" }}>
                  <div style={{ marginLeft: 36 }}>
                    <RatingComponent
                      name="rating2-controlled"
                      value={Math.round(singleRating.rateInPercentage / 20)}
                      icon={<StarFilledComponentIcon />}
                      emptyIcon={<StarOutlinedComponentIcon />}
                      onChange={(e, value) => handleRatingStarInput(value, singleRating.id)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleRating.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={ratings2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={ratings2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS FORM #3
export const Ratings3Form: FC<RatingsFormProps> = React.memo(() => {
  const ratings3 = useAppSelector((state) => state.ratings3);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addRatingRatings3());
  const handleRemoveFields = (ratingId: string) => dispatch(removeRatingRatings3(ratingId));

  // const [ratingSliderValue, setRatingSliderValue] = useState<number>(ratings3.data);
  // useEffect(() => {
  //   const lastRequest = setTimeout(() => dispatch(setDataRateInPercentageRatings3({ rateInPercentage: ratingSliderValue, ratingId: ratingId })), 400);
  //   return () => clearTimeout(lastRequest);
  // }, [ratingSliderValue]);

  // ==================================================================================================================
  // Handle Inputs
  const handleFlip = (flipped: boolean) => dispatch(setFlippedRatings3(flipped));
  const handleBlockTitleInput = (title: string) => dispatch(setBlockTitleRatings3(title));
  const handleBarWidthChange = (_: React.ChangeEvent<HTMLInputElement>, ratingPercentage: number | number[]) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setBarWidthRatings3(sliderValue));
  };
  const handleBarRadiusChange = (_: React.ChangeEvent<HTMLInputElement>, ratingPercentage: number | number[]) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setBarRadiusRatings3(sliderValue));
  };
  const handleRatingSubjectInput = (subjectName: string, ratingId: string) =>
    dispatch(setDataRatingSubjectRatings3({ ratingSubject: subjectName, ratingId: ratingId }));
  const handleRatingRateInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    ratingPercentage: number | number[],
    ratingId: string
  ) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setDataRateInPercentageRatings3({ rateInPercentage: sliderValue, ratingId: ratingId }));
  };

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleRatings3({ ...ratings3.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleRatings3({ ...ratings3.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Ratings #3</h3>
      <GridContainer>
        <GridItem xs={12} md={8}>
          <TextInput label="Title" value={ratings3.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem
          xs={12}
          md={4}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <ButtonGroupComponent2 flipped={ratings3.flipped} handleFlip={handleFlip} />
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <SliderComponent
            handleSliderChange={handleBarWidthChange}
            value={ratings3.barWidth}
            min={4}
            max={28}
            step={2}
            label={"Bar Width"}
          />
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <SliderComponent
            handleSliderChange={handleBarRadiusChange}
            value={ratings3.barRadius}
            min={0}
            max={20}
            step={2}
            label={"Bar Corner Radius"}
          />
        </GridItem>
      </GridContainer>
      {ratings3.data.map((singleRating, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Subject"
                    value={singleRating.ratingSubject}
                    onChange={(e) => handleRatingSubjectInput(e.target.value, singleRating.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <div style={{ marginLeft: 36 }}>
                    <SliderComponent
                      handleSliderChange={(e, value) => handleRatingRateInput(e, value, singleRating.id)}
                      value={singleRating.rateInPercentage}
                      min={0}
                      max={100}
                      step={5}
                      label={""}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleRating.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={ratings3.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={ratings3.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// ============================================================================
// RATINGS FORM #4
export const Ratings4Form: FC<RatingsFormProps> = React.memo(() => {
  const ratings4 = useAppSelector((state) => state.ratings4);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addNewRatingRatings4());
  const handleRemoveFields = (ratingId: string) => dispatch(removeRatingRatings4(ratingId));

  // ==================================================================================================================
  // Handle Inputs
  const handleFlip = (flipped: boolean) => dispatch(setFlippedRatings4(flipped));
  const handleBlockTitleInput = (title: string) => dispatch(setBlockTitleRatings4(title));
  const handleBarWidthChange = (_: React.ChangeEvent<HTMLInputElement>, ratingPercentage: number | number[]) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setBarWidthRatings4(sliderValue));
  };
  const handleBarRadiusChange = (_: React.ChangeEvent<HTMLInputElement>, ratingPercentage: number | number[]) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setBarRadiusRatings4(sliderValue));
  };
  const handleRatingSubjectInput = (subjectName: string, ratingId: string) =>
    dispatch(setRatingSubjectRatings4({ ratingSubject: subjectName, ratingId: ratingId }));
  const handleRatingRateInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    ratingPercentage: number | number[],
    ratingId: string
  ) => {
    const sliderValue = Array.isArray(ratingPercentage) ? ratingPercentage[0] : ratingPercentage;
    dispatch(setPercentageInRatings4({ rateInPercentage: sliderValue, ratingId: ratingId }));
  };

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleRatings4({ ...ratings4.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleRatings4({ ...ratings4.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Ratings #4</h3>
      <GridContainer>
        <GridItem xs={12} md={8}>
          <TextInput label="Title" value={ratings4.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem
          xs={12}
          md={4}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <ButtonGroupComponent2 flipped={ratings4.flipped} handleFlip={handleFlip} />
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <SliderComponent
            handleSliderChange={handleBarWidthChange}
            value={ratings4.barWidth}
            min={4}
            max={28}
            step={2}
            label={"Bar Width"}
          />
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px" }}
        >
          <SliderComponent
            handleSliderChange={handleBarRadiusChange}
            value={ratings4.barRadius}
            min={0}
            max={20}
            step={2}
            label={"Bar Corner Radius"}
          />
        </GridItem>
      </GridContainer>
      {ratings4.data.map((singleRating, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextInput
                    label="Subject"
                    value={singleRating.ratingSubject}
                    onChange={(e) => handleRatingSubjectInput(e.target.value, singleRating.id)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <div style={{ marginLeft: 36 }}>
                    <SliderComponent
                      handleSliderChange={(e, value) => handleRatingRateInput(e, value, singleRating.id)}
                      value={singleRating.rateInPercentage}
                      min={0}
                      max={100}
                      step={5}
                      label={""}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleRating.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={ratings4.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={ratings4.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
