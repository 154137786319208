import React from "react";

export const SpacersIcon1: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <polygon points="125,40 150,60 100,60" fill="#666" />
        <rect x={60} y={65} width={130} height={5} fill="#666" />
        <rect x={60} y={75} width={130} height={5} fill="#666" />
        <polygon points="125,105 150,85 100,85" fill="#666" />
      </svg>
    </div>
  );
};

export const SpacersIcon2: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <polygon points="125,40 150,60 100,60" fill="#666" />
        <rect x={60} y={65} width={130} height={5} fill="#666" />
        <rect x={60} y={75} width={130} height={5} fill="#666" />
        <polygon points="125,105 150,85 100,85" fill="#666" />
      </svg>
    </div>
  );
};

export const SpacersIcon3: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%", height: "100%" }}>
      <svg fill="none" viewBox="0 0 250 150">
        <polygon points="125,40 150,60 100,60" fill="#666" />
        <rect x={60} y={65} width={130} height={5} fill="#666" />
        <rect x={60} y={75} width={130} height={5} fill="#666" />
        <polygon points="125,105 150,85 100,85" fill="#666" />
      </svg>
    </div>
  );
};
