import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Works } from "../../../interfaces/Works";

export const initialStateWorks2: Works = {
  title: "",
  bullet: false,
  indent: true,
  data: [
    {
      id: `work${Date.now()}`,
      workOrganizationName: "",
      workLocation: "",
      jobTitle: "",
      workDetails: [""],
      workDuration: "",
    },
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const works2Slice = createSlice({
  name: "works2",
  initialState: initialStateWorks2,
  reducers: {
    setBlockDataWorks2: (state, action: PayloadAction<Works>) => {
      state.title = action.payload.title;
      state.bullet = action.payload.bullet;
      state.indent = action.payload.indent;
      state.data = action.payload.data.map((item) => ({
        ...item,
        workDetails: [...item.workDetails],
      }));
      state.style = action.payload.style;
    },
    setTitleWorks2: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBulletWorks2: (state, action: PayloadAction<boolean>) => {
      state.bullet = action.payload;
    },
    setIndentWorks2: (state, action: PayloadAction<boolean>) => {
      state.indent = action.payload;
    },
    setOrganizationNameByIdWorks2: (state, action: PayloadAction<{workOrganizationName: string, workId:string}>) => {
      const { workOrganizationName, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workOrganizationName = workOrganizationName;
    },
    setLocationByIdWorks2: (state, action: PayloadAction<{workLocation: string, workId:string}>) => {
      const { workLocation, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workLocation = workLocation;
    },
    setJobTitleByIdWorks2: (state, action: PayloadAction<{jobTitle: string, workId:string}>) => {
      const { jobTitle, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].jobTitle = jobTitle;
    },
    setWorkDetailsByIdWorks2: (state, action: PayloadAction<{workDetails: string[], workId:string}>) => {
      const { workDetails, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workDetails = workDetails;
    },
    setWorkDurationByIdWorks2: (state, action: PayloadAction<{workDuration: string, workId:string}>) => {
      const { workDuration, workId } = action.payload;
      const pos = state.data.findIndex((work) => work.id === workId);
      state.data[pos].workDuration = workDuration;
    },
    addWorkWorks2: (state) => {
      state.data.push({
        id: `work${Date.now()}`,
        workOrganizationName: "",
        workLocation: "",
        jobTitle: "",
        workDetails: [""],
        workDuration: "",
      });
    },
    // remove the work from array where work id is the same as when clicked "X" in UI
    removeWorkByIdWorks2: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((work) => work.id !== action.payload);
    },
    setStyleWorks2: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataWorks2,
  setTitleWorks2,
  setBulletWorks2,
  setIndentWorks2,
  setOrganizationNameByIdWorks2,
  setLocationByIdWorks2,
  setJobTitleByIdWorks2,
  setWorkDetailsByIdWorks2,
  setWorkDurationByIdWorks2,
  addWorkWorks2,
  removeWorkByIdWorks2,
  setStyleWorks2,
} = works2Slice.actions;

export default works2Slice.reducer;
