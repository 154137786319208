import "./_FormsStyles.css";

import React, { ChangeEvent, FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

import {
  setNameAboutWithContact1,
  setProfessionAboutWithContact1,
  setAboutAboutWithContact1,
  setAddressAboutWithContact1,
  setCityZipAboutWithContact1,
  setEmailsAboutWithContact1,
  setPhNoAboutWithContact1,
  setStyleAboutWithContact1,
} from "../../../../features/elements/aboutWithContact/aboutWithContact1Slice";
import {
  setNameAboutWithContact2,
  setProfessionAboutWithContact2,
  setAboutAboutWithContact2,
  setAddressAboutWithContact2,
  setCityZipAboutWithContact2,
  setEmailsAboutWithContact2,
  setPhNoAboutWithContact2,
  setStyleAboutWithContact2,
} from "../../../../features/elements/aboutWithContact/aboutWithContact2Slice";
import { CollapseableForm } from "./_CommonComponents";

import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface AboutWithContactFormProps {}

export const AboutWithContact1Form: FC<AboutWithContactFormProps> = React.memo(() => {
  const aboutWithContact1 = useAppSelector((state) => state.aboutWithContact1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => setExpanded(!expanded);
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleAboutWithContact1({ ...aboutWithContact1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleAboutWithContact1({ ...aboutWithContact1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">About & Contact #1</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput
            label="Full Name"
            value={aboutWithContact1.name}
            onChange={(e) => dispatch(setNameAboutWithContact1(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <TextInput
            label="Profession"
            value={aboutWithContact1.profession}
            onChange={(e) => dispatch(setProfessionAboutWithContact1(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <TextInput
            label="Emails (Separate each email with ',')"
            value={aboutWithContact1.emails.join(",")}
            onChange={(e) => dispatch(setEmailsAboutWithContact1(e.target.value.split(",")))}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <TextInput
            label="Phone Number"
            value={aboutWithContact1.phno}
            onChange={(e) => dispatch(setPhNoAboutWithContact1(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInput
            label="Address"
            value={aboutWithContact1.address.join(",")}
            onChange={(e) => dispatch(setAddressAboutWithContact1(e.target.value.split(",")))}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Pitch about yourself"
            value={aboutWithContact1.about}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => dispatch(setAboutAboutWithContact1(e.target.value))}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={aboutWithContact1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={aboutWithContact1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const AboutWithContact2Form: FC<AboutWithContactFormProps> = React.memo((props) => {
  const aboutWithContact2 = useAppSelector((state) => state.aboutWithContact2);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => setExpanded(!expanded);
  const handleBgColor = (newColor: string) =>
    dispatch(setStyleAboutWithContact2({ ...aboutWithContact2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleAboutWithContact2({ ...aboutWithContact2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">About & Contact #2</h3>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <TextInput
            label="Full Name"
            value={aboutWithContact2.name}
            onChange={(e) => dispatch(setNameAboutWithContact2(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <TextInput
            label="Profession"
            value={aboutWithContact2.profession}
            onChange={(e) => dispatch(setProfessionAboutWithContact2(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12} md={8}>
          <TextInput
            label="Emails (Separate each email with ',')"
            value={aboutWithContact2.emails.join(",")}
            onChange={(e) => dispatch(setEmailsAboutWithContact2(e.target.value.split(",")))}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <TextInput
            label="Phone Number"
            value={aboutWithContact2.phno}
            onChange={(e) => dispatch(setPhNoAboutWithContact2(e.target.value))}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInput
            label="Address"
            value={aboutWithContact2.address.join(",")}
            onChange={(e) => dispatch(setAddressAboutWithContact2(e.target.value.split(",")))}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Pitch about yourself"
            value={aboutWithContact2.about}
            onChange={(e) => dispatch(setAboutAboutWithContact2(e.target.value))}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={aboutWithContact2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={aboutWithContact2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
