import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Skills } from "../../../interfaces/Skills";

export const initialStateSkills1: Skills = {
  color: "#123456",
  title: "",
  chipRadius: 10,
  chipSize: 4,
  filled: true,
  flipped: false,
  data: [],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const skills1Slice = createSlice({
  name: "skills1",
  initialState: initialStateSkills1,
  reducers: {
    setBlockDataSkills1: (state, action: PayloadAction<Skills>) => {
      state.color = action.payload.color;
      state.title = action.payload.title;
      state.chipRadius = action.payload.chipRadius;
      state.chipSize = action.payload.chipSize;
      state.filled = action.payload.filled;
      state.flipped = action.payload.flipped;
      state.data = [...action.payload.data];
      state.style = action.payload.style;
    },
    setColorSkills1: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    setTitleSkills1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setChipRadiusSkills1: (state, action: PayloadAction<number>) => {
      state.chipRadius = action.payload;
    },
    setChipSizeSkills1: (state, action: PayloadAction<number>) => {
      state.chipSize = action.payload;
    },
    setFilledSkills1: (state, action: PayloadAction<boolean>) => {
      state.filled = action.payload;
    },
    setFlippedSkills1: (state, action: PayloadAction<boolean>) => {
      state.flipped = action.payload;
    },
    setDataSkills1: (state, action: PayloadAction<string[]>) => {
      state.data = action.payload;
    },
    setStyleSkills1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataSkills1,
  setColorSkills1,
  setTitleSkills1,
  setChipRadiusSkills1,
  setChipSizeSkills1,
  setFilledSkills1,
  setFlippedSkills1,
  setDataSkills1,
  setStyleSkills1,
} = skills1Slice.actions;

export default skills1Slice.reducer;
