import "./MenuComponent.css";

import React, { FC, useEffect, useState } from 'react';

interface MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Menu:FC<MenuProps> = ({ anchorEl, open, onClose, children }) => {
  const [menuStyles, setMenuStyles] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (open && anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      setMenuStyles({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, [open, anchorEl]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(e.target as Node) && !document.querySelector('.menuCustom')?.contains(e.target as Node)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, onClose]);

  if (!open) return null;

  return (
    <div className="menuCustom" style={menuStyles}>
      {children}
    </div>
  );
};

export default Menu;


interface MenuItemProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const MenuItem:FC<MenuItemProps> = ({ onClick, children }) => {
  return (
    <div className="menu-item" onClick={onClick}>
      {children}
    </div>
  );
};

export const Divider = () => {
  return <div className="menuDivider"></div>;
};