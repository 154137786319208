// These Icons are used in components such as ratings and have cohesive functionalities with special customizations

export const StarOutlinedComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    className="bi bi-star-fill"
    viewBox="0 0 16 16"
    stroke="currentColor"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
  </svg>
);

export const StarFilledComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    className="bi bi-star-fill"
    viewBox="0 0 16 16"
    fill="currentColor"
    stroke="currentColor"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
  </svg>
);

export const CircleOutlinedComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    className="bi bi-circle"
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
  >
    <circle cx="8" cy="8" r="7" />
  </svg>
);

export const CircleFilledComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    className="bi bi-circle-fill"
    viewBox="0 0 16 16"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="1"
  >
    <circle cx="8" cy="8" r="7" />
  </svg>
);

export const SquareOutlinedComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    className="bi bi-square"
    viewBox="0 0 16 16"
    stroke="currentColor"
    strokeWidth={1}
  >
    <rect x="2" y="2" width="13" height="13" rx="2" ry="2" />
  </svg>
);

export const SquareFilledComponentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    className="bi bi-square-fill"
    viewBox="0 0 16 16"
    stroke="currentColor"
    strokeWidth={1}
  >
    <rect x="2" y="2" width="13" height="13" rx="2" ry="2" />
  </svg>
);