import "./SwitchInput.css";

import React, { useState } from 'react';

interface SwitchComponentProps {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

  export const CustomSwitch: React.FC<SwitchComponentProps> = ({ label, checked, onChange }) => {
    // const [isChecked, setIsChecked] = useState(false);

    // const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setIsChecked(event.target.checked);
    // };

    return (
      <div className="switchWrapper">
      <label className="custom-switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="custom-slider"></span>
      </label>
      <span className="switchLabel">
      {label}
      </span>
      </div>
    );
  };