import "./CreatePage.css";

import React, { useEffect, useState } from "react";
import AppBarHeader from "../../Components/AppBarHeader";
import { GridItem } from "../../interfaces/GridItem";

import LeftMenu from "./LeftMenu/LeftMenu";
import MiddleGrid from "./MiddleGrid/MiddleGrid";
import RightForm from "./RightForm/RightForm";
import { LocalStorageItem } from "../../interfaces/LocalStorageItem";
import { log } from "../../helpers/logger";

import { setBlockDataAbout1 } from "../../features/elements/about/about1Slice";
import { setAllDataAboutWithContact2 } from "../../features/elements/aboutWithContact/aboutWithContact2Slice";
import { setAllDataAboutWithContact1 } from "../../features/elements/aboutWithContact/aboutWithContact1Slice";
import { setBlockDataCertifications1 } from "../../features/elements/certifications/certification1Slice";
import { setBlockDataContact1 } from "../../features/elements/contact/contact1Slice";
import { setBlockDataContact2 } from "../../features/elements/contact/contact2Slice";
import { setBlockDataContact3 } from "../../features/elements/contact/contact3Slice";
import { setBlockDataEducations1 } from "../../features/elements/educations/educations1Slice";
import { setBlockDataEducations2 } from "../../features/elements/educations/educations2Slice";
import { setBlockDataEducations3 } from "../../features/elements/educations/educations3Slice";
import { setBlockDataName1 } from "../../features/elements/name/name1Slice";
import { setBlockDataOthers1 } from "../../features/elements/others/others1Slice";
import { setBlockDataPhoto1 } from "../../features/elements/photo/photo1Slice";
import { setBlockDataProjects1 } from "../../features/elements/projects/projects1Slice";
import { setBlockDataProjects2 } from "../../features/elements/projects/projects2Slice";
import { setBlockDataRatings1 } from "../../features/elements/ratings/ratings1Slice";
import { setBlockDataRatings2 } from "../../features/elements/ratings/ratings2Slice";
import { setBlockDataSkills1 } from "../../features/elements/skills/skills1Slice";
import { setBlockDataSkills2 } from "../../features/elements/skills/skills2Slice";
import { setBlockDataWorks1 } from "../../features/elements/works/works1Slice";
import { setBlockDataWorks2 } from "../../features/elements/works/works2Slice";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addGridItem } from "../../features/gridItem/gridItemSlice";
import { Layout as LayoutItem } from "react-grid-layout";
import Drawer from "../../Components/Layout/DrawerComponent";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";
import { CaretLeftIcon, CaretRightIcon } from "../../static/Icons/Icons";
import { _isProd } from "../../constants";
import { setBlockDataRatings3 } from "../../features/elements/ratings/ratings3Slice";
import { setBlockDataRatings4 } from "../../features/elements/ratings/ratings4Slice";
// import "/node_modules/react-grid-layout/css/styles.css";
// import "/node_modules/react-resizable/css/styles.css";

const CreatePage: React.FC = () => {
  const gridItems = useAppSelector((state) => state.gridItems);
  const dispatch = useAppDispatch();

  // // The actual Items
  // const [items, setItems] = useState<GridItem[]>([]);
  // // The Forms corresponding to items in grid
  // const [forms, setForms] = useState<string[]>([]);

  const aboutWithContact1 = useAppSelector((state) => state.aboutWithContact1);
  const aboutWithContact2 = useAppSelector((state) => state.aboutWithContact2);

  
  const name1 = useAppSelector((state) => state.name1);

  const photo1 = useAppSelector((state) => state.photo1);

  const about1 = useAppSelector((state) => state.about1);

  const contact1 = useAppSelector((state) => state.contact1);
  const contact2 = useAppSelector((state) => state.contact2);
  const contact3 = useAppSelector((state) => state.contact3);

  const skills1 = useAppSelector((state) => state.skills1);
  const skills2 = useAppSelector((state) => state.skills2);

  const educations1 = useAppSelector((state) => state.educations1);
  const educations2 = useAppSelector((state) => state.educations2);
  const educations3 = useAppSelector((state) => state.educations3);

  const works1 = useAppSelector((state) => state.works1);
  const works2 = useAppSelector((state) => state.works2);

  const projects1 = useAppSelector((state) => state.projects1);
  const projects2 = useAppSelector((state) => state.projects2);

  const others1 = useAppSelector((state) => state.others1);

  const certifications1 = useAppSelector((state) => state.certifications1);

  const ratings1 = useAppSelector((state) => state.ratings1);
  const ratings2 = useAppSelector((state) => state.ratings2);
  const ratings3 = useAppSelector((state) => state.ratings3);
  const ratings4 = useAppSelector((state) => state.ratings4);

  const formStyles = useAppSelector((state) => state.formStyles);

  // LOAD ALL RESUME DATA AT START TIME FROM LOCAL STORAGE AND SET IT TO LAYOUT
  // SO THAT IT WILL SHOW DATA AT START IF USER CAME BACK AFTER A TIME.
  useEffect(() => {
    // const savedLayout = JSON.parse(localStorage.getItem("ItemsArray") as string);
    const savedLayout = getFromLS();

    // setLayout(savedLayout);
    // SET LAYOUT AND ITS DATA
    if (savedLayout && savedLayout.length != 0) {
      savedLayout.forEach((item: GridItem) => {
        // Add Item to itemsArray/gridItems
        dispatch(
          addGridItem({
            // i: item.i,
            name: item.name,
            x: item.x,
            y: item.y,
            width: item.w,
            height: item.h,
            data: item.data,
            isResizable: true,
          })
        );
        // Set The Data to each element
        switch (item.name) {
          case "aboutwithcontact1":
            dispatch(setAllDataAboutWithContact1(item.data));
            break;
          case "aboutwithcontact2":
            dispatch(setAllDataAboutWithContact2(item.data));
            break;

          case "about1":
            dispatch(setBlockDataAbout1(item.data));
            break;

          case "name1":
            dispatch(setBlockDataName1(item.data));
            break;

          case "contact1":
            dispatch(setBlockDataContact1(item.data));
            break;
          case "contact2":
            dispatch(setBlockDataContact2(item.data));
            break;
          case "contact3":
            dispatch(setBlockDataContact3(item.data));
            break;

          case "educations1":
            dispatch(setBlockDataEducations1(item.data));
            break;
          case "educations2":
            dispatch(setBlockDataEducations2(item.data));
            break;
          case "educations3":
            dispatch(setBlockDataEducations3(item.data));
            break;

          case "skills1":
            dispatch(setBlockDataSkills1(item.data));
            break;
          case "skills2":
            dispatch(setBlockDataSkills2(item.data));
            break;
            
          case "works1":
            dispatch(setBlockDataWorks1(item.data));
            break;
          case "works2":
            dispatch(setBlockDataWorks2(item.data));
            break;

          case "projects1":
            dispatch(setBlockDataProjects1(item.data));
            break;
          case "projects2":
            dispatch(setBlockDataProjects2(item.data));
            
            break;
          case "ratings1":
            dispatch(setBlockDataRatings1(item.data));
            break;
          case "ratings2":
            dispatch(setBlockDataRatings2(item.data));
            break;
          case "ratings3":
            dispatch(setBlockDataRatings3(item.data));
            break;
          case "ratings4":
            dispatch(setBlockDataRatings4(item.data));
            break;

          case "certifications1":
            dispatch(setBlockDataCertifications1(item.data));
            break;

          case "others1":
            dispatch(setBlockDataOthers1(item.data));
            break;

          case "photo1":
            dispatch(setBlockDataPhoto1(item.data));
            break;

          default:
           if(!_isProd) console.log("key doesnt exist in useEffect in CreatePage");
            break;
        }
      });
    }
  }, []);

  const getTruncatedElementName = (elementI: string): string => {
    if (elementI.startsWith("aboutwithcontact1")) return "aboutwithcontact1";
    if (elementI.startsWith("aboutwithcontact2")) return "aboutwithcontact2";

    if (elementI.startsWith("photo1")) return "photo1";

    if (elementI.startsWith("about1")) return "about1";

    if (elementI.startsWith("name1")) return "name1";

    if (elementI.startsWith("contact1")) return "contact1";
    if (elementI.startsWith("contact2")) return "contact2";
    if (elementI.startsWith("contact3")) return "contact3";

    if (elementI.startsWith("educations1")) return "educations1";
    if (elementI.startsWith("educations2")) return "educations2";
    if (elementI.startsWith("educations3")) return "educations3";

    if (elementI.startsWith("skills1")) return "skills1";
    if (elementI.startsWith("skills2")) return "skills2";

    if (elementI.startsWith("works1")) return "works1";
    if (elementI.startsWith("works2")) return "works2";

    if (elementI.startsWith("projects1")) return "projects1";
    if (elementI.startsWith("projects2")) return "projects2";

    if (elementI.startsWith("ratings1")) return "ratings1";
    if (elementI.startsWith("ratings2")) return "ratings2";
    if (elementI.startsWith("ratings3")) return "ratings3";
    if (elementI.startsWith("ratings4")) return "ratings4";
    
    if (elementI.startsWith("certifications1")) return "certifications1";

    if (elementI.startsWith("others1")) return "others1";

    if (elementI.startsWith("spacer1")) return "spacer1";
    if (elementI.startsWith("spacer2")) return "spacer2";
    if (elementI.startsWith("spacer3")) return "spacer3";
    return elementI;
  };

  // GET LAYOUT FROM LOCAL STORAGE
  const getFromLS = (): GridItem[] => {
    let savedLayout = JSON.parse(localStorage.getItem("ItemsArray") as string);
    if (savedLayout) {
      // Transform the saved layout to the format react-grid-layout expects
      const newLayout = savedLayout.map((item: GridItem) => ({
        ...item,
        // i: item.name, // Use 'name' as the 'i' identifier
      }));
      return newLayout;
    }
    return [];
  };

  // MAKE A COPY OF LAYOUT FOR DOWNLOAD PAGE
  const saveToLS = (layoutItemsToSaveAsGridItems: LayoutItem[]) => {
    const finalItems: LocalStorageItem[] = [];
    for (let i = 0; i < layoutItemsToSaveAsGridItems.length; i++) {
      const element = layoutItemsToSaveAsGridItems[i];
      const elementName = getTruncatedElementName(element.i);

      finalItems.push({
        name: elementName,
        x: element.x,
        y: element.y,
        w: element.w,
        h: element.h,
        data: ((elementName: string) => {
          switch (elementName) {
            case "aboutwithcontact1":
              return aboutWithContact1;
            case "aboutwithcontact2":
              return aboutWithContact2;

            case "photo1":
              return photo1;

            case "about1":
              return about1;

            case "name1":
              return name1;

            case "contact1":
              return contact1;
            case "contact2":
              return contact2;
            case "contact3":
              return contact3;

            case "educations1":
              return educations1;
            case "educations2":
              return educations2;
            case "educations3":
              return educations3;

            case "skills1":
              return skills1;
            case "skills2":
              return skills2;

            case "works1":
              return works1;
            case "works2":
              return works2;

            case "projects1":
              return projects1;
            case "projects2":
              return projects2;
              
            case "ratings1":
              return ratings1;
            case "ratings2":
              return ratings2;
            case "ratings3":
              return ratings3;
            case "ratings4":
              return ratings4;

            case "certifications1":
              return certifications1;
            case "others1":
              return others1;

            case "spacer1":
              return "spacer1";
            case "spacer2":
              return "spacer2";
            case "spacer3":
              return "spacer3";

            default:
              if(!_isProd) console.log(`elementName ${elementName} does not exist in (switch case) in saveToLS in CreatePage.tsx`);
              return "elementName does not exist in (switch case) in saveToLS in CreatePage.tsx";
          }
        })(elementName),
      });
    }
    localStorage.setItem("ItemsArray", JSON.stringify(finalItems));
    localStorage.setItem("FormStyles", JSON.stringify(formStyles));
    // localStorage.setItem("ItemsArray", JSON.stringify(gridItems.items));
    // localStorage.setItem("FormStyles", JSON.stringify(gridItems.forms));
  };

  const [layout, setLayout] = useState<LayoutItem[]>([]);
  // Callback so you can save the layout.
  // Calls back with (currentLayout) after every drag or resize stop.
  function onLayoutChange(newLayout: LayoutItem[]) {
    saveToLS(newLayout);
    setLayout(newLayout);
  }
  // // SAVE LAYOUT EVERY X SECONDS
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if(!_isProd) console.log("Saving Layout to Local Storage");
  //     saveToLS(layout);
  //   }, 2000);
  //   return () => clearInterval(interval);
  // }, []);

  const [rightFormExpanded, setRightFormExpanded] = useState(false);
  const openRightFormDrawer = () => {
    setRightFormExpanded(true);
  };
  const closeRightFormDrawer = () => {
    setRightFormExpanded(false);
  };

  const [leftMenuExpanded, setLeftMenuExpanded] = useState(false);
  const [leftMenuExpandedWidthInPercentage, setLeftMenuExpandedWidthInPercentage] = useState("100");
  const openLeftMenuDrawer = (widthInPercentage = "100") => {
    setLeftMenuExpandedWidthInPercentage(widthInPercentage);
    setLeftMenuExpanded(true);
  };
  const closeLeftMenuDrawer = () => {
    setLeftMenuExpanded(false);
  };

  return (
    <>
      <AppBarHeader />
      <div className="createPageWrapper">
        <aside className="leftMenu">
          <LeftMenu
            leftMenuExpanded={leftMenuExpanded}
            openLeftMenuDrawer={openLeftMenuDrawer}
            closeLeftMenuDrawer={closeLeftMenuDrawer}
          />
        </aside>
        <div className={"middleGrid"}>
          <div className="drawerOpenButtons">
            <div className="leftMenuDrawerOpenButton">
              <ButtonComponent onClick={openLeftMenuDrawer} size="small" fullWidth>
                <CaretRightIcon />
              </ButtonComponent>
            </div>
            <div className="rightFormDrawerOpenButton">
              <ButtonComponent onClick={openRightFormDrawer} size="small" fullWidth>
                <CaretLeftIcon />
              </ButtonComponent>
            </div>
          </div>
          <MiddleGrid onLayoutChange={onLayoutChange} />
        </div>
        <div className={"rightForm"}>
          <RightForm
            saveToLS={saveToLS}
            layout={layout}
            rightFormExpanded={rightFormExpanded}
            closeRightFormDrawer={closeRightFormDrawer}
          />
        </div>
        <>
          <Drawer
            anchor="ltr"
            open={leftMenuExpanded}
            onClose={() => setLeftMenuExpanded(false)}
            width={`${leftMenuExpandedWidthInPercentage}%`}
          >
            <LeftMenu
              leftMenuExpanded={leftMenuExpanded}
              openLeftMenuDrawer={openLeftMenuDrawer}
              closeLeftMenuDrawer={closeLeftMenuDrawer}
            />
          </Drawer>
        </>
        <>
          <Drawer anchor="rtl" open={rightFormExpanded} onClose={() => setRightFormExpanded(false)} width="100%">
            <RightForm
              saveToLS={saveToLS}
              layout={layout}
              rightFormExpanded={rightFormExpanded}
              closeRightFormDrawer={closeRightFormDrawer}
            />
          </Drawer>
        </>
      </div>
    </>
  );
};
export default CreatePage;
