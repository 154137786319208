import "./_CommonComponents.css";

import React, {FC} from 'react';
import { GridItem } from "../../../../interfaces/GridItem";
import { useAppDispatch } from "../../../../app/hooks";
import { removeGridItem } from "../../../../features/gridItem/gridItemSlice";

interface RemoveBlockButtonProps {
  blockTitle: string;
  item: GridItem;
  flipped?: boolean;
}

export const RemoveBlockButton: FC<RemoveBlockButtonProps> = (props) => {
  const dispatch = useAppDispatch();

  return <div>
    <div className={`removeBlockButtonWrapper ${props.flipped ? "removeBlockButtonWrapperFlipped" : "removeBlockButtonWrapperNormal"}`}>
      <div className="removeBlockButtonTitle">{props.blockTitle}</div>
      <button className="removeBlockButtonButton" onClick={() => dispatch(removeGridItem(props.item))}>
        X
      </button>
    </div>
  </div>
};
