import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { LOGGED_IN } from "../../constants";
import { GoogleIcon } from "../../static/Icons/Icons";
import { ButtonComponent } from "../../Components/Inputs/ButtonComponent";

interface OAuthCardProps {}

const OAuthCard: React.FC<OAuthCardProps> = () => {
  const analytics = getAnalytics();
  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);

  const signInWithGoogle = async () => {
    setAuthing(true);
    const result = await signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        logEvent(analytics, LOGGED_IN);
        console.log(response.user.uid);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        setAuthing(false);
      });
  };
  return (
    <>
      <div style={{ margin: "32px 0px 32px 0px" }}>
        <ButtonComponent loading={authing} onClick={() => signInWithGoogle()}>
          <GoogleIcon />
          {/* {googleSvg} */}
          &nbsp; SIGN IN WITH GOOGLE
        </ButtonComponent>
      </div>
    </>
  );
};

export default OAuthCard;
