import React, { FC } from "react";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { CollapseableForm } from "./_CommonComponents";
import {
  setStyleAbout1,
  setNameAbout1,
  setProfessionAbout1,
  setAboutAbout1,
} from "../../../../features/elements/about/about1Slice";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";

interface About1FormProps {}

export const About1Form: FC<About1FormProps> = React.memo((props) => {
  const about1 = useAppSelector((state) => state.about1);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => setExpanded(!expanded);
  const handleBgColor = (newColor: string) => dispatch(setStyleAbout1({ ...about1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) => dispatch(setStyleAbout1({ ...about1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">About #1</h3>
      <GridContainer>
        <GridItem xs={6}>
          <TextInput
            label="Full Name"
            value={about1.name}
            onChange={(e) => {
              dispatch(setNameAbout1(e.target.value));
            }}
          />
        </GridItem>
        <GridItem xs={6}>
          <TextInput
            label="Profession"
            value={about1.profession}
            onChange={(e) => {
              dispatch(setProfessionAbout1(e.target.value));
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInputMultiline
            label="Pitch about yourself"
            value={about1.about}
            onChange={(e) => {
              dispatch(setAboutAbout1(e.target.value));
            }}
          />
        </GridItem>
      </GridContainer>
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={about1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={about1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
