import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Certificate, Certifications } from "../../../interfaces/Certification";

export const initialStateCertifications1: Certifications = {
  title: "",
  data: [
    {
      id: `certificate${Date.now()}`,
      certificateName: "",
      organizationName: "",
      certificateDate: "",
      certificateLink: "",
    },
  ],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
}

export const Certifications1Slice = createSlice({
  name: "certifications1",
  initialState: initialStateCertifications1,
  reducers: {
    setBlockDataCertifications1: (state, action: PayloadAction<Certifications>) => {
      state.title = action.payload.title;
      state.data = action.payload.data.map(item => ({
        ...item,
      }));
      state.style = action.payload.style;
    },
    setTitleCertifications1: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDataCertifications1: (state, action: PayloadAction<Certificate>) => {
      state.data = [...state.data, action.payload];
    },
    setOrganizationNameCertifications1: (state, action: PayloadAction<{organizationName: string, certificateId:string}>) => {
      const { organizationName, certificateId } = action.payload;
      const pos = state.data.findIndex((certificate) => certificate.id === certificateId);
      state.data[pos].organizationName = organizationName;
    },
    setCertificateNameCertifications1: (state, action: PayloadAction<{certificateName: string, certificateId:string}>) => {
      const { certificateName, certificateId } = action.payload;
      const pos = state.data.findIndex((certificate) => certificate.id === certificateId);
      state.data[pos].certificateName = certificateName;
    },
    setCertificateDateCertifications1: (state, action: PayloadAction<{certificateDate: string, certificateId:string}>) => {
      const { certificateDate, certificateId } = action.payload;
      const pos = state.data.findIndex((certificate) => certificate.id === certificateId);
      state.data[pos].certificateDate = certificateDate;
    },
    setCertificateLinkCertifications1: (state, action: PayloadAction<{certificateLink: string, certificateId:string}>) => {
      const { certificateLink, certificateId } = action.payload;
      const pos = state.data.findIndex((certificate) => certificate.id === certificateId);
      state.data[pos].certificateLink = certificateLink;
    },
    addCertificateCertifications1: (state) => {
      state.data.push({
        id: `certificate${Date.now()}`,
        certificateName: "",
        certificateDate: "",
        organizationName: "",
        certificateLink: ""
      });
    },
    removeCertificateCertifications1: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((certificate) => certificate.id !== action.payload);
    },
    setStyleCertifications1: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataCertifications1,
  setTitleCertifications1,
  setDataCertifications1,
  addCertificateCertifications1,
  removeCertificateCertifications1,
  setOrganizationNameCertifications1,
  setCertificateNameCertifications1,
  setCertificateDateCertifications1,
  setCertificateLinkCertifications1,
  setStyleCertifications1,
} = Certifications1Slice.actions;

export default Certifications1Slice.reducer;
