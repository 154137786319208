import "./_FormsStyles.css";

import React, { FC } from "react";
import { useAppSelector } from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import {
  addProjectProjects1,
  removeProjectProjects1,
  setBulletProjects1,
  setIndentProjects1,
  setProjectDetailsProjects1,
  setProjectNameProjects1,
  setStyleProjects1,
  setTitleProjects1,
} from "../../../../features/elements/projects/projects1Slice";
import { CollapseableForm, RemoveFieldsButton } from "./_CommonComponents";
import {
  addProjectProjects2,
  removeProjectProjects2,
  setBulletProjects2,
  setIndentProjects2,
  setProjectDetailsProjects2,
  setProjectNameProjects2,
  setStyleProjects2,
  setTitleProjects2,
} from "../../../../features/elements/projects/projects2Slice";
import { TextInput, TextInputMultiline } from "../../../../Components/Inputs/TextInput";
import { ButtonComponent } from "../../../../Components/Inputs/ButtonComponent";
import { GridContainer, GridItem } from "../../../../Components/Layout/GridComponent";
import { CustomSwitch } from "../../../../Components/Inputs/SwitchInput";

interface ProjectsFormProps {}

export const Projects1Form: FC<ProjectsFormProps> = React.memo(() => {
  const projects1 = useAppSelector((state) => state.projects1);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addProjectProjects1());
  const handleRemoveFields = (projectId: string) => dispatch(removeProjectProjects1(projectId));

  // ==================================================================================================================
  // Handle Options Inputs
  const handleBulletParagraphsInput = (bullet: boolean) => dispatch(setBulletProjects1(bullet));
  const handleIndentParagraphsInput = (indent: boolean) => dispatch(setIndentProjects1(indent));

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => dispatch(setTitleProjects1(title));
  const handleProjectNameInput = (projectName: string, projectId: string) =>
    dispatch(setProjectNameProjects1({ projectName, projectId }));
  const handleProjectDetailsInput = (projectDetails: string, projectId: string) =>
    dispatch(setProjectDetailsProjects1({ projectDetails: projectDetails.split("<br>"), projectId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleProjects1({ ...projects1.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleProjects1({ ...projects1.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Projects #1</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={projects1.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch
            label="Bullet Paragraphs"
            checked={projects1.bullet}
            onChange={(e) => handleBulletParagraphsInput(e.target.checked)}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch
            label="Indent Paragraphs"
            checked={projects1.indent}
            onChange={(e) => handleIndentParagraphsInput(e.target.checked)}
          />
        </GridItem>
      </GridContainer>
      {projects1.data.map((singleProject, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Project Name"
                    value={singleProject.projectName}
                    onChange={(e) => handleProjectNameInput(e.target.value, singleProject.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInputMultiline
                    label="Project Details (Separate each point by adding <br> at the end)"
                    value={singleProject.projectDetails.join("<br>")}
                    onChange={(e) => handleProjectDetailsInput(e.target.value, singleProject.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleProject.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={projects1.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={projects1.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});

export const Projects2Form: FC<ProjectsFormProps> = React.memo((props) => {
  const projects2 = useAppSelector((state) => state.projects2);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  // ==================================================================================================================
  // Add or Remove Fields
  const handleAddFields = () => dispatch(addProjectProjects2());
  const handleRemoveFields = (projectId: string) => dispatch(removeProjectProjects2(projectId));

  // ==================================================================================================================
  // Handle Options Inputs
  const handleBulletParagraphsInput = (bullet: boolean) => dispatch(setBulletProjects2(bullet));
  const handleIndentParagraphsInput = (indent: boolean) => dispatch(setIndentProjects2(indent));

  // ==================================================================================================================
  // Handle Text Inputs
  const handleBlockTitleInput = (title: string) => dispatch(setTitleProjects2(title));
  const handleProjectNameInput = (projectName: string, projectId: string) =>
    dispatch(setProjectNameProjects2({ projectName, projectId }));
  const handleProjectDetailsInput = (projectDetails: string, projectId: string) =>
    dispatch(setProjectDetailsProjects2({ projectDetails: projectDetails.split("<br>"), projectId }));

  // ==================================================================================================================
  // Handle Style/Color Inputs
  const handleBgColor = (newColor: string) => dispatch(setStyleProjects2({ ...projects2.style, bgColor: newColor }));
  const handleTextColor = (newColor: string) =>
    dispatch(setStyleProjects2({ ...projects2.style, textColor: newColor }));

  return (
    <>
      <h3 className="formHeader">Projects #2</h3>
      <GridContainer>
        <GridItem xs={12}>
          <TextInput label="Title" value={projects2.title} onChange={(e) => handleBlockTitleInput(e.target.value)} />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch
            label="Bullet Paragraphs"
            checked={projects2.bullet}
            onChange={(e) => handleBulletParagraphsInput(e.target.checked)}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomSwitch
            label="Indent Paragraphs"
            checked={projects2.indent}
            onChange={(e) => handleIndentParagraphsInput(e.target.checked)}
          />
        </GridItem>
      </GridContainer>
      {projects2.data.map((singleProject, index) => (
        <div key={index}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <GridContainer>
                <GridItem xs={12}>
                  <TextInput
                    label="Project Name"
                    value={singleProject.projectName}
                    onChange={(e) => handleProjectNameInput(e.target.value, singleProject.id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInputMultiline
                    label="Project Details (Separate each point by adding <br> at the end)"
                    value={singleProject.projectDetails.join("<br>")}
                    onChange={(e) => handleProjectDetailsInput(e.target.value, singleProject.id)}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div>
              <RemoveFieldsButton id={singleProject.id} handleRemoveFields={handleRemoveFields} />
            </div>
          </div>
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "center", margin: "8px auto", width: "max-content" }}>
        <ButtonComponent size="small" onClick={handleAddFields} bgColor="var(--color-secondary)">
          Add Another
        </ButtonComponent>
      </div>
      {/* THE EXTRA OPTIONS */}
      <CollapseableForm
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        bgColor={projects2.style.bgColor}
        handleBgColor={handleBgColor}
        textColor={projects2.style.textColor}
        handleTextColor={handleTextColor}
      />
    </>
  );
});
