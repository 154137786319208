import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Educations } from "../../../interfaces/Educations";

export const initialStateEducations3: Educations = {
  title: "",
  data: [{ id: `education${Date.now()}`, courseName: "", courseResults: "", organizationName: "", courseDuration: "" }],
  style: {
    bgColor: "#ffffff",
    textColor: "#000000",
  },
};

export const Educations3Slice = createSlice({
  name: "educations3",
  initialState: initialStateEducations3,
  reducers: {
    setBlockDataEducations3: (state, action: PayloadAction<Educations>) => {
      state.title = action.payload.title;
      // Ensure deep copy of the data array and its objects
      state.data = action.payload.data.map(item => ({
        ...item,
      }));
      state.style = action.payload.style;
    },
    setTitleEducations3: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setCourseNameEducations3: (state, action: PayloadAction<{ courseName: string; courseId: string }>) => {
      const { courseName, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseName = courseName;
    },
    setCourseResultsEducations3: (state, action: PayloadAction<{ courseResults: string; courseId: string }>) => {
      const { courseResults, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseResults = courseResults;
    },
    setOrganizationNameEducations3: (state, action: PayloadAction<{ organizationName: string; courseId: string }>) => {
      const { organizationName, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].organizationName = organizationName;
    },
    setCourseDurationEducations3: (state, action: PayloadAction<{ courseDuration: string; courseId: string }>) => {
      const { courseDuration, courseId } = action.payload;
      const pos = state.data.findIndex((education) => education.id === courseId);
      state.data[pos].courseDuration = courseDuration;
    },
    addEducationEducations3: (state) => {
      state.data.push({
        id: `education${Date.now()}`,
        courseName: "",
        courseResults: "",
        organizationName: "",
        courseDuration: "",
      });
    },
    removeEducationEducations3: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((education) => education.id !== action.payload);
    },
    setStyleEducations3: (state, action: PayloadAction<{ bgColor: string; textColor: string }>) => {
      state.style = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockDataEducations3,
  setTitleEducations3,
  setCourseNameEducations3,
  setCourseResultsEducations3,
  setOrganizationNameEducations3,
  setCourseDurationEducations3,
  addEducationEducations3,
  removeEducationEducations3,
  setStyleEducations3,
} = Educations3Slice.actions;

export default Educations3Slice.reducer;
