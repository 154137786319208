import React, { Dispatch } from "react";

import { FormStyles } from "../../../interfaces/FormStyles";
import { GridItem } from "../../../interfaces/GridItem";
import { bulletPoints, Others } from "../../../interfaces/Others";
import { BlueprintWrapper } from "./_BlueprintStyles";
import { BlueprintTitle } from "./_BlueprintTitle";
import { useAppSelector } from "../../../app/hooks";

const dummyOthers = {
  title: "Others Title",
  data: [
    "Lorem ipsum dolor sit amet.",
    "C onsectetur adipisicing repellat",
    "Dolorum earum officiis.",
    "distinctio ipsa officia soluta.",
    "accusantium exercit ationem.",
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

interface OthersBlueprintProps {
  others: {
    name: string;
    x: number;
    y: number;
    w: number;
    h: number;
    data: Others;
  };
}

export const OthersBlueprint1: React.FC<OthersBlueprintProps> = (props) => {
  const formStyles = useAppSelector((state) => state.formStyles);

  return (
    <div
      style={{
        backgroundColor: props.others.data.style.bgColor,
        color: props.others.data.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <BlueprintWrapper
        h={props.others.h}
        x={props.others.x}
        y={props.others.y}
        w={props.others.w}
        bgColor={props.others.data.style.bgColor}
        textColor={props.others.data.style.textColor}
        fontFamily={formStyles.fontFamily}
      >
        <BlueprintTitle title={props.others.data.title} formStyles={formStyles} />
        <div style={{ paddingLeft: 8, fontSize: 15, marginTop: "8px" }}>
          {props.others.data.data.map((eachLine: string, index: number) => {
            return (
              <div
                key={eachLine + index}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom: 8,
                }}
              >
                <div>
                  {bulletPoints[props.others.data.bullet]}&nbsp;&nbsp;
                </div>
                <div style={{ fontWeight: 500 }}>{eachLine}</div>
              </div>
            );
          })}
        </div>
      </BlueprintWrapper>
    </div>
  );
};
