import "./_BlocksStyles.css";

import React, { useEffect, useState } from "react";
import { Course, Educations } from "../../../../interfaces/Educations";
import { RemoveBlockButton } from "./_CommonComponents";
import { GridItem } from "../../../../interfaces/GridItem";
import { BlockTitle } from "./_BlockTitle";
import { useAppSelector } from "../../../../app/hooks";
import { DegreeIcon } from "../../../../static/Icons/Icons";

const dummyEducations: Educations = {
  title: "Education Title",
  data: [
    {
      id: "education001",
      courseName: "Bachelor of Science in Computer Science",
      organizationName: "Stanford University",
      courseDuration: "2019 - 2022",
      courseResults: "Cumulative CGPA 9.00",
    },
    {
      id: "education002",
      courseName: "Higher Secondary Science Stream",
      organizationName: "Palo Alto High School",
      courseDuration: "2017 - 2019",
      courseResults: "Result Percentage 72%",
    },
  ],
  style: {
    bgColor: "#fff",
    textColor: "#000",
  },
};

const isEmptyEducations = (educations: Educations) => {
  return educations.data.every((value) => {
    if (
      educations.title === "" &&
      value.courseName === "" &&
      value.courseDuration === "" &&
      value.courseResults === "" &&
      value.organizationName === ""
    ) {
      return true;
    }
    return false;
  });
};

interface EducationsBlockProps {
  item: GridItem;
}

export const EducationsBlock1: React.FC<EducationsBlockProps> = (props) => {
  const educations1 = useAppSelector(state=>state.educations1);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Educations
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyEducations(educations1));
  }, [educations1]);

  const toBeShownEducations = isEmpty ? dummyEducations : educations1;

  return (
    <div
      style={{
        backgroundColor: toBeShownEducations.style.bgColor,
        color: toBeShownEducations.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownEducations.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Education #1"} />
        <div
          style={{
            fontSize: 14,
            display: "flex",
            flexDirection: "column",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownEducations.data.map((course: Course) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "8px 0px 4px 0px",
                  width: "100%",
                }}
                key={course.id}
              >
                <div style={{ marginRight: 12 }}>
                  <DegreeIcon style={{ color: formStyles.accentColor }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    flex: 1,
                  }}
                >
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                    <h4 style={{ fontWeight: 600, color: toBeShownEducations.style.textColor, marginRight: 30 }}>
                      {course.organizationName}
                    </h4>
                    <p style={{ color: formStyles.accentColor, fontSize: 12, flexShrink: 0, paddingTop: 2 }}>
                      {course.courseDuration}
                    </p>
                  </div>

                  <p style={{ fontWeight: 500, color: toBeShownEducations.style.textColor, margin: 0 }}>
                    {course.courseName}
                  </p>
                  <p style={{ fontWeight: 500, color: toBeShownEducations.style.textColor }}>{course.courseResults}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const EducationsBlock2: React.FC<EducationsBlockProps> = (props) => {
  const educations2 = useAppSelector(state=>state.educations2);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Educations
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyEducations(educations2));
  }, [educations2]);

  const toBeShownEducations = isEmpty ? dummyEducations : educations2;

  return (
    <div
      style={{
        backgroundColor: toBeShownEducations.style.bgColor,
        color: toBeShownEducations.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownEducations.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Education #2"} />
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: "0px 4px",
            width: "100%",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownEducations.data.map((course: Course) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                key={course.id}
              >
                <DegreeIcon style={{ color: formStyles.accentColor }} />

                <div style={{ fontWeight: 600, marginBottom: 1, textAlign: "center" }}>{course.organizationName}</div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center", color: formStyles.accentColor }}>
                  {course.courseDuration}
                </div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center" }}>{course.courseName}</div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center" }}>{course.courseResults}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const EducationsBlock3: React.FC<EducationsBlockProps> = (props) => {
  const educations3 = useAppSelector(state=>state.educations3);
  const formStyles = useAppSelector((state) => state.formStyles);

  // Check For Empty Educations
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(isEmptyEducations(educations3));
  }, [educations3]);

  const toBeShownEducations = isEmpty ? dummyEducations : educations3;

  return (
    <div
      style={{
        backgroundColor: toBeShownEducations.style.bgColor,
        color: toBeShownEducations.style.textColor,
        height: "100%",
        width: "100%",
      }}
    >
      <div className={"blockWrapper"} style={{ fontFamily: formStyles.fontFamily }}>
        <BlockTitle formStyles={formStyles} title={toBeShownEducations.title} isOpaque={isEmpty} />
        <RemoveBlockButton item={props.item} blockTitle={"Education #3"} />
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: "0px 4px",
            width: "100%",
            opacity: isEmpty ? 0.5 : 1,
          }}
        >
          {toBeShownEducations.data.map((course: Course) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                key={course.id}
              >
                <DegreeIcon style={{ color: formStyles.accentColor }} />

                <div style={{ fontWeight: 600, marginBottom: 1, textAlign: "center" }}>{course.organizationName}</div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center", color: formStyles.accentColor }}>
                  {course.courseDuration}
                </div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center" }}>{course.courseName}</div>
                <div style={{ fontWeight: 500, margin: 1, textAlign: "center" }}>{course.courseResults}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
