import React, { useState } from "react";

import "./ButtonGroupComponent.css";
import { TextCenterIcon, TextLeftIcon, TextRightIcon } from "../../static/Icons/Icons";

interface ButtonGroupComponent2Props {
  flipped: boolean;
  handleFlip: (flipped: boolean) => void;
}

export const ButtonGroupComponent2: React.FC<ButtonGroupComponent2Props> = ({ flipped, handleFlip }) => {
  return (
    <div className="button-group">
      <button
        className={`button ${flipped ? "" : "active"}`}
        disabled={flipped ? false : true}
        onClick={() => handleFlip(false)}
      >
        <TextLeftIcon />
      </button>
      <button
        className={`button ${flipped ? "active" : ""}`}
        disabled={flipped ? true : false}
        onClick={() => handleFlip(true)}
      >
        <TextRightIcon />
      </button>
    </div>
  );
};


interface ButtonGroupComponent3Props {
  align: "flex-start" | "center" | "flex-end";
  direction: "row" | "row-reverse";
  handleAlign: (align: "flex-start" | "center" | "flex-end", direction: "row" | "row-reverse") => void;
}
export const ButtonGroupComponent3: React.FC<ButtonGroupComponent3Props> = ({ align, direction, handleAlign }) => {
  return (
    <div className="button-group">
      <button
        className={`button ${align === "flex-start" && direction === "row" ? "active" : ""}`}
        disabled={align === "flex-start" && direction === "row"}
        onClick={() => handleAlign("flex-start", "row")}
      >
        <TextLeftIcon />
      </button>
      <button
        className={`button ${align === "center" && direction === "row" ? "active" : ""}`}
        disabled={align === "center" && direction === "row"}
        onClick={() => handleAlign("center", "row")}
      >
        <TextCenterIcon />
      </button>
      <button
        className={`button ${align === "flex-start" && direction === "row-reverse" ? "active" : ""}`}
        disabled={align === "flex-start" && direction === "row-reverse"}
        onClick={() => handleAlign("flex-start", "row-reverse")}
      >
        <TextRightIcon />
      </button>
    </div>
  );
};
